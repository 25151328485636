<script>
export default {
  components: {},
  data() {
    return {
      isPaused: false
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    toggleAutoSlide() {
      this.isPaused = !this.isPaused
      this.$emit('toggleHandler', this.isPaused)
    }
  }
}
</script>

<template>
  <div class="wf__home-content-news-navi-wrapper-item">
    <div class="wf__home-content-news-pager">
      <span v-show="!isPaused" class="material-icons-outlined" @click="toggleAutoSlide"> pause </span>
      <span v-show="isPaused" class="material-icons" @click="toggleAutoSlide"> play_arrow </span>
    </div>
  </div>
</template>

<style scoped>
.wf__home-content-news-navi-wrapper-item {
  display: flex;
  gap: 9px;
}
.wf__home-content-news-navi {
  display: flex;
  gap: 7px;
  align-items: flex-end;
}
.wf__home-content-news-navi-item {
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 1px solid var(--wf-black-color);
  border-radius: 50%;
  cursor: pointer;
}
.wf__home-content-news-navi-item.active {
  border-color: var(--wf-primary-color);
  background-color: var(--wf-primary-color);
}
.wf__home-content-news-pager {
  display: flex;
  align-items: center;
  background-color: var(--wf-primary-color);
  color: #fff;
  border-radius: 9999px;

  padding: 1px 3px;
}
.wf__home-content-news-pager span {
  cursor: pointer;
}
</style>
