<script>
import LogoWhite from '@/assets/images/logo/white-100.png'
import LogoColor from '@/assets/images/logo/color-100.png'
import ModalVue from '@/components/ui/Modal.vue'
import HandoverVue from '@/components/Tech/Handover.vue'
import HeaderSitemapVue from '@/components/Header/HeaderSitemap.vue'
import MoblieMenusVue from '@/components/Header/MoblieMenus.vue'
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {
    ModalVue,
    HandoverVue,
    HeaderSitemapVue,
    MoblieMenusVue
  },
  data() {
    const menuList = this.$store.state.menuStore.menuList

    return {
      LogoWhite,
      LogoColor,
      menuItemDropdownRefs: [],
      menuList,
      isShowModal: false,
      isOpenMobileMenu: false
    }
  },
  computed: {
    loginUserInfo() {
      const user = this.$store.state.userStore.info
      return user?.groupName === 'bot' ? null : user
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.setElementsStyles()
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    toggleActive(el) {
      el.classList.toggle('active')
    },
    removeActive(e) {
      if (e.target.matches('.material-icons-outlined.cursor-pointer')) return
      this.$refs.sitemap.$el.classList.remove('active')
    },
    setMenuItemDropdownRef(el) {
      this.menuItemDropdownRefs.push(el)
    },
    setElementsStyles() {
      const maxHeight = this.menuItemDropdownRefs.reduce((acc, cur) => (acc > cur.clientHeight ? acc : cur.clientHeight), 0)

      for (const ref of this.menuItemDropdownRefs) {
        ref.style.height = `${maxHeight}px`
        ref.style.bottom = `-${maxHeight}px`
        ref.style.width = `${ref.parentElement.clientWidth}px`
      }

      this.$refs.header.style.setProperty('--min-height-px', `${maxHeight}px`)
    },
    menuClickHandler(e) {
      const target = e.target.matches('li') ? e.target : e.target.closest('li')
      const path = target.dataset.path
      if (!path) return
      if (path === 'showModal') return this.showModal()
      if (path) return this.$router.push(path)
    },
    logout(e) {
      e.preventDefault()
      if (!confirm('로그아웃 하시겠습니까?')) return

      this.$store.dispatch('userStore/LogoutUser')
      this.$router.replace('/')
    },
    toggleEng() {
      this.$store.dispatch('ToogleLangMode')
      this.$router.go()
    },
    showModal() {
      this.isShowModal = true
    },
    closeModal() {
      this.isShowModal = false
    },
    toogleMobileMenu() {
      this.isOpenMobileMenu = !this.isOpenMobileMenu
    }
  }
}
</script>

<template>
  <header class="wf__header" ref="header" @click="removeActive">
    <nav class="wf__header-wrapper">
      <span class="material-icons-outlined wf__header-mobile" @click="toogleMobileMenu"> menu </span>

      <router-link class="wf__header-item wf__header-logo" to="/">
        <!-- <DefaultLogoVue class="wf__header-logo-default" />
        <DarkLogoVue class="wf__header-logo-dark" /> -->

        <img class="wf__header-logo-default" :src="LogoColor" alt="" />
        <img class="wf__header-logo-dark" :src="LogoWhite" alt="" />
      </router-link>

      <ul class="wf__header-item wf__header-menus">
        <li class="wf__header-menu-item" v-for="(menu, i) in menuList" :key="i" @click="menuClickHandler">
          <span>{{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}</span>
          <ul v-if="menu.children.length !== 0" class="wf__header-menu-item-dropdown" :ref="setMenuItemDropdownRef">
            <li v-for="(child, j) in menu.children" :key="j" :data-path="child.path">
              <span>{{ LanguageConverter({ en: child.nameEn, kr: child.name }) }}</span>
            </li>
          </ul>
          <ul v-else class="wf__header-menu-item-dropdown" :ref="setMenuItemDropdownRef">
            <li :data-path="menu.path">
              <span>{{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}</span>
            </li>
          </ul>
        </li>
      </ul>

      <div class="wf__header-item wf__header-glob">
        <div class="wf__header-glob-icon-wrapper">
          <router-link v-if="!loginUserInfo" class="" to="/member/register">
            <span class="material-icons-outlined" style="font-size: 28px !important"> person_add </span>
          </router-link>
          <router-link v-if="loginUserInfo" class="" to="/mypage">
            <span class="material-icons-outlined" style="font-size: 28px !important"> person </span>
          </router-link>
        </div>

        <div class="wf__header-glob-icon-wrapper">
          <router-link v-if="!loginUserInfo" class="" to="/member/login">
            <span class="material-icons-outlined" style="font-size: 28px !important"> login </span>
          </router-link>
          <a v-if="loginUserInfo" class="" href="#" @click="logout">
            <span class="material-icons-outlined" style="font-size: 28px !important"> logout </span>
          </a>
        </div>

        <div class="wf__header-glob-lang" @click="toggleEng">
          <span class="material-icons-outlined wf__glob-icon"> public </span>

          <span class="uppercase wf__glob-lang-text">{{ LanguageConverter({ en: 'kor', kr: 'eng' }) }}</span>
        </div>

        <span
          class="material-icons-outlined cursor-pointer open_site_map"
          @click="toggleActive($refs.sitemap.$el)"
          style="font-size: 28px !important; transform: translateY(3px)"
        >
          menu
        </span>
      </div>

      <span class="material-icons-outlined wf__header-mobile"> search </span>
    </nav>

    <div class="wf__header-menu-background"></div>

    <MoblieMenusVue
      :menuList="menuList"
      :isOpenMobileMenu="isOpenMobileMenu"
      @toogleMobileMenu="toogleMobileMenu"
      @logout="logout"
      @toggleEng="toggleEng"
      @showModal="showModal"
    ></MoblieMenusVue>
  </header>

  <HeaderSitemapVue ref="sitemap" @showModal="showModal" />

  <ModalVue v-if="isShowModal" @closeModal="closeModal" :closeOnClick="true">
    <HandoverVue :labNo="0"></HandoverVue>
  </ModalVue>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wf__header {
  position: fixed;
  top: 0;

  width: 100vw;
  height: var(--wf-header-height);

  border-bottom: 1px solid var(--wf-gray-400);

  background-color: #fff;

  overflow: hidden;

  z-index: 999;

  transition: height 0.3s ease-in-out;
}
.wf__header.black {
  background-color: transparent;
}
.wf__header.transparent {
  background-color: transparent;
}
.wf__header.fixed {
  position: fixed;
}
.wf__header.white-glob-menu .wf__header-glob,
.wf__header.white-glob-menu .wf__header-glob a {
  color: #fff;
}
.wf__header.white-glob-menu .wf__header-glob .wf__header-glob-lang {
  border-color: #fff;
}

.wf__header-logo img {
  width: 176px;
}
.wf__header.black .wf__header-logo-default,
.wf__header .wf__header-logo-dark {
  display: none;
}
.wf__header.black .wf__header-logo-dark {
  display: block;
}
.wf__header.black .wf__header-wrapper {
  background-color: rgba(0, 0, 0, 0.66);
}

.wf__header.black,
.wf__header.black a {
  color: #fff;
}

.wf__header.black,
.wf__header.black .wf__header-wrapper {
  border: none;
}

.wf__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  min-height: var(--wf-header-height);

  padding: 0 60px;

  border-bottom: 1px solid var(--wf-gray-400);

  background-color: transparent;

  transition: background-color 0.4s ease-in-out;
}

.wf__header-item {
  display: flex;
  align-items: center;
}

.wf__header-menus {
  flex: 1;
  position: relative;

  margin: 0 3vw;
  justify-content: center;
  align-items: stretch;
}

.wf__header-menu-item,
.wf__header-menu-item-dropdown {
  cursor: pointer;
}
.wf__header-menu-item {
  flex-basis: 8em;

  display: flex;
  align-items: center;

  font-size: var(--wf-text-20);
  font-weight: 600;

  border-bottom: 0.4em solid transparent;

  transition: all 0.2s;
  text-align: center;
}
.wf__header-menu-item > span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.wf__header-menu-item-dropdown {
  position: absolute;

  padding: 1em;

  font-size: var(--wf-text-16);
  font-weight: 400;
  color: var(--wf-black-color);

  transition: all 0.4s;
}
.wf__header-menu-item-dropdown li {
  padding-bottom: 0.2em;
  margin-bottom: 0.5em;
}
.wf__header-menu-item-dropdown li span {
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}
.wf__header-menu-item-dropdown li:hover span {
  border-bottom-color: var(--wf-primary-color);
  color: var(--wf-primary-color);
}

.wf__header-menu-background {
  height: 0;
  background-color: #fff;

  transition: height 0.4s ease-in-out;
}
.wf__header:has(.wf__header-menu-item:hover) {
  height: calc(var(--min-height-px) + var(--wf-header-height));
}
.wf__header:has(.wf__header-menu-item:hover) .wf__header-menu-background {
  height: var(--min-height-px);
}
.wf__header-menu-item:hover {
  border-bottom-color: var(--wf-primary-color);
}

.wf__header-glob {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 11px;
}
.wf__header-glob-lang {
  display: flex;
  align-items: center;
  gap: 0.25em;

  padding: 0 0.5em;
  height: 24px;
  transform: translateY(3px);

  border: 2px solid var(--wf-black-color);
  border-radius: 9999px;

  cursor: pointer;
}
.wf__header.black .wf__header-glob-lang {
  border-color: #fff;
}
.wf__header-glob-icon-wrapper {
  height: 24px;
}
.wf__header-glob-lang .wf__glob-icon {
  font-size: 12px !important;
}
.wf__header-glob-lang .wf__glob-lang-text {
  font-size: 13px;
  line-height: 1.2;
}

.wf__header-mobile {
  display: none;
  align-items: center;
  z-index: 2;
}
/* ********** 반응형 ********** */
/* 모바일 */
@media screen and (max-width: 1199px) {
  .wf__header.black,
  .wf__header.black .wf__header-wrapper {
    background-color: transparent;
  }

  .wf__header.transparent {
    background-color: #fff;
  }

  .wf__header-wrapper {
    justify-content: space-between;
    padding: 10px;
    padding-top: 40px;
    min-height: 50px;
  }
  .wf__header-item:not(.wf__header-logo) {
    display: none;
  }

  .wf__header-mobile {
    display: flex;
  }
}

@media screen and (max-width: 576px) {
  .wf__header .wf__header-logo-dark,
  .wf__header .wf__header-logo-default {
    width: 125px;
  }
}
</style>
