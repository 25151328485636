import store from '@/store'
import router from '@/router'

export default [
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/member/Member.vue'),
    // user가 로그인한 상태인 경우 뒤로가기
    beforeEnter: (to, from, next) => {
      const user = store.getters['userStore/info']

      if (user && user.groupName !== 'bot') router.replace('/')
      return next()
    },
    children: [
      {
        path: '',
        name: 'memberRedirect',
        redirect: '/member/login'
      },
      {
        path: 'login', // 로그인 화면
        name: 'MemberLogin',
        component: () => import('../components/Member/MemberLogin.vue')
      },
      {
        path: 'register', // 회원가입
        name: 'MemberRegister',
        component: () => import('../components/Member/MemberRegister.vue')
      },
      {
        path: 'findId', // 아이디 찾기
        name: 'MemberFindId',
        component: () => import('../components/Member/Find/Id.vue')
      },
      {
        path: 'findPw', // 비밀번호 찾기
        name: 'MemberFindIdPw',
        component: () => import('../components/Member/Find/Pw.vue')
      }
    ]
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('../views/member/MyPage.vue'),
    beforeEnter: (to, from, next) => {
      const user = store.getters['userStore/info']

      if (!user || user.groupName === 'bot') router.replace('/member/login')
      return next()
    }
  }
]
