<script>
import NewsSliderVue from './Content2/NewsSlider.vue'
import NewsSliderPagerVue from './Content2/NewsSliderPager.vue'
import UniversitySliderVue from './Content2/UniversitySlider.vue'
import UniversitySliderControllerVue from './Content2/UniversitySliderController.vue'
import { getLabPjt, getLabPatent } from '@/api/platform/community'
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { NewsSliderVue, NewsSliderPagerVue, UniversitySliderVue, UniversitySliderControllerVue },
  data() {
    return {
      htmlParser: new DOMParser(),
      newsSwiper: null,
      navItems: [],
      labNewsList: [],
      slideLength: 0,

      universityNewsList: [],
      universitySlidePause: false
    }
  },
  created() {
    this.loadLabNewsData()
    this.loadUniversityNewsData()
  },
  mounted() {
    this.newsSwiper = this.$refs.slide.swiper
  },
  methods: {
    LanguageConverter,
    setNavItemRefs(el) {
      if (this.slideLength === this.navItems.length) return
      this.navItems.push(el)
    },

    async loadLabNewsData() {
      const data = await getLabPatent({ limit: 5, order: 'patentRegisterDate' })

      if (data.code) {
        // alert(data.message)
        return null
      }
      if (data.status) {
        return null
      }

      const filteredData = data.items

      this.labNewsList = filteredData.map((item) => {
        return {
          ...item,
          title: item.patentResultTitle,
          descList: [
            `(${item.patentResultID})`, //
            `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
            item.patentRegistType,
            item.patentIprType,
            item.patentRegistrant
          ]
        }
      })
      this.slideLength = this.labNewsList.length
    },

    async loadUniversityNewsData() {
      const data = await getLabPjt({ limit: 10, order: 'projectPeriodTotalStart' })

      if (!(data.items instanceof Array)) return

      const mappedData = data.items.map((item) => {
        return {
          ...item,
          title: item.projectTitleKr,
          desc: `
          ${item.projectYear ? new Date(item.projectYear).getFullYear() : ''} <span style="padding: 0 .5em">/</span>
          (${item.projectNumber}) <br/>
          ${item.projectResearchAgency} <span style="padding: 0 .5em">/</span>
          ${item.projectMinistry}
          `
        }
      })

      this.universityNewsList = mappedData
    },

    moveSlide(idx) {
      for (const item of this.navItems) {
        item.classList.remove('active')
      }
      this.navItems[idx].classList.add('active')
      this.carousel.slideTo(idx)
    },
    universitySliderControllerHandler(state) {
      this.universitySlidePause = state
    }
  }
}
</script>

<template>
  <div class="home-section wf__full-width">
    <div class="wf__home-content">
      <div class="wf__background"></div>

      <div class="wf__home-content-news">
        <div class="wf__home-content-news-describe">
          <div class="wf__home-content-title-wrapper">
            <h3 class="wf__home-content-subtitle">Space connecting the lab to the enterprise</h3>
            <h1 class="wf__home-content-title">
              {{ LanguageConverter({ en: 'Patents', kr: '지재권' }) }}
            </h1>
            <!-- <p class="wf__home-content-news-desc">
              {{
                LanguageConverter({
                  en: 'By integrating various core competencies, we share integrated platform information and performance information.',
                  kr: ' 다양한 분야와 핵심역량을 중심기반의 플랫폼 연구정보와 성과정보를 공유합니다'
                })
              }}
            </p> -->
          </div>

          <div class="wf__home-content-news-navi-wrapper">
            <router-link class="wf__home-content-news-more-link" to="/community/patent">
              {{ LanguageConverter({ en: 'MORE', kr: '더보기' }) }}
            </router-link>

            <NewsSliderPagerVue
              v-if="newsSwiper"
              class="wf__home-content-news-pager"
              :slideLength="slideLength"
              :swiper="newsSwiper"
            ></NewsSliderPagerVue>
          </div>
        </div>

        <div class="wf__home-content-news-slide">
          <NewsSliderVue :slideList="labNewsList" ref="slide"></NewsSliderVue>
        </div>
      </div>

      <div class="wf__home-content-university">
        <div class="wf__home-content-title-wrapper">
          <h3 class="wf__home-content-subtitle">Projects</h3>
          <h1 class="wf__home-content-title notice">
            {{ LanguageConverter({ en: 'Projects', kr: '프로젝트' }) }}

            <UniversitySliderControllerVue
              class="wf__home-content-university-pager"
              @toggleHandler="universitySliderControllerHandler"
            ></UniversitySliderControllerVue>
          </h1>
        </div>

        <UniversitySliderVue
          v-if="universityNewsList.length !== 0"
          :universitySlidePause="universitySlidePause"
          :slideList="universityNewsList"
        ></UniversitySliderVue>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.home-section {
  height: 100vh;
  min-height: 700px;
  font-size: var(--wf-text-16);
}

.wf__home-content {
  position: relative;
  display: flex;
  gap: 10vh;
  flex-direction: column;
  margin-left: 15vw;
  height: 100%;
}

.wf__background {
  position: absolute;
  right: 0;
  width: 24vw;
  background-color: var(--wf-primary-color);
  height: 100vh;
  z-index: -1;
}

.wf__home-content-news {
  display: flex;

  margin-top: 16vh;
}
.wf__home-content-news-describe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 410px;
}
.wf__home-content-title {
  font-size: var(--wf-text-48);
  font-weight: 700;
  letter-spacing: -0.1em;
}
.wf__home-content-title.notice {
  margin-bottom: var(--wf-text-30);
}
.wf__home-content-subtitle {
  font-size: var(--wf-text-14);
  color: var(--wf-primary-color);
  font-weight: 500;
}
.wf__home-content-news-desc {
  margin-top: 25px;
  width: 19em;
}
.wf__home-content-news-navi-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 13px;
}
.wf__home-content-news-more-link {
  border-bottom: 2px solid var(--wf-black-color);
}

.wf__home-content-news-slide {
  flex: 1;
  max-width: 100%;
}
.wf__home-content-university {
  flex: 1;
}
.wf__home-content-university-pager {
  display: inline-flex;
}

@media screen and (max-width: 992px) {
  .wf__home-content {
    margin-left: 0;
  }
  .wf__home-content-news {
    margin-top: var(--wf-header-height);
    flex-direction: column-reverse;
    gap: 20px;
  }
  .wf__home-content-news-describe {
    flex-direction: row;
    min-width: 0px;
  }
  .wf__home-content-title-wrapper {
    margin-left: 28px;
  }
  .wf__home-content-title {
    font-size: 40px;
  }
  .wf__home-content-subtitle {
    font-size: 12px;
  }
  .wf__home-content-news-desc {
    margin-top: 8px;
  }
  .wf__home-content-news-pager {
    display: none;
  }
  .wf__home-content-news-more-link {
    display: flex;
    align-items: flex-end;
  }
  .wf__background {
    height: 370px;
    border-bottom-left-radius: 30px;
  }
}
</style>
