<script>
import CommunitiesVue from '@/components/Home/Content5/Communities.vue'
import RemarkableLabsVue from '@/components/Home/Content5/RemarkableLabs.vue'
import NewestBoardsVue from '@/components/Home/Content5/NewestBoards.vue'
import NoticeCarouselVue from '@/components/Home/Content5/NoticeCarousel.vue'
import ApplyVue from '@/components/Home/Content5/Apply.vue'

import { loadRemarkableLabs } from '@/views/labs/RemarkableLabs'
import { getLabPaper, getLabPatent, getLabPjt, getTechHandover, getNotice } from '@/api/platform/community'
import { topBoards } from '@/api/platform/search'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { CommunitiesVue, RemarkableLabsVue, NewestBoardsVue, NoticeCarouselVue, ApplyVue },
  data() {
    return {
      communityDataList: [],
      remarkableLabDataList: [],
      newestBoardDataList: [],
      noticeDataList: []
    }
  },
  async mounted() {
    this.loadNewestDataList()
    this.loadNoticeDataList()
    this.loadCommunityDataList()
    this.loadRemarkableLabDataList()
  },
  methods: {
    mapPjtData(item) {
      return {
        id: item.projectId,
        type: LanguageConverter({ en: 'Project', kr: '프로젝트' }),
        title: LanguageConverter({ en: item.projectTitleEn, kr: item.projectTitleKr }),
        textContents: [
          `(${item.projectNumber})`,
          `${item.projectYear ? new Date(item.projectYear).getFullYear() : ''}`,
          item.projectResearchAgency,
          item.projectMinistry
        ]
      }
    },
    mapPaperData(item) {
      return {
        id: item.paperId,
        type: LanguageConverter({ en: 'Paper', kr: '논문' }),
        title: item.paperResultTitle,
        textContents: [
          `(${item.paperResultID})`, //
          `${item.paperPubYear ? new Date(item.paperPubYear).getFullYear() : ''}`,
          item.paperJCType,
          item.paperJournalName
        ]
      }
    },
    mapPatentData(item) {
      return {
        id: item.patentId,
        type: LanguageConverter({ en: 'Patent', kr: '지재권' }),
        title: item.patentResultTitle,
        textContents: [
          `(${item.patentResultID})`, //
          `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
          item.patentRegistType,
          item.patentIprType,
          item.patentRegistrant
        ]
      }
    },
    mapHandoverData(item) {
      return {
        id: item.handoverId,
        type: LanguageConverter({ en: 'Tech Cooperation', kr: '기업협력' }),
        title: LanguageConverter({ en: item.techNameEn, kr: item.techName }),
        textContents: [
          `${item.techTransferYear ? new Date(item.techTransferYear).getFullYear() : ''}`, //
          item.techGrowLevel,
          item.techStatus,
          item.techApplyField
        ]
      }
    },
    async loadCommunityDataList() {
      const pjt = (await getLabPjt({ limit: 2 })).items
      const paper = (await getLabPaper({ limit: 2 })).items
      const patent = (await getLabPatent({ limit: 2 })).items
      const handover = (await getTechHandover({ limit: 2 })).items

      this.communityDataList = {
        pjt: pjt instanceof Array ? pjt.map(this.mapPjtData).slice(0, 2) : [],
        paper: paper instanceof Array ? paper.map(this.mapPaperData).slice(0, 2) : [],
        patent: patent instanceof Array ? patent.map(this.mapPatentData).slice(0, 2) : [],
        handover: handover instanceof Array ? handover.map(this.mapHandoverData).slice(0, 2) : []
      }
    },
    async loadRemarkableLabDataList() {
      if (this.getStoredLabList().length === 0) {
        const loadedLabList = await loadRemarkableLabs()
        this.$store.dispatch('labStore/SetRemarkableLabList', loadedLabList)
      }

      this.remarkableLabDataList = this.getStoredLabList()
    },
    async loadNewestDataList() {
      const dataList = await topBoards({ limit: 3, order: 'createdAt' })

      if (!(dataList instanceof Array)) return

      this.newestBoardDataList = dataList.slice(0, 3).map((item) => {
        if (item.projectId) {
          return {
            badge: LanguageConverter({ en: 'Project', kr: '프로젝트' }),
            type: 'pjt',
            id: item.projectId,
            title: LanguageConverter({ en: item.projectTitleEn, kr: item.projectTitleKr })
          }
        }

        if (item.paperId) {
          return { badge: LanguageConverter({ en: 'Paper', kr: '논문' }), type: 'paper', id: item.paperId, title: item.paperResultTitle }
        }

        if (item.patentId) {
          return {
            badge: LanguageConverter({ en: 'Patent', kr: '지재권' }),
            type: 'patent',
            id: item.patentId,
            title: item.patentResultTitle
          }
        }

        if (item.handoverId) {
          return {
            badge: LanguageConverter({ en: 'Tech Cooperation', kr: '기업협력' }),
            type: 'handover',
            id: item.handoverId,
            title: LanguageConverter({ en: item.techNameEn, kr: item.techName })
          }
        }
      })
    },
    async loadNoticeDataList() {
      const data = await getNotice({ limit: 5, noticeType: 0 })

      if (!(data.items instanceof Array)) return

      this.noticeDataList = data.items
    },
    getStoredLabList() {
      return this.$store.state.labStore.remarkableLabList.map((item) => {
        return {
          src: item.leadResearcherImgUrl,
          data: {
            title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
            subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
            infoList: [
              {
                head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
                desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
              },
              {
                head: LanguageConverter({ en: 'MainField', kr: '분야' }),
                desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
              }
            ],
            link: `/lab/${item.labNo}`
          }
        }
      })
    }
  }
}
</script>

<template>
  <section class="home-section">
    <div class="wf__home-communities">
      <CommunitiesVue v-if="communityDataList.length !== 0" :communityDataList="communityDataList"></CommunitiesVue>
    </div>

    <div class="wf__home-remarkables">
      <RemarkableLabsVue v-if="remarkableLabDataList.length !== 0" :remarkableLabDataList="remarkableLabDataList"></RemarkableLabsVue>
    </div>

    <div class="wf__home-newests">
      <NewestBoardsVue v-if="newestBoardDataList.length !== 0" :newestBoardDataList="newestBoardDataList"></NewestBoardsVue>
    </div>

    <div class="wf__home-notices">
      <NoticeCarouselVue v-if="noticeDataList.length !== 0" :noticeDataList="noticeDataList"></NoticeCarouselVue>
      <ApplyVue v-if="noticeDataList.length !== 0"></ApplyVue>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.home-section {
  display: grid;
  grid-template-columns: 1fr 1.3fr 375px;
  gap: 4rem;

  width: 100%;
  min-height: 80vh;

  padding-top: calc(var(--wf-header-height) + 5vh);
  padding-bottom: 6vh;
}
.wf__home-communities {
  grid-column: 1 / span 3;
}

.wf__home-newests,
.wf__home-notices,
.wf__home-remarkables {
  max-width: calc(100vw - 20px);
}
.wf__home-notices {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
}

@media screen and (max-width: 1024px) {
  .home-section {
    grid-template-rows: repeat(3, 1fr);
  }
  .wf__home-remarkables {
    grid-row: 3 / 4;
  }
  .wf__home-notices {
    grid-row: 3 / 4;
    grid-column: 2 / 4;
  }
  .wf__home-newests {
    grid-row: 2 / 3;
    grid-column: 1 / span 3;
  }
}
@media screen and (max-width: 768px) {
  .home-section {
    grid-template-rows: repeat(4, 1fr);
  }
  .wf__home-notices {
    grid-row: 4 / 5;
    grid-column: 1 / span 3;
  }
  .wf__home-remarkables {
    grid-column: 1 / span 3;
  }
}

:deep(.wf__pager) {
  padding-top: 3px;
  padding-bottom: 3px;
}
:deep(.wf__pager span),
:deep(.wf__pager span:nth-child(2)) {
  font-size: var(--wf-text-16) !important;
}
</style>
