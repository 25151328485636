//INFO: 화면설명
<script setup>
import LanguageConverter from '@/util/LanguageConverter'
import sanitizer from '@/util/htmlSanitizer'

import MobileContent1 from '@/components/Home/Content3/MobileContent1.vue'
import MobileContent2 from '@/components/Home/Content3/MobileContent2.vue'
import MobileContent3 from '@/components/Home/Content3/MobileContent3.vue'
import MobileContent4 from '@/components/Home/Content3/MobileContent4.vue'

import slide1 from '@/assets/images/003.jpg'
import slide2 from '@/assets/images/001.jpg'
import slide3 from '@/assets/images/002.jpg'
import slide4 from '@/assets/images/004.jpg'

import { onMounted, ref, defineProps } from 'vue'

const props = defineProps(['techMaxNumberList', 'boardsMaxNumberList', 'sixTechLargeList', 'labList'])

const touchStartX = ref(0)
const touchEndX = ref(0)
const slideIndex = ref(0)
const lab = ref(null)

// const randNum = Math.floor(Math.random() * (props.labList.length - 1))

// 섹션 slide 배열
const slides = [slide1, slide2, slide3, slide4]

// 섹션 내용별 텍스트 정해주기
const constTextContents = [
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Technology Transfer', kr: '기술이전' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Core <br/> Competency', kr: '역량' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Fields', kr: '분야' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Labs', kr: '연구실' }),
    desc: ''
  }
]

const startIndex = 0
const lastIndex = constTextContents.length - 1

const handleStart = (e) => {
  touchStartX.value = e.changedTouches[0].clientX
}

const handleEnd = (e) => {
  touchEndX.value = e.changedTouches[0].clientX

  const moved = touchEndX.value - touchStartX.value

  if (Math.abs(moved) < 30) return

  if (moved < 0) {
    if (slideIndex.value === lastIndex) return
    slideIndex.value++
  } else {
    if (slideIndex.value === startIndex) return
    slideIndex.value--
  }
}

// 모바일 슬라이드 이벤트
const mobileSlide = async () => {
  // 슬라이드할 요소 가져오기
  const slide1 = document.querySelector('.section_content1')
  const slide2 = document.querySelector('.section_content2')
  const slide3 = document.querySelector('.section_content3')
  const slide4 = document.querySelector('.section_content4')

  // 슬라이드 요소에 이벤트 걸어주기
  slide1.addEventListener('touchstart', handleStart, false)
  slide1.addEventListener('touchend', handleEnd, false)
  slide2.addEventListener('touchstart', handleStart, false)
  slide2.addEventListener('touchend', handleEnd, false)
  slide3.addEventListener('touchstart', handleStart, false)
  slide3.addEventListener('touchend', handleEnd, false)
  slide4.addEventListener('touchstart', handleStart, false)
  slide4.addEventListener('touchend', handleEnd, false)
}

onMounted(async () => {
  await mobileSlide()
})
</script>

<template>
  <!-- 홈 섹션 3 시작 -->
  <section class="home-section">
    <section class="wf__slide-section" :data-idx="slideIndex">
      <!-- 내용 시작 -->
      <article
        v-for="(slide, i) in slides"
        :key="i"
        :data-idx="i"
        class="wf__home-slide"
        :class="`section_content${i + 1}`"
        :style="{ 'background-image': `url(${slide})` }"
      >
        <div class="wf__overlay"></div>

        <div class="wf__content-wrapper">
          <div class="wf__const-text" :data-idx="i">
            <h5 class="wf__const-subtitle" v-html="sanitizer(constTextContents[i].subtitle)"></h5>
            <h1 class="wf__const-title" v-html="sanitizer(constTextContents[i].title)"></h1>
            <p class="wf__const-desc" v-html="sanitizer(constTextContents[i].desc)"></p>
          </div>

          <div>
            <MobileContent1 v-if="i === 0" :numbers="techMaxNumberList"></MobileContent1>
            <MobileContent2 v-if="i === 1" :numbers="boardsMaxNumberList"></MobileContent2>
            <MobileContent3 v-if="i === 2 && sixTechLargeList.length !== 0" :sixTechLargeList="sixTechLargeList"></MobileContent3>
            <MobileContent4 v-if="i === 3 && labList.length !== 0" :labList="labList"></MobileContent4>
          </div>
        </div>
      </article>
      <!-- 내용 시작 /article-->
    </section>
  </section>
  <!-- 홈 섹션 3 끝 -->
</template>

<style scoped>
.home-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 720px;
  margin-left: calc(-50vw + 50%);
}
.wf__slide-section {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.wf__home-slide {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  min-height: 720px;

  transition: translate 0.4s ease;
}
.wf__content-wrapper {
  position: absolute;
  top: 180px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 var(--wf-text-30);
  color: #fff;
}
.wf__const-text {
  color: var(--wf-misty-color);

  text-align: left;

  margin-bottom: var(--wf-text-50);
}
.wf__const-subtitle {
  font-size: var(--wf-text-14);
}
.wf__const-title {
  font-size: var(--wf-text-48);
  font-weight: bold;
}
.wf__const-desc {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

.wf__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
}

.wf__slide-section[data-idx='0'] .wf__home-slide {
  translate: 0vw;
}
.wf__slide-section[data-idx='1'] .wf__home-slide {
  translate: -100vw;
}
.wf__slide-section[data-idx='2'] .wf__home-slide {
  translate: -200vw;
}
.wf__slide-section[data-idx='3'] .wf__home-slide {
  translate: -300vw;
}
</style>
