//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  props: {
    maxNumberList: {
      type: Array
    }
  },
  data() {
    return {
      numberFormatter: new Intl.NumberFormat(), // 숫자 3자리 콤마
      timeout: null // mounted 시 작동할 timeout
    }
  },
  setup() {},
  created() {},

  methods: {
    LanguageConverter,
    // 숫자 count up 함수
    countUp(target, maxCount) {
      let now = maxCount

      // 0.05초에 한 번 씩 동작
      const handle = setInterval(() => {
        // 원하는 DOM Element의 textContent에 계산한 수 출력 (Math.ceil => 올림)
        target.textContent = this.numberFormatter.format(Math.ceil(maxCount - now))

        // 현재 계산 값이 1보다 작을때 interval 종료
        if (now < 1) return clearInterval(handle)

        const step = now / 10
        now -= step
      }, 50)
    },
    setCountUpTimeout() {
      this.timeout = setTimeout(() => {
        this.countUp(this.$refs.countFields, this.maxNumberList[0])
        this.countUp(this.$refs.countHandovers, this.maxNumberList[1])
      }, 1400)
    }
  },
  mounted() {
    // 1.4초 뒤 countUp 함수 실행
    this.setCountUpTimeout()
  },
  unmounted() {
    // timeout 종료
    clearTimeout(this.timeout)
  }
}
</script>

<template>
  <section class="wf__animate-section">
    <div class="wf__animate-head">
      <h2 class="wf__animate-head-subtitle animate__animated animate__fadeInDown">
        {{ LanguageConverter({ en: 'New technology', kr: '새로운 기술' }) }}
      </h2>
      <h1 class="wf__animate-head-title animate__animated animate__fadeInDown">
        {{ LanguageConverter({ en: 'Starts With PromisingLabs', kr: '유망에서 시작하다' }) }}
      </h1>
    </div>

    <div class="wf__animate-body">
      <div class="wf__animate-body-content first animate__animated animate__rotateIn">
        <span class="wf__animate-body-label">
          {{ LanguageConverter({ en: 'Fields', kr: '기술분야' }) }}
        </span>
        <span class="wf__animate-body-number" ref="countFields"></span>
        <span class="wf__animate-body-tail">
          {{ LanguageConverter({ en: 'fields', kr: '개 분야' }) }}
        </span>
      </div>
      <div class="wf__animate-body-content second animate__animated animate__rotateIn">
        <span class="wf__animate-body-label">{{ LanguageConverter({ en: 'Cooperations', kr: '기업협력' }) }}</span>
        <span class="wf__animate-body-number" ref="countHandovers"></span>
        <span class="wf__animate-body-tail">{{ LanguageConverter({ en: 'cases', kr: '건' }) }}</span>
      </div>
    </div>

    <div class="wf__animate-foot"></div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__animate-section {
  position: absolute;
  top: 15%;
  left: 20%;
  text-align: left;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wf__animate-head {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding-right: 5rem;
}
.wf__animate-body {
  flex: 2;
  color: white;
  display: flex;
  align-items: flex-end;
  margin-left: 17%;

  font-size: 2rem;
}
.wf__animate-foot {
  flex: 5;
}

.wf__animate-head-subtitle {
  font-size: 80px;
  font-weight: 900;

  color: transparent;
  -webkit-text-stroke: 2px var(--wf-misty-color);

  animation-duration: 0.6s;
  animation-delay: 0.4s;
}
.wf__animate-head-title {
  color: var(--wf-misty-color);
  font-size: 80px;
  font-weight: 700;

  animation-duration: 0.6s;
  animation-delay: 1s;
}

.wf__animate-body-content {
  margin-left: 6rem;
  position: relative;

  animation-duration: 0.6s;
}
.wf__animate-body-content.first {
  animation-delay: 1.6s;
}
.wf__animate-body-content.second {
  animation-delay: 1.8s;
}
.wf__animate-body-label {
  position: absolute;
  top: 4em;
  left: -3em;

  width: max-content;

  font-size: 24px;
  font-weight: 700;
  transform: rotate(-90deg);
}
.wf__animate-body-tail {
  font-size: 24px;
  font-weight: 700;
}
.wf__animate-body-number {
  font-size: 166px;
  color: #28e5d5;
  font-weight: 700;
}

.wf__animate-foot-content {
  padding: 2rem 0;
  animation-duration: 0.6s;
  animation-delay: 2.4s;
  color: var(--wf-misty-color);

  border: 2px solid var(--wf-misty-color);
  border-left: none;
  border-right: none;
}
.wf__animate-foot-content-title {
  color: var(--wf-primary-color);
  font-size: 3rem;
  font-weight: bold;
}
.wf__animate-foot-content-list {
  display: flex;
  gap: 1.5em;
  font-size: 1.5rem;
}
</style>
