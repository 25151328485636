import { createStore } from 'vuex'

import exampleStore from './exampleStore'
import menuStore from './menuStore'
import userStore from './userStore'
import searchStore from './searchStore'
import searchStateStore from './searchStateStore'
import labStore from './labStore'
import imageStore from './imageStore'

export default createStore({
  // state: store 초기 값 설정
  state: {
    basicInfo: null,
    langMode: 'kor'
  },
  // getters: store 값 조회 시 실행하는 함수
  getters: {
    basicInfo: (state) => state.basicInfo,
    langMode: (state) => state.langMode,
    isEng: (state) => state.langMode === 'eng'
  },
  // mutations: store 값 수정 시 실행하는 함수
  mutations: {
    SET_BASIC_INFO(state, basicInfo) {
      state.basicInfo = basicInfo
    },
    SET_LANG_MODE(state, langMode) {
      state.langMode = langMode
    }
  },
  // actions: store를 수정 할 값을 mutations로 전달하는 함수
  actions: {
    SetBasicInfo({ commit, state }, basicInfo) {
      commit('SET_BASIC_INFO', basicInfo)
    },
    SetLangMode({ commit, state }) {
      commit('SET_LANG_MODE', sessionStorage.getItem('lang_mode'))
    },
    ToogleLangMode({ commit, state }) {
      const langMode = sessionStorage.getItem('lang_mode') === 'kor' ? 'eng' : 'kor'
      sessionStorage.setItem('lang_mode', langMode)
      commit('SET_LANG_MODE', langMode)
    }
  },
  // modules: module store적용
  modules: {
    exampleStore,
    menuStore,
    userStore,
    searchStore,
    labStore,
    imageStore,
    searchStateStore
  }
})
