<template>
  <!-- 유망연구실 홈페이지 -->
  <main class="wf__main" v-if="!isAdminPage">
    <app-header></app-header>

    <section class="wf__main-container">
      <router-view />
    </section>

    <app-footer></app-footer>
  </main>

  <!-- 유망연구실 관리자 -->
  <main v-if="isAdminPage" class="wf__admin-main">
    <app-header-admin></app-header-admin>

    <section class="wf__admin-main-container">
      <router-view />
    </section>
  </main>
</template>

<script>
import AppHeader from '@/components/Header/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeaderAdmin from '@/components/AppHeaderAdmin.vue'

export default {
  props: {},
  components: { AppHeader, AppFooter, AppHeaderAdmin },
  data() {
    this.$store.dispatch('SetLangMode')

    return {
      isAdminPage: window.location.pathname.includes('admin')
    }
  },
  watch: {
    $route(to, from) {
      this.isAdminPage = to.matched[0].path.includes('admin')

      const r = document.querySelector(':root')
      if (this.isAdminPage) {
        r.style.fontSize = '16px'
      } else {
        r.style.fontSize = '10px'
      }

      this.checkHome(to.fullPath)
      this.hideLicenseError()
    }
  },
  setup() {},
  created() {},
  mounted() {
    if (!sessionStorage.getItem('lang_mode')) sessionStorage.setItem('lang_mode', 'kor')
  },
  unmounted() {},
  methods: {
    hideLicenseError() {
      const interval = setInterval(() => {
        const licenseError = document.querySelector('#js-licensing')
        if (!licenseError) return

        clearInterval(interval)
        licenseError.remove()
      }, 100)
    },
    checkHome(path) {
      const interval = setInterval(() => {
        const mainContainer = document.querySelector('.wf__main-container')

        if (!mainContainer) return
        clearInterval(interval)

        if (path === '/') mainContainer.classList.add('wf__home')
        else {
          const header = document.querySelector('.wf__header')

          mainContainer.classList.remove('wf__home')

          header.classList.remove('black')
          header.classList.remove('transparent')
          header.classList.remove('fixed')
        }
      }, 100)
    }
  }
}
</script>

<style scoped>
.wf__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__main-container {
  width: 100%;
  margin-top: var(--wf-header-height);
  padding: 0 10px;
  max-width: var(--wf-container-width);
}
.wf__main-container.wf__home {
  margin-top: 0;
}

/* 관리자 설정*/
.wf__admin-main {
  color: #2c3e50;
  display: flex;
}
.wf__admin-main-container {
  flex-grow: 1;
  margin: 4rem 3rem;
}
</style>
