<script setup>
import { useRouter } from 'vue-router'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import MainBackground from '@/assets/images/HomeMainBackground.jpg'

import IntroduceVue from '@/components/Home/Content1/Introduce.vue'
import SearchBarVue from '@/components/Home/Content1/SearchBar.vue'
import CardsVue from '@/components/Home/Content1/Cards.vue'

const src = MainBackground
const height = '100vh'

const router = useRouter()
const searchAction = (keyword, option) => {
  const url = `/integratedSearch/integratedSearchResult?q=${keyword}&opt=${option}`
  router.push(url)
}
</script>

<template>
  <section class="home-section">
    <ImageBackgroundVue class="wf__background" :src="src" :height="height">
      <div class="wf__home-content">
        <IntroduceVue class="wf__home-intro" />

        <SearchBarVue class="wf__home-search" @searchAction="searchAction" />

        <CardsVue class="wf__home-cards" />
      </div>
    </ImageBackgroundVue>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__background {
  min-width: 100vw;
  min-height: 886px;
  margin-left: calc(-50vw + 50%);
}

.wf__home-content {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 3.2em;

  padding: 0 1em;
}
.wf__home-intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.6em;
}
.wf__home-search {
  max-width: 876px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
}
.wf__home-cards {
  margin-top: 3.2em;
}

@media screen and (max-width: 992px) {
  .wf__home-intro {
    justify-content: center;
  }
  .wf__home-search {
    min-width: 0px;
  }
  .wf__home-content {
    width: 100vw;
    padding: 0 30px;
  }
}
</style>
