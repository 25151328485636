//INFO: 화면설명
<script>
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Autoplay } from 'swiper'

import sanitizer from '@/util/htmlSanitizer'

export default {
  components: { Swiper, SwiperSlide },
  props: ['universitySlidePause', 'slideList'],
  data() {
    return {
      slidesPerView: 4,
      items: [],
      modules: [Autoplay]
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$el.swiper
    }
  },
  watch: {
    universitySlidePause() {
      if (this.universitySlidePause) return this.swiper.autoplay.stop()
      return this.swiper.autoplay.start()
    }
  },
  setup() {},
  created() {},
  mounted() {
    const observer = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.contentRect.width <= 576) {
          this.slidesPerView = 1
        } else if (entry.contentRect.width <= 992) {
          this.slidesPerView = 2
        } else {
          this.slidesPerView = 4
        }
      }
    })

    observer.observe(this.swiper.el)
  },
  unmounted() {},

  methods: {
    sanitizer,
    goNews(id) {
      this.$router.push(`/community/pjt/${id}`)
    }
  }
}
</script>

<template>
  <div class="wf__slider">
    <swiper
      :speed="1000"
      :slides-per-view="slidesPerView"
      :centeredSlides="true"
      :spaceBetween="30"
      :loop="true"
      :autoplay="{
        delay: 1000,
        disableOnInteraction: false
      }"
      :modules="modules"
      ref="swiper"
    >
      <swiper-slide class="cursor-pointer" v-for="(slide, i) in slideList" :key="i">
        <div class="wf__slider-item" @click="goNews(slide.projectId)">
          <div class="wf__slider-item-title">
            {{ slide.title }}
          </div>

          <div class="divider"></div>

          <div class="wf__slider-item-desc" v-html="sanitizer(slide.desc)"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>
.wf__slider {
  height: 100%;

  width: 100vw;
  margin-left: calc(-50vw + 41.2%);
}

.wf__slider-item {
  width: 100%;
  height: 150px;
  background-color: var(--wf-primary-color);
  border: 3px solid #fff;
  border-radius: 1em;
  color: #fff;
  cursor: pointer;

  text-align: left;

  margin-right: 35px;

  padding: 18px 26px;
}
.divider {
  width: 2.2em;
  height: 3px;
  background-color: #fff;
  margin: 12px 0;
}
.wf__slider-item-title {
  font-size: var(--wf-text-20);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wf__slider-item-desc {
  font-size: var(--wf-text-16);
  font-weight: 400;
  max-height: 5em;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}

.swiper-slide-prev,
.swiper-slide-next {
  transition: opacity 0.7s ease;
}

@media screen and (max-width: 992px) {
  .wf__slider {
    margin: 0;
  }
  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.45;
  }
}
</style>
