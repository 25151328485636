import { useAxios } from '../axiosUtil'
import router from '@/router'

const root = '/v1/six-technology'

/**
 * 6T분류 가져오기
 * TODO: 연구실 정보, 등등
 * @return {Array}
 * */
export const getSixTechnology = async () => {
  const result = await useAxios(`${root}`)

  if (result) {
    return result
  }

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getSixTechnologyByLarge = async (large) => {
  const queryUrl = `/v1/six-technology?sixTechnologyLarge=${large}`
  const result = await useAxios(`${queryUrl}`)

  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getSixTechnologyByMeduim = async (meduim) => {
  const queryUrl = `/v1/six-technology?sixTechnologyMedium=${meduim}`
  const result = await useAxios(`${queryUrl}`)

  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getSixTechnologyBySmall = async (small) => {
  const queryUrl = `/v1/six-technology?sixTechnologySmall=${small}`
  const result = await useAxios(`${queryUrl}`)

  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getSixTechnologyByCode = async (code) => {
  const queryUrl = `/v1/six-technology?sixTechnologyCode=${code}`
  const result = await useAxios(`${queryUrl}`)

  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}
