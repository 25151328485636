<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  data() {
    return {}
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter,
    goPage(link) {
      this.$router.push(link)
    },
    showModal() {
      const modalButton = document.querySelector('li[data-path="showModal"]')
      modalButton.click()
    }
  }
}
</script>

<template>
  <div class="wf__apply">
    <div class="wf__overlay"></div>

    <div class="wf__apply-content">
      <div class="wf__apply-title-wrapper">
        <div>
          <div class="wf__apply-subtitle">APPLY</div>
          <div class="wf__apply-title cursor-pointer" @click="showModal">
            {{ LanguageConverter({ en: 'Search', kr: '연구실협력신청' }) }}
          </div>
        </div>
      </div>

      <div class="wf__apply-item-wrapper">
        <div class="wf__apply-item" @click="goPage('/integratedSearch')">
          {{ LanguageConverter({ en: 'Search Tech', kr: '기술 통합검색' }) }}

          <span class="wf__apply-more-link material-icons-outlined"> expand_circle_down </span>
        </div>
        <div class="wf__apply-item" @click="goPage('/researchField')">
          {{ LanguageConverter({ en: 'Search Labs', kr: '연구분야 검색' }) }}

          <span class="wf__apply-more-link material-icons-outlined"> expand_circle_down </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__apply {
  position: relative;
  height: 100%;
  min-height: 200px;

  background-image: url('../../../assets/images/002.jpg');
  background-size: cover;
  background-position: center;
}
.wf__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.wf__apply-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;

  padding: 19px 20.5px;
}
.wf__apply-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #fff;

  padding-bottom: 6px;
}
.wf__apply-subtitle {
  font-size: var(--wf-text-14);
  font-weight: 300;
}
.wf__apply-title {
  font-size: var(--wf-text-24);
  font-weight: 600;
  line-height: 1.3;
}
.wf__apply-more-link {
  font-size: var(--wf-text-18) !important;
  rotate: 270deg;
}

.wf__apply-item {
  cursor: pointer;
  font-size: var(--wf-text-16);

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;

  margin-bottom: 5px;
  padding-bottom: 6px;

  font-weight: 500;
}
</style>
