import { useAxios } from './axiosUtil'
import store from '@/store'

const baseUrl = '/v1/auth'

export const signIn = async (userId, password) => {
  const result = await useAxios(`${baseUrl}/sign-in`, {
    method: 'POST',
    data: { userId, password }
  })

  if (result.status === 400) {
    return result.data
  }

  return result
}

export const signUp = async (info) => {
  const result = await useAxios(`${baseUrl}/sign-up`, {
    method: 'POST',
    data: info
  })

  if (result.status === 400) {
    return result.data
  }

  return result
}

// token으로 user정보 조회
const infoAxios = async (tokenType, token) => {
  return useAxios(`${baseUrl}/user-info`, {
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  })
}

export const getLoginUserInfo = async () => {
  // localStorage에 저장된 token값 조회
  const token = localStorage.getItem('token')
  const tokenType = localStorage.getItem('tokenType')

  // token값이 없을 경우
  if (!token) return null

  const result = await infoAxios(tokenType, token)

  if (result.status === 401 && result.data.message === 'jwt must be provided') {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenType')
    return null
  }

  // token값이 만료된 경우
  if (result.status === 401 && result.data.message === 'jwt expired') {
    // refresh token으로 토큰 재발급
    const newToken = await getNewToken()

    // 토큰을 재 발급받지 못한 경우
    if (!newToken) {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenType')
      return null
    }

    localStorage.setItem('token', newToken.accessToken)
    return await infoAxios(tokenType, newToken.accessToken)
  }

  return result
}

export const getNewToken = async () => {
  const rfToken = store.getters['userStore/refreshToken']
  if (!rfToken) return null

  const result = await useAxios(`${baseUrl}/token`, {
    method: 'POST',
    data: {
      refreshToken: rfToken,
      grantType: 'refreshToken'
    }
  })

  return result
}

const findInfo = async (endpoint, data) => {
  const result = await useAxios(`${baseUrl}/${endpoint}`, {
    method: 'POST',
    data
  })

  if (result.status === 400) {
    return result.data
  }

  return result
}

/**
 * 아이디 찾기 API
 * @param {String} email 사용자 email
 * @returns
 */
export const findId = async (email) => {
  return findInfo('forgot-id', { email })
}
/**
 * 비밀번호 찾기
 * @param {String} userId 사용자 id
 * @returns
 */
export const findPw = async (userId) => {
  return findInfo('forgot-password', { userId })
}

const modifyInfo = async (endpoint, opt) => {
  const token = localStorage.getItem('token')
  const tokenType = localStorage.getItem('tokenType')

  const result = await useAxios(`${baseUrl}/${endpoint}`, {
    method: 'PUT',
    data: opt,
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  })

  return result
}

/**
 * 회원 정보 수정 api
 * @param {Object} opt
 *  @opt name: 사용자 이름
 *  @opt email: 사용자 이메일
 *  @opt phoneNumber: 전화번호
 *  @opt advertisementAgreement: 이메일 수신 동의 여부 - 현재는 사용 불가
 */
export const modifyUserInfo = async (opt) => {
  return modifyInfo('user-info', opt)
}

/**
 * 비밀번호 수정 api
 * @param {Object} opt
 *  @opt password: 현재 비밀번호
 *  @opt newPassword: 새 비밀번호
 */
export const modifyPasswordInfo = async (opt) => {
  return modifyInfo('change-password', opt)
}
