<template>
  <div class="dropdown" v-if="options">
    <input type="hidden" :name="name" v-model="searchValue" />
    <!-- Dropdown Button -->
    <button
      type="button"
      class="dropdown-input"
      :class="{ 'no-border': borderNone }"
      :style="{ width: `${calcWidth}px` }"
      @click="showOptions()"
      @blur="exit()"
      :disabled="disabled"
    >
      {{ searchFilter }}

      <span class="material-icons-outlined"> arrow_drop_down </span>
    </button>

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        :ref="setDropdownItemRef"
        @mousedown="selectOption(option)"
        v-for="(option, index) in options"
        :key="index"
      >
        {{ LanguageConverter({ en: option?.textEn, kr: option?.text }) || option.value || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    initialOption: {
      type: Object,
      required: false,
      default: null
    },
    initAfterSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: 'Options of dropdown. An array of options with id and name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    borderNone: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    dropdownStyle: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: true,
      calcWidth: 0,

      searchFilter: '',
      searchValue: '',
      dropdownItems: []
    }
  },
  created() {},
  mounted() {
    if (this.dropdownStyle) {
      const target = this.$el.querySelector('.dropdown-input')
      for (const name in this.dropdownStyle) {
        target.style[name] = this.dropdownStyle[name]
      }
    }

    this.calcWidth = this.calcDropdownItemMaxWidth()
    this.setDropdownItemsWidth(this.calcWidth)

    const option = this.initialOption || this.options[0]
    this.selectOption(option)
  },
  computed: {},
  methods: {
    LanguageConverter,
    setDropdownItemRef(el) {
      this.dropdownItems.push(el)
    },
    calcDropdownItemMaxWidth() {
      return this.dropdownItems.reduce((acc, cur) => (acc > cur.clientWidth ? acc : cur.clientWidth), 0) + 30
    },
    setDropdownItemsWidth(width) {
      for (const item of this.dropdownItems) {
        item.style.width = `${width}px`
      }
    },
    selectOption(option) {
      this.selected = option
      this.optionsShown = false
      this.searchFilter = LanguageConverter({ en: option?.textEn, kr: option?.text })
      this.$emit('selected', option)

      if (this.initAfterSelect) this.searchFilter = this.initialOption.text
    },
    showOptions() {
      if (!this.disabled) {
        this.optionsShown = true
      }
    },
    exit() {
      this.$emit('selected', this.selected)
      this.optionsShown = false
    }
  },
  watch: {
    searchFilter() {
      this.searchValue = this.selected?.value
      this.$emit('filter', this.searchFilter)
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dropdown {
  position: relative;
  display: block;
  margin: auto;
  font-size: 16px;
}

.dropdown-input {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  background: #fff;
  cursor: pointer;
  border: 1px solid var(--wf-gray-500);
  border-radius: 3px;
  color: var(--wf-black-color);
  font-size: 1em;
  padding: 6px;

  text-align: left;
}
.dropdown-input.no-border {
  border: none;
}

.dropdown-input:hover {
  background: var(--wf-misty-color);
}

.dropdown-content {
  position: absolute;
  background-color: #fff;

  border: 1px solid var(--wf-gray-500);
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  z-index: 10;
}

.dropdown-item {
  color: black;
  font-size: 1em;
  line-height: 1;
  padding: 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #e7ecf5;
}
.dropdown:hover .dropdowncontent {
  display: block;
}
</style>
