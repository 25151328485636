<!-- eslint-disable no-irregular-whitespace -->
<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Autoplay } from 'swiper'

import { getContent } from '@/api/admin/setting.js'
import LanguageConverter from '@/util/LanguageConverter'
import { SmallLogos } from '@/assets/images/LabLogos'
import HeaderSitemapVue from '@/components/Header/HeaderSitemap.vue' // 사이트맵 클릭 시 헤더 사이드 메뉴 오픈

export default {
  components: { Swiper, SwiperSlide, HeaderSitemapVue },
  data() {
    return {
      infoData: null,
      SmallLogos,
      modules: [Autoplay],
      slidesPerView: 9
    }
  },
  computed: {
    loginUserInfo() {
      return this.$store.state.userStore.info
    },
    swiper() {
      return this.$refs.swiper.$el.swiper
    }
  },
  setup() {},
  created() {},
  async mounted() {
    const observer = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.contentRect.width > 1199) {
          this.slidesPerView = 9
        } else if (entry.contentRect.width > 993) {
          this.slidesPerView = 6
        } else if (entry.contentRect.width > 769) {
          this.slidesPerView = 4
        } else if (entry.contentRect.width > 500) {
          this.slidesPerView = 3
        } else {
          this.slidesPerView = 2
        }
      }
    })
    observer.observe(this.swiper.el)

    const infoData = await getContent()
    if (!this.$store.state.basicInfo) this.$store.dispatch('SetBasicInfo', infoData)

    this.infoData = this.$store.state.basicInfo

    setTimeout(() => {
      const swiperItems = this.swiper.el.querySelector('.swiper-wrapper').children
      for (const item of swiperItems) {
        const imgWidth = item.querySelector('.wf__footer-cooperator-logo').clientWidth
        item.style.width = `${imgWidth}px`
      }
    }, 200)
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    // INFO: footer 사이트맵 클릭 이벤트
    openSiteMap(el) {
      el.classList.toggle('active')
    }
  }
}
</script>

<template>
  <HeaderSitemapVue ref="sitemap" @showModal="showModal" />
  <footer class="wf__footer">
    <div class="wf__footer-cooperator">
      <swiper
        :speed="1000"
        :slides-per-view="slidesPerView"
        :centeredSlides="true"
        :loop="true"
        :autoplay="{
          delay: 1000,
          disableOnInteraction: false
        }"
        :modules="modules"
        ref="swiper"
      >
        <swiper-slide v-for="(logo, i) in SmallLogos" :key="i">
          <img class="wf__footer-cooperator-logo" :src="logo" alt="" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="wf__footer-menu">
      <ul>
        <li>
          <router-link class="wf__footer-menu-item" to="/about/1">{{
            LanguageConverter({ en: 'TERM OF SERVICE', kr: '이용약관' })
          }}</router-link>
        </li>
        <li>
          <router-link class="wf__footer-menu-item" to="/about/3">
            {{ LanguageConverter({ en: 'PRIVACY POLICY', kr: '개인정보처리방침' }) }}
          </router-link>
        </li>
        <li>
          <router-link class="wf__footer-menu-item" to="/about/20">
            {{ LanguageConverter({ en: 'EMAIL POLICY', kr: '이메일무단수집거부' }) }}
          </router-link>
        </li>
        <li>
          <router-link class="wf__footer-menu-item" to="/about/22">
            {{ LanguageConverter({ en: 'COPYRIGHT ACT', kr: '저작권정책' }) }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="wf__footer-divider"></div>

    <div class="wf__footer-info" v-if="infoData">
      <div class="wf__footer-logo">
        <img src="@/assets/images/logo/color-100.png" />
      </div>

      <ul class="wf__footer-info-company">
        <li>본 서비스는 과학기술정보통신부의 지원을 받아 유망연구실 사업화 연계 및 기술발굴 연계 사업을 통해 운영되고 있습니다.</li>
        <li>COPYRIGHT 2022 Lab2Biz. ALL RIGHTS RESERVED.</li>
        <!-- <li>
          <span class="wf__footer-info-item">
            {{ LanguageConverter({ en: 'COMPANY', kr: '회사명' }) }} :
            {{ LanguageConverter({ en: infoData.companyNameEn, kr: infoData.companyName }) }}
          </span>
          <span class="wf__footer-info-item">
            {{ LanguageConverter({ en: 'ADDRESS', kr: '주소' }) }} :
            {{
              LanguageConverter({
                en: `${infoData.addressMainEn} ${infoData.addressDetailEn}`,
                kr: `${infoData.addressMain} ${infoData.addressDetail}`
              })
            }}
          </span>
          <span class="wf__footer-info-item"> {{ LanguageConverter({ en: 'E-MAIL', kr: '이메일' }) }} : {{ infoData.email }} </span>
        </li>
        <li>
          <span class="wf__footer-info-item">
            {{ LanguageConverter({ en: 'BUSINESS NUMBER', kr: '사업자번호' }) }} :
            {{ infoData.businessNumber }}
          </span>
          <span class="wf__footer-info-item">
            {{ LanguageConverter({ en: 'PRIVACY OFFICER', kr: '개인정보보호담당자' }) }} :
            {{ infoData.privacyManagerName }}
          </span>
        </li> -->
      </ul>

      <!-- <div class="wf__footer-inquiry">
        <div>
          <p class="wf__footer-inquiry-subtitle">
            {{ LanguageConverter({ en: 'CUSTOMER SERVICE', kr: '이용문의' }) }}
          </p>
          <p class="wf__footer-inquiry-title">{{ infoData.phoneNumber }}</p>
          <p class="wf__footer-inquiry-content">
            {{
              LanguageConverter({
                en: 'OPERATING TIME : WEEKDAY(MON~FRI) 09:00-18:00 / ',
                kr: '운영시간 : 평일(월~금) 09:00-18:00 / 토,일,공휴일 휴무'
              })
            }}
          </p>
        </div>
      </div> -->
    </div>
  </footer>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
.openSiteMap {
  cursor: pointer;
}
.wf__footer {
  display: flex; /* 하위 요소 가로 배치 */
  flex-direction: column; /* 배치 방향 설정 */
  justify-content: center; /* 메인축 방향 정렬 */
  position: relative;
  gap: 2em;

  width: 100%;
  max-width: var(--wf-container-width);

  margin: 1em 0;
  margin-bottom: 3em;

  padding: 1em;
}

.wf__footer-cooperator {
  display: flex;
  justify-content: center;
  gap: 1em;
  background-color: var(--wf-misty-color);
  padding: 1em 0;
}
:deep(.swiper-slide) {
  margin-right: 30px;
}

.wf__footer-menu ul {
  display: flex;
  flex-wrap: wrap;
}

.wf__footer-menu-item.active > a {
  color: var(--wf-primary-color);
}
.wf__footer-menu-item {
  display: flex;
  width: max-content;

  color: black;
  text-decoration: none;
}
.wf__footer-menu-item::after {
  float: right;
  display: inline-table;
  color: var(--wf-black-color);
  content: '|';
  align-items: center;
  margin: 0 5px;
}

.wf__footer-divider {
  min-width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 1px;
  background-color: var(--wf-gray-color);
}

.wf__footer-info {
  display: flex;
  align-items: center;
  max-width: var(--wf-container-width);
  gap: 10em;
}
.wf__footer-logo img {
  width: 200px;
}

.wf__footer-cooperator-logo {
  min-width: 10px;
  object-fit: cover;
  object-position: center;
}

.wf__footer-info-item {
  font-size: 0.75em;
}
.wf__footer-info-item::after {
  content: '|';
  margin: 0 0.5em;
}

.wf__footer-inquiry {
  flex: 1;

  display: flex;
  justify-content: flex-end;
}
.wf__footer-inquiry-title {
  font-size: 1.5em;
  font-weight: bold;
}
.wf__footer-inquiry-content {
  font-size: 0.75em;
}

/* ********** 반응형 ********** */
/* 데스크탑 (가로해상도가 1200px 보다 작은 화면) */
@media screen and (max-width: 1200px) {
}

/* 모바일 */
@media screen and (max-width: 992px) {
  .wf__footer-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
  }
}
</style>
