<script setup>
import LanguageConverter from '@/util/LanguageConverter'

import { getPopup } from '@/api/admin/settings'
import { createObjectURL } from '@/api/fileAPI'
import { ref, onMounted } from 'vue'
import Util from '@/util/common'

const cookieKey = 'hd_popup'
const removePopup = (key) => {
  const date = new Date()

  Util.setCookie({
    key: `${cookieKey}_${key}`,
    value: key,
    expires: date.setDate(date.getDate() + 1)
  })
}

const replaceImgFromDataList = async (list) => {
  const arr = []
  for (const data of list) {
    const imgUrl = await createObjectURL(data.imgUrl?.split('/').at(-1), 'popup', data.popupId)
    arr.push({ ...data, imgUrl })
  }
  return arr
}

const isRemovedPopup = (key) => {
  const cookie = Util.getCookie(`${cookieKey}_${key}`)
  return cookie
}

const checkDate = (item) => {
  const endDate = new Date(item.endDate)
  const currentDate = new Date()
  const startDate = new Date(item.startDate)

  return currentDate >= startDate && currentDate <= endDate
}

const loadData = async () => {
  const data = await getPopup()

  if (!(data instanceof Array)) return
  const filtered = data.filter((item) => checkDate(item) && !isRemovedPopup(item.popupId))

  return await replaceImgFromDataList(filtered)
}

const popupList = ref(null)

const closeEvent = (e) => {
  e.target.closest('figure').remove()
}

const closeDayEvent = (e) => {
  const figure = e.target.closest('figure')
  removePopup(figure.dataset.id)
  closeEvent(e)
}

onMounted(async () => {
  popupList.value = await loadData()
})
</script>

<template>
  <section class="wf__popup-wrapper">
    <figure class="wf__popup-item" v-for="(popup, i) in popupList" :key="i" :data-id="popup.popupId">
      <img class="wf__popup-item-img" :src="popup.imgUrl" alt="" @click="$router.push(popup.link)" />

      <!-- TODO: Cookie를 활용하여 24시간 동안 보지 않기 기능 구현 -->
      <figcaption class="wf__popup-item-footer">
        <label class="cursor-pointer" @click="closeDayEvent">
          {{ LanguageConverter({ en: 'Do not show for 24 hours', kr: '24시간 동안 보지 않기' }) }}
        </label>
        <span class="material-icons-outlined cursor-pointer" @click="closeEvent">close</span>
      </figcaption>
    </figure>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__popup-wrapper {
  display: flex;
  flex-wrap: wrap;

  position: absolute;
  top: var(--wf-header-height);
  z-index: 99;
}
.wf__popup-item {
  display: flex;
  flex-direction: column;

  background-color: var(--wf-secondary-color);
  border: 1px solid var(--wf-misty-color);

  font-size: var(--wf-text-14);

  cursor: pointer;

  /* 2023-12-27 팝업레이어 버그 수정 */
  max-width: 450px;
}
.wf__popup-item-img {
  /* 2023-12-27 팝업레이어 버그 수정 */
  width: min(450px, 100%);
  object-fit: cover;
  object-position: center;
}
.wf__popup-item-footer {
  flex: 1;
  color: var(--wf-misty-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* 2023-12-27 팝업레이어 버그 수정 */
  padding: 0.3em 0.5em;
}

@media screen and (max-width: 1024px) {
  .wf__popup-item {
    /* 2023-12-27 팝업레이어 버그 수정 */
    width: 280px;
  }
}
@media screen and (max-width: 768px) {
  .wf__popup-item {
    /* 2023-12-27 팝업레이어 버그 수정 */
    width: 240px;
  }
}
</style>
