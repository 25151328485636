import { useAxios } from '../axiosUtil'
import queryStringBuilder from '@/util/queryStringBuilder'

const baseURL = '/v1/setting' // 지재권 저장 url

const headerOpt = (method) => ({
  method
})

const bannerModel = {
  title: '',
  link: '',
  imgUrl: '',
  startDate: '',
  endDate: ''
}
Object.freeze(bannerModel)
const popupModel = {
  title: '',
  link: '',
  imgUrl: '',
  startDate: '',
  endDate: ''
}
Object.freeze(popupModel)

const setBodyDataByModel = (model, data) => {
  const obj = {}
  for (const key in model) {
    const value = data[key]
    if (!value && value !== 0) continue

    obj[key] = value
  }
  return obj
}

const api = async (endpoint, method, model, data, id) => {
  const opt = headerOpt(method)
  let url = `${baseURL}/${endpoint}`

  // select
  if (method === 'GET') {
    // select one
    if (['string', 'number'].includes(typeof data)) url += `/${data}`

    // select with search option
    if (data) {
      url += queryStringBuilder(data)
    }
  }

  // insert
  if (method === 'POST') {
    opt.data = setBodyDataByModel(model, data)
  }

  // delete
  if (method === 'DELETE') {
    url += `/${data}`
  }

  // update
  if (method === 'PUT') {
    opt.data = setBodyDataByModel(model, data)
    url += `/${id}`
  }

  const result = await useAxios(url, opt)

  if (result.status) return []
  return result
}

const bannerApi = async (method, data, id) => {
  return await api('banner', method, bannerModel, data, id)
}
const popupApi = async (method, data, id) => {
  return await api('popup', method, popupModel, data, id)
}

export const getBanner = (opt = null) => {
  return bannerApi('GET', opt)
}
export const insertBanner = (data) => {
  return bannerApi('POST', data)
}
export const updateBanner = (data, id) => {
  return bannerApi('PUT', data, id)
}
export const deleteBanner = (id) => {
  return bannerApi('DELETE', id)
}

export const getPopup = (opt = null) => {
  return popupApi('GET', opt)
}
export const insertPopup = (data) => {
  return popupApi('POST', data)
}
export const updatePopup = (data, id) => {
  return popupApi('PUT', data, id)
}
export const deletePopup = (id) => {
  return popupApi('DELETE', id)
}
