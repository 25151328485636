// module store
// store.state.exampleStore로 접근

export default {
  namespaced: true,
  state: {
    menuList: []
  },
  getters: {
    menuList: (state) => state.menuList.sort((a, b) => a.id - b.id)
  },
  mutations: {
    SET_MENU_LIST(state, menuList) {
      state.menuList = menuList
    }
  },
  actions: {
    LoadMenuList({ commit, state }, payload) {
      // menuList 조회 api 호출
      const menuList = () => [
        { id: 1, path: '/community', name: '커뮤니티' },
        { id: 2, path: '/community/notice', name: '공지사항' },
        { id: 3, path: '/community/qna', name: 'Q&A' }
      ]
      // commit( 실행할 mutation key, 해당 store에 정의 된 state )
      commit('SET_MENU_LIST', menuList())
    }
  }
}
