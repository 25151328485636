/* eslint-disable no-useless-escape */
export const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
export const regSearch = /^[0-9a-zA-Z가-힣]*$/
export const regRemoval = /[^\w\sㄱ-힣]|[\_]/g

export const regOnlyNumberEngKor = /^[0-9a-zA-Zㄱ-힣]*$/
export const regOnlyEngKor = /^[a-zA-Zㄱ-힣]*$/
export const regOnlyNumberEng = /^[0-9a-zA-Z]*$/
export const regLoginId = /^[A-Za-z0-9]{4,10}$/
export const regPassword = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!@%^&*]).{8,16}$/

export const Reg = {
  SPECIAL_CHAR: /[!@%^&*]/g,
  SPECIAL_CHAR_FOR_CONTENT: /[!?=>~/:@%^&*;'"()\.\,\\]/g,
  NUMBER: /[0-9]/g,
  ENG: /[a-z]/gi,
  KOR: /[ㄱ-힣]/gi,
  EMAIL: regEmail
}
