//INFO: 화면설명
<script>
import { createObjectURL } from '@/api/fileAPI'
import LanguageConverter from '@/util/LanguageConverter'

export default {
  name: '',
  components: {},
  props: ['labList'],
  data() {
    const randNum = Math.floor(Math.random() * this.labList.length)
    const lab = this.labList[randNum]
    return {
      imgSrc: 'error',
      lab
    }
  },
  setup() {},
  created() {},
  async mounted() {
    const src = this.lab.leadResearcherImgUrl

    const splitedSrc = src?.split('/')

    const imageName = decodeURIComponent(splitedSrc.at(-1))
    const childFolder = decodeURIComponent(splitedSrc.at(-3))
    const parentFolder = decodeURIComponent(splitedSrc.at(-4))

    const imgSrc = await createObjectURL(imageName, parentFolder, childFolder)
    this.imgSrc = imgSrc
  },
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <div class="wf__content">
    <div class="wf__intro">
      <div class="wf__intro-title">
        {{ LanguageConverter({ en: 'Labs And Companies', kr: '연구실과 기업' }) }}
        <div class="wf__intro-title--big">{{ LanguageConverter({ en: 'Find Each Other', kr: '서로를 발견하다' }) }}</div>
      </div>
    </div>

    <div class="wf__wrapper">
      <img :src="imgSrc" alt="" />

      <div class="wf__lab-desc">
        <div class="wf__researcher">{{ LanguageConverter({ en: lab.leadResearcherNameEn, kr: lab.leadResearcherName }) }}</div>
        <div class="wf__lab-name">{{ LanguageConverter({ en: lab.labNameEn, kr: lab.labName }) }}</div>
        <div class="wf__lab-intro">{{ LanguageConverter({ en: lab.introductionEn, kr: lab.introduction }) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__content {
  padding: var(--wf-text-30) 0;
  font-size: var(--wf-text-10);

  border: 2px solid #fff;
  border-left: none;
  border-right: none;
}
.wf__intro {
  font-size: var(--wf-text-20);
  border-bottom: 2px solid #fff;
}
.wf__intro-title--big {
  font-size: var(--wf-text-36);
  font-weight: 700;
  color: #28e5d5;
}
.wf__wrapper {
  margin-top: 30px;
  display: flex;
  font-size: var(--wf-text-14);
  gap: 1em;
}
.wf__researcher {
  font-weight: 700;
}
.wf__lab-name {
  font-size: var(--wf-text-30);
  font-weight: 700;
  line-height: 1.3;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}

.wf__lab-intro {
  margin-top: 1em;
}
.wf__lab-desc {
  max-width: 50vw;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}
.wf__wrapper img {
  width: 120px;
  object-fit: scale-down;
  object-position: center;
}
</style>
