/* eslint-disable generator-star-spacing */
/* eslint-disable no-unused-vars */
export const execGenerator = (generator) => {
  let result
  for (const g of generator) {
    result = g
  }
  return result
}

export const pipe = (...fns) => {
  return (value) => {
    return fns.reduce((acc, cur) => (acc instanceof Promise ? acc.then(cur) : cur(acc)), value)
  }
}

export const everyPipe = (...fns) => {
  let result = true

  function* g(value) {
    for (const fn of fns) {
      result = result && fn(value)
      yield result
      if (result === false) return
    }
  }

  return (value) => {
    result = true
    return execGenerator(g(value))
  }
}

export const anyPipe = (...fns) => {
  let result = false

  function* g(value) {
    for (const fn of fns) {
      result = result || fn(value)
      yield result
      if (result === true) return
    }
  }

  return (value) => {
    result = false
    return execGenerator(g(value))
  }
}

export const identify = (msg) => {
  return (val) => {
    console.log(`${msg}`, val)
    return val
  }
}

export const curry = (fn) => {
  if (typeof fn !== 'function') throw new Error('curry함수는 function만 인자로 받을 수 있습니다')

  return function curried(...args) {
    if (args.length < fn.length) {
      return (...inArgs) => {
        return curried(...[...args, ...inArgs])
      }
    }
    return fn.apply(null, args)
  }
}

// 순회하는 배열의 모든 요소가 주어진 조건에 참인지 확인하는 함수
export const every = (arr, fn) => {
  let result = true
  for (const item of arr) {
    result = result && fn(item)
  }
  return result
}

// 순회하는 배열의 적어도 하나의 요소가 주어진 조건에 참인지 확인하는 함수
export const some = (arr, fn) => {
  let result = false
  for (const item of arr) {
    result = result || fn(item)
  }
  return result
}

export const memorize = (fn) => {
  const memorized = new Map()
  return function (arg) {
    return memorized.get(arg) || memorized.set(arg, fn(arg)).get(arg)
  }
}
