// module store
// store.state.userStore로 접근
import { getLoginUserInfo } from '@/api/auth'

export default {
  namespaced: true,
  state: {
    info: null,
    refreshToken: '',
    isBot: true
  },
  getters: {
    info: (state) => state.info,
    refreshToken: (state) => state.refreshToken,
    isBot: (state) => state.isBot
  },
  mutations: {
    SET_INFO(state, info) {
      state.info = info
    },
    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token
    },
    SET_IS_BOT(state, isBot) {
      state.isBot = isBot
    }
  },
  actions: {
    async SetUserInfo({ commit, state }) {
      const info = await getLoginUserInfo()

      if (!info) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenType')
        return commit('SET_INFO', null)
      }

      if (info?.code) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenType')
        alert(`Error: ${info.message}`)
        return commit('SET_INFO', null)
      }

      if (info.status !== 1) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenType')
        return commit('SET_INFO', null)
      }

      commit('SET_INFO', info)
    },

    LogoutUser({ commit, state }) {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenType')
      commit('SET_INFO', null)
    },

    SetRefreshToken({ commit, state }, token) {
      commit('SET_REFRESH_TOKEN', token)
    },

    SetIsBot({ commit, state }, isBot) {
      commit('SET_IS_BOT', isBot)
    }
  }
}
