export default [
  {
    path: '/researchField', // 연구분야 검색 화면
    name: 'ResearchField',
    component: () => import('../views/researchField/ResearchField.vue'),
    children: [
      {
        path: '',
        name: 'ResearchFieldRedirect',
        redirect: '/researchField/lab'
      },
      {
        // /researchField/researchFieldLab/lab 일 때 researchFieldLab은 researchField의 <router-view> 에 렌더링 된다.
        path: 'lab',
        component: () => import('../components/researchField/Labs.vue')
      },
      {
        // /researchField/researchFieldMajor/major 일 때 researchFieldMajor은 researchField의 <router-view> 에 렌더링 된다.
        path: 'major',
        component: () => import('../components/researchField/Majors.vue')
      },
      {
        // /researchField/researchFieldMajor/major 일 때 researchFieldMajor은 researchField의 <router-view> 에 렌더링 된다.
        path: 'field',
        component: () => import('../components/researchField/Fields.vue')
      }
    ]
  },
  {
    path: '/researchField/result', // 연구분야 검색 화면
    name: 'ResearchFieldResult',
    component: () => import('../views/researchField/ResearchFieldResult.vue')
  },
  // 통합검색
  {
    path: '/integratedSearch',
    name: 'integratedSearch',
    component: () => import('../views/integratedSearch/IntegratedSearch.vue'),
    children: [
      {
        path: '',
        name: 'integratedSearchMain',
        component: () => import('../components/integratedSearch/SearchView.vue')
      },
      {
        path: 'integratedSearchResult',
        name: 'integratedSearchResult',
        component: () => import('../components/integratedSearch/ResultView.vue')
      }
    ]
  }
]
