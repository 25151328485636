//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  name: '',
  components: {},
  props: ['numbers'],
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <div class="wf__content">
    <div class="wf__intro">
      <div class="wf__intro-title">
        {{ LanguageConverter({ en: 'New Knowledge Stretching', kr: '유망에서부터 뻗어가는' }) }}
        <div class="wf__intro-title--big">{{ LanguageConverter({ en: 'From PromisingLabs', kr: '새로운 지식' }) }}</div>
        <br />
        {{ LanguageConverter({ en: 'Meet Wide and Various', kr: '폭넓게, 다양하게 얻을 수 있는' }) }} <br />
        {{ LanguageConverter({ en: 'PromisingLabs Knowledges', kr: '유망연구실의 지식자료' }) }}
      </div>
    </div>

    <div class="wf__number-wrapper">
      <div class="wf__number-text">
        <div class="wf__rotate">{{ LanguageConverter({ en: 'Project', kr: '프로젝트' }) }}</div>

        <span class="wf__number">{{ numbers[0] }}</span>
        {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
      </div>

      <div class="wf__number-text">
        <div class="wf__rotate">{{ LanguageConverter({ en: 'Paper', kr: '논문' }) }}</div>

        <span class="wf__number">{{ numbers[1] }}</span>
        {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
      </div>

      <div class="wf__number-text">
        <div class="wf__rotate">{{ LanguageConverter({ en: 'Patent', kr: '지재권' }) }}</div>

        <span class="wf__number">{{ numbers[2] }}</span>
        {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__content {
  padding: var(--wf-text-30) 0;
  font-size: var(--wf-text-10);

  border: 2px solid #fff;
  border-left: none;
  border-right: none;
}
.wf__intro {
  font-size: var(--wf-text-20);
}
.wf__intro-title--big {
  font-size: var(--wf-text-36);
  font-weight: 700;
}

.wf__rotate {
  rotate: -90deg;
  translate: -9em -1em;
}

.wf__number-wrapper {
  translate: 0 -4em;
  display: flex;
  flex-direction: column;

  align-items: flex-end;
}
.wf__number {
  font-size: 7rem;
  font-weight: 700;
  color: #28e5d5;

  line-height: 1.2;
}
</style>
