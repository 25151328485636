import { signIn } from '@/api/auth'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import adminUrl from '@/router/admin'

import memberUrl from '@/router/member'
import basicUrl from '@/router/basic'
import boardUrl from '@/router/board'
import communityUrl from '@/router/community'
import searchUrl from '@/router/search'

const routes = [
  ...basicUrl, //
  ...boardUrl,
  ...adminUrl,
  ...communityUrl,
  ...memberUrl,
  ...searchUrl
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router 이동 할때 마다 실행해야하는 코드 작성
router.beforeEach(async (to, from, next) => {
  const header = document.querySelector('header.wf__header')
  if (header) header.classList.remove('black')

  store._actions.SetLangMode[0]()
  // 로그인한 사용자 정보 저장
  await store._actions['userStore/SetUserInfo'][0]()
  const user = store.getters['userStore/info']
  const isBot = store.getters['userStore/isBot']

  // console.log(user, isBot)

  if (user && user.groupName !== 'bot') {
    store._actions['userStore/SetIsBot'][0](false)
  }

  document.documentElement.scrollTop = 0

  // 로그인 정보가 없는 경우 bot계정 로그인 실행
  if (!user && !isBot && to.path !== '/' && to.path !== '/member/login') {
    alert('다시 로그인해주십시오.')
    return next('/member/login')
  }

  if (!user) {
    // bot계정으로 로그인 되어있지 않았을 경우
    const { token, tokenType } = await signIn('bot', '20221021')
    localStorage.setItem('token', token)
    localStorage.setItem('tokenType', tokenType)
    store._actions['userStore/SetIsBot'][0](true)
    return next()
  }

  // admin 페이지로 이동 시
  if (to.matched.some((item) => item.path.includes('admin'))) {
    // 접근 권한이 없는경우
    if (!['admin', 'lab'].includes(user?.groupName)) {
      alert('접근 권한이 없습니다.')
      return next('/')
    }
  }

  return next()
})

export default router
