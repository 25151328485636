<script>
import LanguageConverter from '@/util/LanguageConverter'
import HomeSlideDefaultPjt from '@/assets/images/HomeSlideDefaultPjt.jpg'
import HomeSlideDefaultPaper from '@/assets/images/HomeSlideDefaultPaper.jpg'
import HomeSlideDefaultPatent from '@/assets/images/HomeSlideDefaultPatent.jpg'
import HomeSlideDefaultHandover from '@/assets/images/HomeSlideDefaultHandover.jpg'

export default {
  components: {},
  props: ['communityDataList'],
  data() {
    const images = {
      pjt: HomeSlideDefaultPjt,
      paper: HomeSlideDefaultPaper,
      patent: HomeSlideDefaultPatent,
      handover: HomeSlideDefaultHandover
    }
    return {
      targetBoard: 'pjt',
      viewCommunityData: [],
      images,
      backgroundImg: images.pjt
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.viewCommunityData = this.communityDataList.pjt
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    navClickHandler(e) {
      const target = e.target.matches('.wf__community-navs-item') ? e.target : e.target.closest('.wf__community-navs-item')

      target.parentElement.querySelector('.active').classList.remove('active')
      target.classList.add('active')

      this.targetBoard = target.dataset.board
      this.backgroundImg = this.images[this.targetBoard]
      this.viewCommunityData = this.communityDataList[this.targetBoard].slice(0, 2)
    },
    changeBackground(e) {
      const target = e.target.matches('.wf__community-news-item') ? e.target : e.target.closest('.wf__community-news-item')
      const child = target.querySelector('.wf__community-news-after')
      child.style.backgroundImage = `url(${this.backgroundImg})`
    },
    goBoard(endpoint, id) {
      const url = `/community/${endpoint}`
      this.$router.push(id ? `${url}/${id}` : url)
    }
  }
}
</script>

<template>
  <div class="wf__community">
    <div class="wf__community-navs-wrapper">
      <div class="wf__community-navs-flex">
        <ul class="wf__community-navs">
          <li class="wf__community-navs-item active" data-board="pjt" @click="navClickHandler">
            <div class="wf__community-navs-title">project</div>
            {{ LanguageConverter({ en: 'Project', kr: '프로젝트' }) }}
          </li>
          <li class="wf__community-navs-item" data-board="paper" @click="navClickHandler">
            <div class="wf__community-navs-title">paper</div>
            {{ LanguageConverter({ en: 'Paper', kr: '논문' }) }}
          </li>
          <li class="wf__community-navs-item" data-board="patent" @click="navClickHandler">
            <div class="wf__community-navs-title">patent</div>
            {{ LanguageConverter({ en: 'Patent', kr: '지재권' }) }}
          </li>
          <li class="wf__community-navs-item" data-board="handover" @click="navClickHandler">
            <div class="wf__community-navs-title">Tech Cooperation</div>
            {{ LanguageConverter({ en: 'Tech Cooperation', kr: '기업 협력 성과' }) }}
          </li>
        </ul>

        <span class="wf__community-more">
          <span @click="goBoard(targetBoard)">{{ LanguageConverter({ en: 'MORE', kr: '더보기' }) }}</span>
        </span>
      </div>
    </div>

    <div class="wf__community-news-wrapper">
      <div
        class="wf__community-news-item"
        v-for="(item, i) in viewCommunityData"
        :key="i"
        @click="goBoard(targetBoard, item.id)"
        @mouseover="changeBackground"
      >
        <span class="wf__community-news-badge">{{ item.type }}</span>
        <span class="wf__community-news-title">{{ item.title }}</span>
        <ul class="wf__community-news-desc">
          <li v-for="(text, j) in item.textContents" :key="j">{{ text }}</li>
        </ul>
        <span class="wf__community-news-after"></span>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__community {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  max-width: calc(100vw - 20px);
}

.wf__community-navs-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wf__community-navs {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4em;
}
.wf__community-navs-item {
  color: var(--wf-gray-400);
  cursor: pointer;
  font-size: var(--wf-text-30);
  font-weight: 700;
  line-height: 1.3;
}
.wf__community-navs-title {
  opacity: 0;
  text-transform: uppercase;
  font-size: var(--wf-text-14);
  font-weight: 400;
}
.wf__community-navs-item.active {
  color: var(--wf-black-color);
  border-bottom: 3px solid var(--wf-primary-color);
}

.wf__community-navs-item.active .wf__community-navs-title {
  opacity: 1;
  color: var(--wf-primary-color);
}

.wf__community-more {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.wf__community-more span {
  cursor: pointer;
  font-size: var(--wf-text-14);
  border-bottom: 2px solid var(--wf-black-color);
}
.wf__community-more span:hover {
  opacity: 0.75;
}

.wf__community-news-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--wf-gray-400);
  border-bottom: 1px solid var(--wf-gray-400);
}
.wf__community-news-item {
  position: relative;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid var(--wf-gray-400);

  transition: all 0.2s ease;
}
.wf__community-news-item:hover {
  background-color: var(--wf-primary-100);
}
.wf__community-news-after {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover;

  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.wf__community-news-item:hover .wf__community-news-after {
  opacity: 1;
}
.wf__community-news-item:last-child {
  border-bottom: none;
}
.wf__community-news-badge {
  font-size: var(--wf-text-14);
  color: var(--wf-primary-color);
  font-weight: 500;
}
.wf__community-news-title {
  font-size: var(--wf-text-20);
  font-weight: 600;

  width: 100%;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;

  transition: all 0.2s ease-in-out;
}

.wf__community-news-desc {
  display: flex;
  font-size: var(--wf-text-14);
  color: var(--wf-gray-color);
}
.wf__community-news-desc li:not(:last-child)::after {
  content: '/';
  padding: 0 0.5em;
}
.wf__community-news-item:hover .wf__community-news-title {
  width: 50%;
  -webkit-line-clamp: 2; /* ...처리가 일어날 줄 */
}

@media screen and (max-width: 576px) {
  .wf__community-navs-flex {
    justify-content: flex-end;
  }
}
</style>
