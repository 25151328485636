// module store

// {
//   boardName: '',
//   boardId: '',
//   fileName: '',
//   file: null
// }
export default {
  namespaced: true,
  state: {
    imgList: []
  },
  getters: {
    imgList: (state) => state.imgList
  },
  mutations: {
    SET_IMG_LIST(state, imgList) {
      state.imgList = imgList
    },
    PUSH_IMG_ITEM(state, imgItem) {
      state.imgList.push(imgItem)
    }
  },
  actions: {
    SetImgList({ commit, state }, imgList) {
      commit('SET_IMG_LIST', imgList)
    },
    PushImgItem({ commit, state }, imgItem) {
      const index = state.imgList.findIndex(
        (item) => item.boardId === imgItem.boardId && item.boardName === imgItem.boardName && item.fileName === imgItem.fileName
      )

      if (index === -1) return commit('PUSH_IMG_ITEM', imgItem)
      return (state.imgList[index] = imgItem)
    }
  }
}
