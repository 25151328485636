import { labNavigationGuard } from '../util/routerNavigationGuard'

export default [
  {
    path: '/admin/banners',
    name: 'adminBanners',
    component: () => import('../views/admin/banners/Banners.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    },
    children: [
      {
        path: '',
        name: 'adminBannersList',
        component: () => import('../views/admin/banners/BannersList.vue')
      },
      // 쓰기 페이지
      {
        path: 'w',
        name: 'adminBannersWrite',
        component: () => import('../views/admin/banners/BannersDetail.vue')
      },
      // 수정 페이지
      {
        path: 'w/:id',
        name: 'adminBannersModify',
        component: () => import('../views/admin/banners/BannersDetail.vue')
      }
    ]
  }
]
