import { useAxios } from './axiosUtil'
import QueryStringBuilder from '../util/queryStringBuilder'
import imageCompression from 'browser-image-compression'
import axios from 'axios'

import DefalutImg from '@/assets/images/profile.jpg'

import store from '../store'

/**
 * Nhn 토큰 조회
 * @returns {String} nhn 토큰
 */
const getNhnObjectStorageToken = async () => {
  const token = localStorage.getItem('nhn_token')
  const expires = localStorage.getItem('nhn_expires')

  if (!token) {
    const result = await useAxios('/v1/file/token')

    localStorage.setItem('nhn_token', result.token)
    localStorage.setItem('nhn_expires', result.expires)

    return result
  }

  if (token) {
    const now = new Date()
    const expireTime = new Date(new Date(expires).setHours(new Date(expires).getHours() - 9))

    if (now >= expireTime) {
      const result = await useAxios('/v1/file/token')

      localStorage.setItem('nhn_token', result.token)
      localStorage.setItem('nhn_expires', result.expires)

      return result
    }

    return { token }
  }
}

const baseUrl = '/v1/file'

const typeModel = {
  images: {
    list: 'image-list',
    endpoint: 'upload-image',
    blobKey: 'uploadImage'
  },
  files: {
    list: 'file-list',
    endpoint: 'upload-file',
    blobKey: 'uploadFile'
  }
}
Object.freeze(typeModel)

const boardDataModel = {
  boardName: '',
  boardId: 0
}
Object.freeze(boardDataModel)

const imageCompressOption = {
  maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
  initialQuality: 0.9 // optional, initial quality value between 0 and 1 (default: 1)
}
Object.freeze(imageCompressOption)

/**
 * file API
 */
export default class FileAPI {
  _type
  _formDataList
  _blobMap
  _downloadRequestLimit

  /**
   * 생성자
   * @param {String} type
   */
  constructor(type) {
    if (!typeModel[type]) {
      return console.error('FileAPI에 허용되지 않은 type이 할당되었습니다. (valid types = [images, files])')
    }

    this._type = type
    this._formDataList = []
    this._blobMap = {}
  }

  /**
   * 업로드할 파일 추가
   * @param {File} blob 업로드할 파일 객체
   */
  async add(blob) {
    // formData 객체 생성
    const formData = new FormData()

    // blob set
    const blobKey = typeModel[this._type].blobKey

    const file =
      this._type === 'images'
        ? new File([await imageCompression(blob, imageCompressOption)], encodeURIComponent(blob.name), { type: blob.type })
        : new File([blob], encodeURIComponent(blob.name), { type: blob.type })

    formData.append(blobKey, file)

    // formDataList에 formData 담기
    this._formDataList.push(formData)
  }

  /**
   * Nhn Object Storage에 파일 저장
   * @param {Object} boardData 게시판 정보
   *   @param {String} boardData.boardName
   *   @param {Number} boardData.boardId
   *
   * @returns {Array} 저장된 fileName 리스트
   */
  async send(boardData, opt) {
    const url = `${baseUrl}/${typeModel[this._type].endpoint}`
    const { token } = await getNhnObjectStorageToken()
    const resultArray = []

    for (const formData of this._formDataList) {
      // boardData set
      for (const key in boardDataModel) {
        formData.append(key, boardData[key])
      }
      // token set
      formData.append('token', token)

      // api call
      const result = await useAxios(url, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'POST',
        data: formData
      })

      // error handling
      if (result.code === 'CLOUD_ERROR' && result.message === 'Unauthorized') {
        localStorage.removeItem('nhn_token')
        localStorage.removeItem('nhn_expires')
        return alert('다시 시도해주세요.')
      }
      if (result.code) return alert(result.message)

      // fileName push
      const fileName = result.url.slice(result.url.lastIndexOf('/') + 1, result.url.length)
      resultArray.push(opt?.fullUrl ? result.url : fileName)
    }

    // formDataList 초기화
    this._formDataList = []
    return resultArray
  }

  /**
   * 파일 다운로드 URL 생성
   * @param {String} fileName 파일 이름
   *
   * @param {Object} boardData 게시판 정보
   *   @param {String} boardData.boardName
   *   @param {Number} boardData.boardId
   *
   * @returns {String} 파일 다운로드 및 미리보기가 가능한 URL
   */
  async createObjectURL(fileName, boardName, boardId) {
    const imgList = store.state.imageStore.imgList
    const index = imgList.findIndex((item) => item.boardId === boardId && item.boardName === boardName && item.fileName === fileName)

    if (index !== -1) {
      const { file, fileName } = imgList[index]

      this._blobMap[fileName] = new File([file], encodeURI(fileName), { type: file.type })
      return URL.createObjectURL(file)
    }

    // file download api url
    const url = `${process.env.VUE_APP_NHN_ENDPOINT}/${boardName}/${boardId}/images/${fileName}`

    // nhn token
    const { token } = await getNhnObjectStorageToken()

    // file download api call
    //  returns : blob
    const result = await axios(url, {
      headers: {
        'X-Auth-Token': token
      },
      responseType: 'blob'
    }).catch((err) => err)

    if (result.code === 'ERR_BAD_REQUEST') return ''

    const file = result.data
    this._blobMap[fileName] = new File([file], fileName, { type: file.type })

    // make objectURL
    return URL.createObjectURL(file)
  }

  /**
   * 파일 다운로드 실행
   * @param {String} fileName 파일 이름
   * @param {String} url objectURL
   */
  async downloadFile(fileName, url) {
    // a 태그 생성
    const link = document.createElement('a')

    // file object url 설정
    link.href = url

    // 다운로드 파일 이름 설정
    link.setAttribute('download', fileName)

    // a 태그 추가
    document.body.appendChild(link)
    // 다운로드 실행
    link.click()

    // a 태그 및 object url 삭제
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  /**
   * 파일 다운로드
   * @param {String} fileName 파일이름
   *
   * @param {Object} boardData 게시판 정보
   *   @param {String} boardData.boardName
   *   @param {Number} boardData.boardId
   *
   * @returns
   */
  async download(fileName, { boardName, boardId }) {
    // file object url 생성
    const objectURL = await this.createObjectURL(fileName, { boardName, boardId })

    // class type이 files일 때 file download 실행
    if (this._type === 'files') return await this.downloadFile(fileName, objectURL)
  }

  async getURLs({ boardName, boardId }) {
    // console.log(boardName)
    // console.log(boardId)
    // get list API URL
    const url = `${baseUrl}/${typeModel[this._type].list}`

    // nhn token
    const { token } = await getNhnObjectStorageToken()

    const queryString = QueryStringBuilder({
      token,
      boardName,
      boardId
    })

    const result = await useAxios(`${url}/${queryString}`)
    // console.log(result)

    if (result?.code) return alert(result.message)

    return result.map((item) => {
      return item.slice(item.lastIndexOf('/') + 1, item.length)
    })
  }

  async delete(fileName, { boardName, boardId }) {
    // delete API URL
    const url = `${baseUrl}/${boardName}/${boardId}/${this._type}/${fileName}`

    // nhn token
    const { token } = await getNhnObjectStorageToken()

    // file download api call
    //  returns : blob
    const result = await useAxios(`${url}/?token=${token}`, {
      method: 'DELETE'
    })

    if (result.code === 'CLOUD_ERROR' && result.message === 'Unauthorized') {
      localStorage.removeItem('nhn_token')
      localStorage.removeItem('nhn_expires')
      return alert('다시 시도해주세요.')
    }

    if (result?.code) return alert(result.message)
    return result
  }

  async deleteAll({ boardName, boardId }) {
    // console.log(boardName)
    // console.log(boardId)
    const list = await this.getURLs({ boardName, boardId })
    // console.log(list)

    for (const imgName of list) {
      if (!imgName) continue
      const result = await this.delete(imgName, { boardName, boardId })
      // console.log(result)
      if (result?.code) return alert(result.message)
    }
  }
}

const url = (fileName, boardName, boardId) => `${process.env.VUE_APP_NHN_ENDPOINT}/${boardName}/${boardId}/images/${fileName}`
export const createObjectURL = async (fileName, boardName, boardId) => {
  const imgList = store.state.imageStore.imgList
  const index = imgList.findIndex((item) => item.boardId === boardId && item.boardName === boardName && item.fileName === fileName)
  if (index !== -1) return URL.createObjectURL(imgList[index].file)

  const { token } = await getNhnObjectStorageToken()

  return axios(url(fileName, boardName, boardId), {
    headers: {
      'X-Auth-Token': token
    },
    responseType: 'blob'
  })
    .then((data) => {
      store._actions['imageStore/PushImgItem'][0]({
        boardName,
        boardId,
        fileName,
        file: data.data
      })

      return data
    })
    .then((data) => URL.createObjectURL(data.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('nhn_token')
        localStorage.removeItem('nhn_expires')
      }
      return DefalutImg
    })
}

// TODO: file upload 새 로직
export const uploadObject = async (formData, { fileName, boardName, boardId }) => {
  const { token } = await getNhnObjectStorageToken()

  const opt = {
    method: 'PUT',
    headers: {
      'X-Auth-Token': token
    },
    data: formData
  }

  const requestUrl = url(fileName, boardName, boardId)

  const response = await axios(requestUrl, opt)
  console.log(response)
}
