const boardUrl = [
  {
    path: '/write',
    name: 'Write',
    component: () => import('../views/Write.vue')
  }
  // {
  //   path: '/boardList',
  //   name: 'BoardList',
  //   component: () => import('../views/tempBoard/BoardList.vue')
  // },
  // {
  //   path: '/boardView/:boardNo',
  //   name: 'BoardView',
  //   component: () => import('../views/tempBoard/BoardView.vue')
  // },
  // {
  //   path: '/boardWrite/:boardNo?',
  //   name: 'BoardWrite',
  //   component: () => import('../views/tempBoard/BoardWrite.vue')
  // }
]

export default boardUrl
