<script>
export default {
  components: {},
  props: ['swiper', 'slideLength', 'reverseColor', 'log'],
  data() {
    return {
      isPaused: false,
      curIdx: 0,
      navItems: []
    }
  },
  setup() {},
  created() {},
  watch: {
    curIdx() {
      this.toggleActiveClass()
    }
  },
  mounted() {
    if (this.swiper) this.swiper.on('activeIndexChange', this.activeIndexChangeHandler)
  },
  unmounted() {},
  methods: {
    setNavItemRefs(el) {
      if (this.slideLength === this.navItems.length) return
      this.navItems.push(el)
    },
    toggleActiveClass() {
      for (const item of this.navItems) {
        item.classList.remove('active')
      }
      if (this.navItems[this.curIdx]) this.navItems[this.curIdx].classList.add('active')
    },
    moveSlide(idx) {
      this.curIdx = idx

      this.toggleActiveClass()
      this.swiper.slideToLoop(this.curIdx)
    },
    goPrev() {
      if (this.curIdx === 0) this.moveSlide(this.slideLength - 1)
      else this.moveSlide(this.curIdx - 1)
    },
    goNext() {
      if (this.curIdx === this.slideLength - 1) this.moveSlide(0)
      else this.moveSlide(this.curIdx + 1)
    },
    activeIndexChangeHandler(e) {
      this.curIdx = e.realIndex
    },
    toggleAutoSlide() {
      this.isPaused = !this.isPaused
      if (this.isPaused) return this.swiper.autoplay.stop()
      return this.swiper.autoplay.start()
    }
  }
}
</script>

<template>
  <div class="wf__home-content-news-navi-wrapper-item" :class="{ reversed: reverseColor }">
    <div class="wf__home-content-news-navi">
      <span
        v-for="item in slideLength"
        :ref="setNavItemRefs"
        :key="item"
        class="wf__home-content-news-navi-item"
        :class="{ active: item - 1 === 0 }"
        @click="moveSlide(item - 1)"
      >
      </span>
    </div>

    <div class="wf__pager">
      <span class="material-icons-outlined" @click="goPrev"> arrow_back_ios </span>
      <span v-show="!isPaused" class="material-icons-outlined" @click="toggleAutoSlide"> pause </span>
      <span v-show="isPaused" class="material-icons" @click="toggleAutoSlide"> play_arrow </span>
      <span class="material-icons-outlined" @click="goNext"> arrow_forward_ios </span>
    </div>
  </div>
</template>

<style scoped>
.wf__home-content-news-navi-wrapper-item {
  display: flex;
  gap: 9px;
}
.wf__home-content-news-navi {
  display: flex;
  gap: 7px;
  align-items: flex-end;
}
.wf__home-content-news-navi-item {
  display: inline-block;
  width: 9px;
  height: 9px;
  border: 1px solid var(--wf-black-color);
  border-radius: 50%;
  cursor: pointer;
}
.wf__home-content-news-navi-item.active {
  border-color: var(--wf-primary-color);
  background-color: var(--wf-primary-color);
}
.wf__pager {
  display: flex;
  align-items: center;
  background-color: var(--wf-primary-color);
  color: #fff;
  border-radius: 9999px;
  column-gap: 5px;

  padding: 1px 10px;
}
.wf__pager span {
  cursor: pointer;
  font-size: 1em !important;
}
.wf__pager span:nth-child(2) {
  font-size: 1.2em !important;
}

.wf__home-content-news-navi-wrapper-item.reversed .wf__home-content-news-navi-item {
  border-color: #fff;
}
.wf__home-content-news-navi-wrapper-item.reversed .wf__home-content-news-navi-item.active {
  background-color: #fff;
}
</style>
