<script>
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Autoplay } from 'swiper'

import InfoCardVue from '@/components/ui/InfoCard.vue'
import NewsSliderPagerVue from '@/components/Home/Content2/NewsSliderPager.vue'

import LanguageConverter from '@/util/LanguageConverter'
export default {
  components: { Swiper, SwiperSlide, InfoCardVue, NewsSliderPagerVue },
  props: ['remarkableLabDataList'],
  data() {
    return {
      viewDataList: [],
      dataListLength: 0,
      viewDataListLength: 0,
      swiper: null,
      modules: [Autoplay]
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.dataListLength = this.remarkableLabDataList.length
    this.setViewDataList()

    this.swiper = this.$refs.swiper.$el.swiper
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    cardClickHandler(data) {
      this.$router.push(data.link)
    },
    setViewDataList() {
      const arr = []

      for (let i = 0; i < this.dataListLength; i = i + 2) {
        arr.push([this.remarkableLabDataList[i], this.remarkableLabDataList[i + 1] ?? []])
      }

      this.viewDataList = arr
      this.viewDataListLength = arr.length
    },
    goRemarkableLabs() {
      this.$router.push('/remarkable')
    }
  }
}
</script>

<template>
  <div class="wf__remarkable">
    <div>
      <div class="wf__remarkable-title-wrapper">
        <div>
          <div class="wf__remarkable-subtitle">Laboratory</div>
          <div class="wf__remarkable-title">{{ LanguageConverter({ en: 'Remarkable Labs', kr: '이달의 연구실' }) }}</div>
        </div>

        <span class="wf__remarkable-more-link material-icons-outlined" @click="goRemarkableLabs"> expand_circle_down </span>
      </div>

      <div class="wf__remarkable-item-wrapper">
        <swiper
          :speed="1500"
          :slides-per-view="1"
          :spaceBetween="28"
          :loop="true"
          :autoplay="{
            delay: 1000,
            disableOnInteraction: false
          }"
          :modules="modules"
          ref="swiper"
        >
          <swiper-slide class="cursor-pointer" v-for="(slide, i) in viewDataList" :key="i">
            <div v-if="slide">
              <InfoCardVue
                class="wf__remarkable-item"
                v-for="(lab, j) in slide"
                :key="j"
                :src="lab.src"
                :data="lab.data"
                maxWidth="125px"
                height="130px"
                @onClick="cardClickHandler(lab.data)"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="wf__remarkable-pager-wrapper">
      <NewsSliderPagerVue v-if="swiper" :slideLength="viewDataListLength" :swiper="swiper"></NewsSliderPagerVue>
    </div>
  </div>
</template>

<style scoped>
.wf__remarkable {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.wf__remarkable-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--wf-black-color);

  padding-bottom: 12px;
}
.wf__remarkable-subtitle {
  font-size: var(--wf-text-14);
  font-weight: 300;
  color: var(--wf-primary-color);
}
.wf__remarkable-title {
  font-size: var(--wf-text-24);
  font-weight: 600;
  line-height: 1.3;
}
.wf__remarkable-more-link {
  cursor: pointer;
  font-size: var(--wf-text-18) !important;
  transform: rotate(270deg);
}
.wf__remarkable-item-wrapper {
  max-width: 370px;
}
.wf__remarkable-carousel-track {
  height: 100%;
  max-width: 100%;

  overflow: hidden;
}
.wf__remarkable-item {
  width: 370px;
  margin-left: 0;
  margin-right: 0;
}
.wf__remarkable-pager-wrapper {
  display: flex;
  justify-content: flex-end;
}

:deep(li .info-content-span) {
  -webkit-line-clamp: 1; /* ...처리가 일어날 줄 */
}
</style>
