//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  name: '',
  components: {},
  props: ['sixTechLargeList'],
  data() {
    return {}
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: { LanguageConverter }
}
</script>

<template>
  <div class="wf__content">
    <div class="wf__intro">
      <div class="wf__intro-title">
        {{ LanguageConverter({ en: 'Find Techs We Want', kr: '우리가 원하는 기술' }) }}
        <div class="wf__intro-title--big">{{ LanguageConverter({ en: 'In PromisingLabs', kr: '유망연구실에서' }) }}</div>

        {{ LanguageConverter({ en: 'Meet Wide and Various', kr: '폭넓은 분야의 정보를 제공하는' }) }}
        <br />
        {{ LanguageConverter({ en: 'PromisingLabs Teches', kr: '유망연구실의 기술분야' }) }}
      </div>
    </div>

    <div class="wf__number-wrapper">
      <div class="wf__number-wrapper-title">{{ LanguageConverter({ en: 'Fields', kr: '기술분야' }) }}</div>
      <ul>
        <li v-for="(tech, i) in sixTechLargeList" :key="i">
          {{ tech }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wf__content {
  padding: var(--wf-text-30) 0;
  font-size: var(--wf-text-10);

  border: 2px solid #fff;
  border-left: none;
  border-right: none;
}
.wf__intro {
  font-size: var(--wf-text-20);
}
.wf__intro-title--big {
  font-size: var(--wf-text-36);
  font-weight: 700;
}

.wf__number-wrapper {
  margin-top: var(--wf-text-50);
}

.wf__number-wrapper ul {
  margin-top: 0.75em;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--wf-text-20);
  font-weight: 700;
}
.wf__number-wrapper ul li {
  margin-top: 0.25em;
  margin-right: 1em;
}

.wf__number-wrapper-title {
  font-size: var(--wf-text-50);
  font-weight: 700;
}
</style>
