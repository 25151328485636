import store from '../store'
const isEng = sessionStorage.getItem('lang_mode') === 'eng'

export default [
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/community/Community.vue'),
    children: [
      {
        path: '',
        name: 'communityRedirect',
        redirect: '/community/notice'
      },
      {
        path: 'notice',
        name: 'Notice',
        meta: { title: isEng ? 'PromisingLabs Notice' : '유망연구실 공지사항' },
        component: () => import('../views/community/Notice/Notice.vue')
      },
      {
        path: 'notice/:id',
        name: 'NoticeDetail',
        meta: { title: isEng ? 'PromisingLabs Notice' : '유망연구실 공지사항' },
        component: () => import('../views/community/Notice/NoticeDetail.vue')
      },
      // {
      //   path: 'news',
      //   name: 'News',
      //   meta: { title: isEng ? 'News' : '대학별 공지사항' },
      //   component: () => import('../views/community/News/NewsList.vue')
      // },
      // {
      //   path: 'news/:id',
      //   name: 'NewsDetail',
      //   meta: { title: isEng ? 'News' : '대학별 공지사항' },
      //   component: () => import('../views/community/News/NewsDetail.vue')
      // },
      {
        path: 'labNews',
        name: 'labNews',
        meta: { title: isEng ? 'Lab News' : '연구실 동향' },
        component: () => import('../views/community/LabNews/LabNewsList.vue')
      },
      {
        path: 'labNews/:id',
        name: 'labNewsDetail',
        meta: { title: isEng ? 'Lab News' : '연구실 동향' },
        component: () => import('../views/community/LabNews/LabNewsDetail.vue')
      },
      // {
      //   path: 'gallery',
      //   name: 'Gallery',
      //   meta: { title: isEng ? 'Gallery' : '갤러리' },
      //   component: () => import('../views/community/Gallery/Gallery.vue')
      // },
      // {
      //   path: 'gallery/:id',
      //   name: 'GalleryDetail',
      //   meta: { title: isEng ? 'Gallery' : '갤러리' },
      //   component: () => import('../views/community/Gallery/GalleryDetail.vue')
      // },
      {
        path: 'qna',
        name: 'Qna',
        meta: { title: 'QnA' },
        component: () => import('../views/community/QnA/QnA.vue')
      },
      {
        path: 'qna/:id',
        name: 'QnaDetail',
        meta: { title: 'QnA' },
        component: () => import('../views/community/QnA/QnADetail.vue')
      },
      {
        path: 'qna/w',
        name: 'QnAWrite',
        meta: { title: 'QnA' },
        component: () => import('../views/community/QnA/QnAWrite.vue'),
        beforeEnter: (to, from, next) => {
          const isBot = store.getters['userStore/isBot']
          const user = store.getters['userStore/info']

          if (isBot || !user) {
            alert('로그인이 필요합니다.')
            return next('/member/login')
          }

          return next()
        }
      },
      // {
      //   path: 'help',
      //   name: 'Help',
      //   meta: { title: isEng ? 'help' : '도움말' },
      //   component: () => import('../views/community/Help/Help.vue')
      // },
      {
        path: 'pjt',
        name: 'project',
        meta: { title: isEng ? 'Projects' : '프로젝트 게시판' },
        component: () => import('../views/community/Project/Project.vue')
      },
      {
        path: 'pjt/:id',
        name: 'projectDetail',
        meta: { title: isEng ? 'Projects' : '프로젝트 게시판' },
        component: () => import('../views/community/Project/ProjectDetail.vue')
      },
      {
        path: 'paper',
        name: 'paper',
        meta: { title: isEng ? 'Papers' : '논문 게시판' },
        component: () => import('../views/community/Paper/Paper.vue')
      },
      {
        path: 'paper/:id',
        name: 'paperDetail',
        meta: { title: isEng ? 'Papers' : '논문 게시판' },
        component: () => import('../views/community/Paper/PaperDetail.vue')
      },
      {
        path: 'patent',
        name: 'patent',
        meta: { title: isEng ? 'Patents' : '지재권 게시판' },
        component: () => import('../views/community/Patent/Patent.vue')
      },
      {
        path: 'patent/:id',
        name: 'patentDetail',
        meta: { title: isEng ? 'Patents' : '지재권 게시판' },
        component: () => import('../views/community/Patent/PatentDetail.vue')
      },
      {
        path: 'handover',
        name: 'handover',
        meta: { title: isEng ? 'Tech Cooperation' : '기업협력 게시판' },
        component: () => import('../views/community/Handover/Handover.vue')
      },
      {
        path: 'handover/:id',
        name: 'handoverDetail',
        meta: { title: isEng ? 'Tech Cooperation' : '기업협력 게시판' },
        component: () => import('../views/community/Handover/HandoverDetail.vue')
      }
    ]
  }
]
