import store from '../store'

const navigationGuard = (next, test, redirectPath) => {
  if (test) return next(redirectPath)
  return next()
}

export const labNavigationGuard = (next, redirectPath = '/admin/labs') => {
  // TODO: userInfo의 labNo 필요
  const user = store.getters['userStore/info']

  navigationGuard(next, user.groupName === 'lab', redirectPath)
}
