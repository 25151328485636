//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  components: {},
  props: ['newestBoardDataList'],
  data() {
    return {}
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter,
    goBoard(type, id) {
      this.$router.push(`/community/${type}/${id}`)
    }
  }
}
</script>

<template>
  <div class="wf__newest">
    <div class="wf__newest-title-wrapper">
      <div>
        <div class="wf__newest-subtitle">Newest</div>
        <div class="wf__newest-title">{{ LanguageConverter({ en: 'Newest Boards', kr: '최근 게시물' }) }}</div>
      </div>
    </div>

    <div class="wf__newest-list-wrapper">
      <div class="wf__newest-list-item" v-for="(board, i) in newestBoardDataList" :key="i" @click="goBoard(board.type, board.id)">
        <div class="wf__newest-list-item-badge">
          {{ board.badge }}
        </div>

        <div class="wf__newest-list-item-title">
          {{ board.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__newest {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wf__newest-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--wf-black-color);

  padding-bottom: 12px;
}
.wf__newest-subtitle {
  font-size: var(--wf-text-14);
  font-weight: 300;
  color: var(--wf-primary-color);
}
.wf__newest-title {
  font-size: var(--wf-text-24);
  font-weight: 600;
  line-height: 1.3;
}
.wf__newest-list-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.wf__newest-list-item {
  cursor: pointer;
  flex: 1;
  border-bottom: 1px solid #707070;
  margin-top: 21.5px;
}
.wf__newest-list-item-badge {
  font-size: var(--wf-text-10);
  color: #777;
}
.wf__newest-list-item-title {
  font-size: var(--wf-text-16);
  height: 3em;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}
</style>
