export default [
  {
    path: '/admin/board/patent', // 지재권 게시판
    name: 'adminBoardPatent',
    component: () => import('../views/admin/board/patent/Patent.vue'),
    children: [
      // 지재권 게시판 리스트
      {
        path: ':labNo?/:page?',
        name: 'adminBoardPatentList',
        component: () => import('../views/admin/board/patent/PatentList.vue')
      },
      // 지재권 게시판 등록
      {
        path: 'w',
        name: 'adminBoardPatentCreate',
        component: () => import('../views/admin/board/patent/PatentCreate.vue')
      },
      // 지재권 게시판 보기, 수정
      {
        path: 'w/:id/:page?/:labNo?',
        name: 'adminBoardPatentUpdate',
        component: () => import('../views/admin/board/patent/PatentUpdate.vue')
      }
    ]
  }
]
