import { useAxios } from '../axiosUtil'

const baseUrl = '/v1/board'

const buildQuery = (obj) => {
  let str = ''
  for (const key in obj) {
    str += str === '' ? '?' : '&'
    str += `${key}=${obj[key]}`
  }
  return str
}

/**
 * 게시판 조회
 * @param {String} endpoint
 * @param {*} arg no value => 게시글 전체 조회;
 * @param {*} arg string or number value (board id) => 게시글 한 건 조회;
 * @param {*} arg object value => {limit: 조회할 건 수, offset: 조회 시작 지점};
 * @returns
 */
const getBoardData = async (endpoint, arg) => {
  // 전체 조회
  if (!arg) return await useAxios(`${baseUrl}/${endpoint}`)

  // 한 건 조회
  if (typeof arg !== 'object') return await useAxios(`${baseUrl}/${endpoint}/${arg}`)

  // 페이지네이션
  const obj = {
    limit: 1000,
    offset: 0,
    ...arg
  }
  return await useAxios(`${baseUrl}/${endpoint}${buildQuery(obj)}`)
}

const saveBoardData = async (endpoint, data, id) => {
  const url = id ? `${baseUrl}/${endpoint}/${id}` : `${baseUrl}/${endpoint}`
  const method = id ? 'PUT' : 'POST'
  return await useAxios(url, {
    method,
    data
  })
}

const deleteBoardData = async (endpoint, id) => {
  const url = `${baseUrl}/${endpoint}/${id}`
  const method = 'DELETE'
  return await useAxios(url, {
    method
  })
}

export const getLabPjt = async (arg) => {
  return await getBoardData('lab-project', arg)
}
export const getLabPaper = async (arg) => {
  return await getBoardData('lab-paper', arg)
}
export const getLabPatent = async (arg) => {
  return await getBoardData('lab-patent', arg)
}
export const getTechHandover = async (arg) => {
  return await getBoardData('lab-tech-handover', arg)
}
export const getNotice = async (arg) => {
  return await getBoardData('lab-notice', arg)
}
export const getNews = async (arg) => {
  return await getBoardData('lab-notice', arg)
}

export const getQna = async (arg) => {
  return await getBoardData('lab-qna', arg)
}
export const insertQna = async (data) => {
  return await saveBoardData('lab-qna', data)
}
export const updateQna = async (data, id) => {
  return await saveBoardData('lab-qna', data, id)
}
export const deleteQna = async (id) => {
  return await deleteBoardData('lab-qna', id)
}
export const getQnaReply = async (qnaId) => {
  return await getBoardData(`lab-qna/${qnaId}/comment`)
}
