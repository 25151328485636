<script setup>
import { defineProps, ref, defineEmits, onMounted, onUnmounted } from 'vue'
const props = defineProps({
  showModal: Boolean,
  closeOnClick: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['closeModal'])

const modal = ref(null)

const overlayClickHandler = (e) => {
  const el = e.target
  if (!el.matches('.wf__modal')) return

  if (props.closeOnClick) closeModal()
}

const closeModal = () => {
  modal.value.classList.add('closeModal')
  setTimeout(() => {
    emits('closeModal')
  }, 300)
}

onMounted(() => {
  document.documentElement.style.overflowY = 'hidden'
})
onUnmounted(() => {
  document.documentElement.style.overflowY = 'initial'
})
</script>

<template>
  <section @click="overlayClickHandler" class="wf__modal" ref="modal">
    <article class="wf__modal-wrapper">
      <slot></slot>

      <button class="wf__modal-close" @click="closeModal">
        <span class="material-icons-outlined"> close </span>
      </button>
    </article>
  </section>
</template>

<style scoped>
.wf__modal {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99999;
  backdrop-filter: blur(2px);

  transition: opacity 0.3s ease;
}
.wf__modal.closeModal {
  opacity: 0;
}

.wf__modal-wrapper {
  position: relative;
  background-color: #fff;
  padding: 4em;
  border-radius: 1em;

  max-width: 1200px;
  width: 50vw;

  margin: 0 2.6em;
}

.wf__modal-close {
  position: absolute;
  top: 1em;
  right: 1em;

  outline: none;
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 1199px) {
  .wf__modal-wrapper {
    width: 75vw;
  }
}

@media screen and (max-width: 992px) {
  .wf__modal-wrapper {
    padding: 3em 2.5em;
    width: 100%;
  }
}
</style>
