//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'
import Dropdown from '@/components/ui/Dropdown.vue'
import { regRemoval } from '@/util/regex'

export default {
  components: { Dropdown },
  emits: ['searchAction'],
  data() {
    const conditions = this.$store.state.searchStore.conditions
    return {
      conditions
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter,
    searchAction() {
      const replacedValue = this.$refs.searchInput.value.replace(regRemoval, '')
      this.$emit('searchAction', replacedValue, this.$refs.selectOption.searchValue)
    }
  }
}
</script>

<template>
  <div class="wf__search">
    <div class="wf__search-bar animate__animated animate__fadeInUp">
      <Dropdown :options="conditions" :disabled="false" name="zipcode" :maxItem="3" ref="selectOption" />

      <div class="divider"></div>

      <label class="input-wrapper">
        <input class="wf__search-input" type="text" @keyup.enter="searchAction" ref="searchInput" />
        <button type="button" id="" @click="searchAction">
          <span class="material-icons-outlined"> search </span>
        </button>
      </label>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
:deep(.dropdown-input) {
  flex-direction: row-reverse;
  border: none;
  justify-content: center;
  gap: 0.25em;
}
.wf__search-bar {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  width: 70%;

  padding: 0.25em 0.5em;

  background-color: #fff;
  border: 2px solid var(--wf-gray-color);
  border-radius: 12px;

  animation-delay: 1s;
  transition: all 0.3s ease;
}
.wf__search-bar:hover {
  border-color: var(--wf-misty-color);
}
.wf__search-bar:has(input:focus) {
  border-color: var(--wf-misty-color);
  box-shadow: 0em 0em 1em rgba(255, 255, 255, 0.7);
}

.divider {
  width: 2px;
  height: 1em;
  margin: 0 0.5em;
  background-color: var(--wf-gray-color);
}

.wf__search-input {
  background-color: transparent;
  padding: 0.5em 1em;
  width: 100%;
  outline: none;
  border: none;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
}
.input-wrapper button {
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
}
.input-wrapper button span {
  font-size: 28px;
}

@media screen and (max-width: 576px) {
  .wf__search-bar {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }

  :deep(.dropdown-input) {
    flex-direction: row-reverse;
    border: none;
    justify-content: center;
    width: 60px !important;
    gap: 0px;
  }
}
</style>
