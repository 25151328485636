// module store
// store.state.menuStore로 접근

export default {
  namespaced: true,
  state: {
    conditions: [
      { value: 'all', text: '전체', textEn: 'All' },
      { value: 'title', text: '제목', textEn: 'Title' },
      { value: 'keyword', text: '키워드', textEn: 'Keyword' },
      { value: 'researcherName', text: '연구원', textEn: 'Researcher' },
      { value: 'scienceClass', text: '기술분류', textEn: 'Tech classification' }
    ]
  },
  getters: {},
  mutations: {},
  actions: {}
}
