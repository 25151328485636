import { labNavigationGuard } from '../util/routerNavigationGuard'

import adminContentUrl from '@/router/adminContent'
import adminPopupUrl from '@/router/adminPopup'
import adminBannersUrl from '@/router/adminBanners'
import adminLabsUrl from '@/router/adminLabs'
import adminProject from '@/router/adminProject' // 프로젝트
import adminPaper from '@/router/adminPaper' // 논문
import adminPatent from '@/router/adminPatent' // 지재권
import adminData from '@/router/adminData'

const adminUrl = [
  ...adminContentUrl,
  ...adminPopupUrl,
  ...adminBannersUrl,
  ...adminLabsUrl,
  ...adminProject,
  ...adminPaper,
  ...adminPatent,
  ...adminData,
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/Basic.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/basic',
    name: 'adminBasic',
    component: () => import('../views/admin/Basic.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import('../views/admin/users/UsersList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/gallery', // 갤러리 게시판
    name: 'adminBoardGalleryList',
    component: () => import('../views/admin/board/gallery/GalleryList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/gallery/galleryCreate/:boardNo?', // 갤러리 등록
    name: 'adminBoardGalleryCreate',
    component: () => import('../views/admin/board/gallery/GalleryCreate.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/qna/:page?', // Qna 게시판
    name: 'adminBoardQNAList',
    component: () => import('../views/admin/board/qna/QNAList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/qna/qnaCreate/:boardNo/:page?', // Qna 등록
    name: 'adminBoardQNACreate',
    component: () => import('../views/admin/board/qna/QNACreate.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/notice/:page?', // 공지사항 게시판
    name: 'adminBoardNoticeList',
    component: () => import('../views/admin/board/notice/NoticeList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/notice/noticeCreate/:boardNo?/:page?', // 공지사항 등록
    name: 'adminBoardNoticeCreate',
    component: () => import('../views/admin/board/notice/NoticeCreate.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/board/result/:labNo?/:page?', // 산학협력 게시판
    name: 'adminBoardResultList',
    component: () => import('../views/admin/board/result/ResultList.vue')
  },
  {
    path: '/admin/board/result/resultCreate/:boardNo?/:page?/:labNo?', // 산학협력 등록
    name: 'adminBoardResultCreate',
    component: () => import('../views/admin/board/result/ResultCreate.vue')
  },
  {
    path: '/admin/users', // 회원리스트
    name: 'adminUsersList',
    component: () => import('../views/admin/users/UsersList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  },
  {
    path: '/admin/users/usercreate/:userId?', // 회원 view, create
    name: 'adminUsersListCreate',
    component: () => import('../views/admin/users/UserCreate.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  }
]

export default adminUrl
