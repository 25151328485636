//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  data() {
    return {}
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <div class="wf__intro">
    <div class="wf__intro-title-wrapper animate__animated animate__fadeInUp">
      <h3 class="wf__intro-subtitle">
        {{ LanguageConverter({ en: 'A Platform that connects Labs and Companies', kr: '연구실과 기업을 연결하는 공간' }) }}
      </h3>
      <h1 class="wf__intro-title">{{ LanguageConverter({ en: 'PromisingLabs Integration Platform', kr: '유망연구실 통합플랫폼' }) }}</h1>
    </div>

    <p class="wf__intro-content animate__animated animate__fadeInUp">
      {{
        LanguageConverter({
          en: "PromisingLabs Integration Platform is based on the researcher's researcher, task, department information",
          kr: '연구자 정보, 보유기술, 프로젝트 수행이력 등의 정보제공을 통해 기술수요 기업과'
        })
      }}
      <br />
      {{
        LanguageConverter({
          en: 'It is a platform for smoothly sharing related research information and performance information.',
          kr: '관련 기술 및 역량을 보유한 연구실 간 매칭이 될 수 있도록 지원하는 플랫폼입니다.'
        })
      }}
    </p>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__intro {
  display: flex;
  flex-direction: column;
  text-align: center;

  gap: 1em;
}

.wf__intro-subtitle {
  color: #fff;
  font-weight: 500;
  font-size: var(--wf-text-24);
  margin-bottom: 4px;
}
.wf__intro-title {
  font-size: var(--wf-text-50);
  font-weight: 700;

  color: var(--wf-misty-color);
}
.wf__intro-content {
  font-weight: 400;
  animation-delay: 0.4s;
  font-size: var(--wf-text-16);
}

@media screen and (max-width: 576px) {
  .wf__intro-subtitle {
    font-size: 15px;
  }
  .wf__intro-content {
    font-size: 14px;
  }

  .wf__intro-content br {
    display: none;
  }
}
</style>
