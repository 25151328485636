export default [
  {
    path: '/admin/popups', // 팝업레이어 관리
    name: 'adminPopups',
    component: () => import('../views/admin/popups/Popups.vue'),
    children: [
      {
        path: '',
        name: 'adminPopupsList',
        component: () => import('../views/admin/popups/PopupsList.vue')
      },
      // 쓰기 페이지
      {
        path: 'w',
        name: 'adminPopupsWrite',
        component: () => import('../views/admin/popups/PopupsDetail.vue')
      },
      // 수정 페이지
      {
        path: 'w/:id',
        name: 'adminPopupsModify',
        component: () => import('../views/admin/popups/PopupsDetail.vue')
      }
    ]
  }
]
