import { labNavigationGuard } from '../util/routerNavigationGuard'

export default [
  {
    path: '/admin/contents',
    name: 'adminContents',
    component: () => import('../views/admin/contents/Contents.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    },
    children: [
      {
        path: '',
        name: 'adminContentsList',
        component: () => import('../views/admin/contents/ContentsList.vue')
      },
      // 보기 페이지
      {
        path: 'r/:id',
        name: 'adminContentsDetail',
        component: () => import('../views/admin/contents/ContentsDetail.vue')
      },
      // 쓰기 페이지
      {
        path: 'w',
        name: 'adminContentsWrite',
        component: () => import('../views/admin/contents/ContentsDetail.vue')
      },
      // 수정 페이지
      {
        path: 'w/:id',
        name: 'adminContentsModify',
        component: () => import('../views/admin/contents/ContentsDetail.vue')
      }
    ]
  }
]
