import store from '../store'
import { labNavigationGuard } from '../util/routerNavigationGuard'

export default [
  {
    path: '/admin/labs',
    name: 'adminLabs',
    component: () => import('../views/admin/labs/Labs.vue'),
    children: [
      {
        path: '',
        name: 'adminLabsList',
        component: () => import('../views/admin/labs/LabsList.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['userStore/info']
          labNavigationGuard(next, `/admin/labs/u/${user.labNo}`)
        }
      },
      // 쓰기 페이지
      {
        path: 'w',
        name: 'adminLabsWrite',
        component: () => import('../views/admin/labs/LabsDetail.vue'),
        beforeEnter: (to, from, next) => {
          const user = store.getters['userStore/info']
          labNavigationGuard(next, `/admin/labs/u/${user.labNo}`)
        }
      },
      // 수정 페이지
      {
        path: 'u/:id',
        name: 'adminLabsModify',
        component: () => import('../views/admin/labs/LabsDetail.vue')
      }

      // TODO: 연구실 프로젝트 리스트 보기 화면 작성 필요
    ]
  },
  {
    path: '/admin/mails',
    redirect: '/admin/mails/0'
  },
  {
    path: '/admin/mails/:labId',
    name: 'adminLabMail',
    component: () => import('../views/admin/mail/LabMail.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    },
    children: [
      {
        path: '',
        name: 'adminLabMailList',
        component: () => import('../views/admin/mail/LabMailList.vue')
      },
      {
        path: ':mailId',
        name: 'adminLabMailDetail',
        component: () => import('../views/admin/mail/LabMailDetail.vue')
      }
    ]
  },
  {
    path: '/admin/labs/labsapplylist',
    name: 'adminLabsApplyList',
    component: () => import('../views/admin/labs/LabsApplyList.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    }
  }
]
