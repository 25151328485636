const keyPrefix = 'wf__'

export default {
  install(Vue) {
    /**
     * ISO Date을 받아서 yyyy-MM-dd 형식으로 return
     * @param {*} isoDate
     * @returns {'2022-01-01 06:00:00'}
     */
    Vue.config.globalProperties.$getDate = function (isoDate) {
      try {
        const date = new Date(isoDate)
        return date.toISOString().substring(0, 10) ?? ''
      } catch (error) {
        return '-'
      }
    }

    /**
     * ISO Date을 받아서 yyyy-MM-dd h:m:s형식으로 return
     * @param {*} isoDate
     * @returns {'2022-01-01 06:00:00'}
     */
    Vue.config.globalProperties.$getDateTime = function (isoDate) {
      const date = new Date(isoDate)
      return date.toLocaleString() ?? ''
    }

    /**
     * console.log 대체
     * @param {*} string
     */
    Vue.config.globalProperties.$log = function (string) {
      if (window.location.href.substr(7, 5) === 'local') {
        console.log('*****************************')
        console.log(string)
        console.log('*****************************')
      }
    }
  },

  setCookie(opt) {
    const { key, value, maxAge, expires } = opt
    let cookieStr = `${keyPrefix}${key}=${value};`

    if (maxAge) cookieStr += ` max-age=${maxAge};`
    if (expires) cookieStr += ` expires=${expires};`

    document.cookie = cookieStr
  },

  getCookie(key) {
    const value = document.cookie.match('(^|;) ?' + `${keyPrefix}${key}` + '=([^;]*)(;|$)')
    return value ? value[2] : null
  },

  delCookie(key) {
    document.cookie = `${keyPrefix}${key}=; max-age=0`
  }
}
