export default function throttle(cb, delay = 500) {
  let shouldWait = false
  let waitingArgs = null

  const timeout = () => {
    if (waitingArgs === null) {
      shouldWait = false
    } else {
      cb(waitingArgs)
      waitingArgs = null
      setTimeout(timeout, delay)
    }
  }

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args
      return
    }

    cb(args)
    shouldWait = true

    setTimeout(timeout, delay)
  }
}
