import { labNavigationGuard } from '../util/routerNavigationGuard'

export default [
  {
    path: '/admin/data',
    name: 'adminData',
    component: () => import('../views/admin/data/Data.vue'),
    beforeEnter: (to, from, next) => {
      labNavigationGuard(next)
    },
    children: [
      {
        path: '',
        name: 'adminDataList',
        component: () => import('../views/admin/data/DataList.vue')
      },
      {
        path: 'w',
        name: 'adminDataDetail',
        component: () => import('../views/admin/data/DataDetail.vue')
      },
      // 수정 페이지
      {
        path: 'w/:id',
        name: 'adminDataDetail',
        component: () => import('../views/admin/data/DataDetail.vue')
      }
    ]
  }
]
