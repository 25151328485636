<script setup>
import LanguageConverter from '@/util/LanguageConverter'
import sanitizer from '@/util/htmlSanitizer'

import Animation1 from '@/components/Home/Content3/Animation1.vue'
import Animation2 from '@/components/Home/Content3/Animation2.vue'
import Animation3 from '@/components/Home/Content3/Animation3.vue'
import Animation4 from '@/components/Home/Content3/Animation4.vue'
import MobileHomeSection3 from '@/components/Home/MobileHomeSection3.vue' // section3

import { getLab } from '@/api/labs'
import slide1 from '@/assets/images/001.jpg'
import slide2 from '@/assets/images/002.jpg'
import slide3 from '@/assets/images/003.jpg'
import slide4 from '@/assets/images/004.jpg'

import { getTechHandover, getLabPjt, getLabPaper, getLabPatent } from '@/api/platform/community'
import { getSixTechnology } from '@/api/category/sixTechnologyCategory'

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const currentIdx = ref(-1)

const slides = [slide1, slide2, slide3, slide4]
const slideRef = ref({})
const labList = ref([])
const maxNumberList1 = ref([])
const sixTechLargeList = ref([])
const maxNumberList2 = ref([])

const constTextContents = [
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Technology Transfer', kr: '기업협력' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Core <br/> Competency', kr: '역량' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Fields', kr: '분야' }),
    desc: ''
  },
  {
    subtitle: LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }),
    title: LanguageConverter({ en: 'Labs', kr: '연구실' }),
    desc: ''
  }
]
const constTextRef = ref([])

const hover = (e) => {
  const key = e.target.dataset.idx
  if (!key && key !== 0) return
  currentIdx.value = key

  slideRef.value[key].classList.add('on')
  for (const el of constTextRef.value) {
    el.classList.add('hide')
    el.closest('article').classList.add('no-border')
  }
  constTextRef.value[key].classList.remove('hide')
}

const leave = (e) => {
  const key = e.target.dataset.idx
  if (!key && key !== 0) return

  currentIdx.value = -1

  slideRef.value[key].classList.remove('on')
  for (const el of constTextRef.value) {
    el.classList.remove('hide')
    el.closest('article').classList.remove('no-border')
  }
}

const setSlideRef = (el, opt) => {
  if (!el) return
  const key = el.dataset.idx
  slideRef.value[key] = el
}

const setConstTextRef = (el) => {
  if (!el) return
  const key = el.dataset.idx
  constTextRef.value[key] = el
}

const labComponent = ref(null)
const goLab = () => {
  labComponent.value.goLab()
}
const goHandover = () => {
  router.push('/community/handover')
}
const goProject = () => {
  router.push('/community/pjt')
}
const goFields = () => {
  router.push('/researchField/field')
}

const sixTechByLargeModel = {
  IT: '정보기술',
  BT: '생명공학기술',
  NT: '나노기술',
  ST: '우주항공기술',
  ET: '환경기술',
  CT: '문화기술'
}
const groupSixTechByLarge = (arr) => {
  return arr.reduce((acc, cur) => {
    const stLarge = sixTechByLargeModel[cur.sixTechnologyLarge]
    if (stLarge && acc.findIndex((item) => item === stLarge) === -1) acc.push(stLarge)
    return acc
  }, [])
}

const setMaxNumberList = async () => {
  Promise.all([getSixTechnology(), getTechHandover()]).then((values) => {
    const [fields, handovers] = values
    if (fields.length) {
      maxNumberList1.value.push(fields.length)

      sixTechLargeList.value = groupSixTechByLarge(fields)
    } else {
      maxNumberList1.value.push(0)
    }

    if (handovers.count) maxNumberList1.value.push(handovers.count)
    else maxNumberList1.value.push(0)
  })

  Promise.all([getLabPjt(), getLabPaper(), getLabPatent()]).then((values) => {
    for (const value of values) {
      if (value.count) maxNumberList2.value.push(value.count)
      else maxNumberList2.value.push(0)
    }
  })
}

onMounted(async () => {
  await setMaxNumberList()
  labList.value = (await getLab()).items
})
</script>

<template>
  <MobileHomeSection3
    class="wf__home-section3--mob"
    :techMaxNumberList="maxNumberList1"
    :boardsMaxNumberList="maxNumberList2"
    :sixTechLargeList="sixTechLargeList"
    :labList="labList"
  ></MobileHomeSection3>

  <section class="home-section wf__home-section3--pc">
    <section class="wf__slide-section">
      <article
        v-for="(slide, i) in slides"
        :key="i"
        :data-idx="i"
        class="wf__home-slide"
        :class="`slide${i + 1}`"
        :style="{ 'background-image': `url(${slide})` }"
        :ref="setSlideRef"
      >
        <div class="wf__home-slide-overlay"></div>
      </article>
    </section>

    <section class="wf__area-section">
      <article v-for="(slide, i) in slides" :key="i" :data-idx="i" class="wf__home-area" @mouseover="hover" @mouseleave="leave">
        <div class="wf__const-text" :ref="setConstTextRef" :data-idx="i">
          <h5 class="wf__const-subtitle" v-html="sanitizer(constTextContents[i].subtitle)"></h5>
          <h1 class="wf__const-title" v-html="sanitizer(constTextContents[i].title)"></h1>
          <p class="wf__const-desc" v-html="sanitizer(constTextContents[i].desc)"></p>
        </div>

        <div v-if="currentIdx == 0 && i === slides.length - 4" class="wf__lab-link-button-wrapper">
          <button class="wf__lab-link-button" @click="goHandover">
            {{ LanguageConverter({ en: 'Read More', kr: '자세히 보러가기' }) }}
          </button>
        </div>
        <div v-if="currentIdx == 1 && i === slides.length - 3" class="wf__lab-link-button-wrapper">
          <button class="wf__lab-link-button" @click="goProject">
            {{ LanguageConverter({ en: 'Read More', kr: '자세히 보러가기' }) }}
          </button>
        </div>
        <div v-if="currentIdx == 2 && i === slides.length - 2" class="wf__lab-link-button-wrapper">
          <button class="wf__lab-link-button" @click="goFields">
            {{ LanguageConverter({ en: 'Read More', kr: '자세히 보러가기' }) }}
          </button>
        </div>
        <div v-if="currentIdx == 3 && i === slides.length - 1" class="wf__lab-link-button-wrapper">
          <button class="wf__lab-link-button" @click="goLab">
            {{ LanguageConverter({ en: 'Visit The Lab', kr: '연구실보러가기' }) }}
          </button>
        </div>
      </article>
    </section>

    <section class="wf__animation-section">
      <Animation1 v-if="currentIdx == 0" :maxNumberList="maxNumberList1" />
      <Animation2 v-if="currentIdx == 1" :maxNumberList="maxNumberList2" />
      <Animation3 v-if="currentIdx == 2" :sixTechLargeList="sixTechLargeList" />
      <Animation4 v-if="currentIdx == 3" :labList="labList" ref="labComponent" />
    </section>
  </section>
</template>

<style scoped>
.home-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-left: calc(-50vw + 50%);
  background-color: var(--wf-misty-color);
}

.wf__slide-section,
.wf__area-section,
.wf__animation-section {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wf__animation-section {
  z-index: 2;
}
.wf__area-section {
  z-index: 3;
}
.wf__home-slide,
.wf__home-area {
  width: 25%;
  height: 100%;
}

.wf__home-area {
  position: relative;

  border: none;
  border-right: 1px solid var(--wf-secondary-color);
}
.wf__home-area.no-border {
  border: none;
}

.wf__home-slide {
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center;

  transition: all 0.6s ease-out;
}
.slide1 {
  left: 0;
}
.slide2 {
  left: 25%;
}
.slide3 {
  left: 50%;
}
.slide4 {
  left: 75%;
}
.wf__home-slide.on {
  width: 100%;
  z-index: 2;
}
.slide2.on {
  transform: translateX(-25%);
}
.slide3.on {
  transform: translateX(-50%);
}
.slide4.on {
  transform: translateX(-75%);
}

.wf__home-slide-overlay,
.wf__home-slide-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wf__home-slide-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.wf__const-text {
  position: absolute;
  top: 30%;
  left: 10%;
  color: var(--wf-misty-color);

  text-align: left;
}
.wf__const-text.hide {
  display: none;
}
.wf__const-subtitle {
  font-size: var(--wf-text-18);
}
.wf__const-title {
  font-size: var(--wf-text-48);
  font-weight: bold;
}
.wf__const-desc {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

.wf__lab-link-button-wrapper {
  position: absolute;

  top: 42%;
  left: 10%;
  width: 36%;
}
.wf__lab-link-button {
  width: 100%;
  font-size: 16px;

  padding: 0.25em 0.5em;

  background-color: transparent;
  border: 2px solid var(--wf-gray-color);
  color: #fff;

  transition: all 0.2s ease;
}

.wf__lab-link-button:hover {
  border-color: var(--wf-gray-600);
  color: var(--wf-gray-600);
}

.wf__home-section3--mob {
  display: none;
}

@media screen and (max-width: 1199px) {
  .wf__home-section3--pc {
    display: none;
  }
  .wf__home-section3--mob {
    display: block;
  }
}
</style>
