//INFO: 화면설명
<script>
import InfoCardVue from '../ui/InfoCard.vue'
import Dropdown from '@/components/ui/Dropdown.vue'
import { getLab, sendEmailToLab } from '@/api/labs'
import LanguageConverter from '@/util/LanguageConverter'
import { validateEmail, validateKorEngNumberSpecialForContent } from '@/util/reg-validators'
import { pipe } from '@/util/functional'
import { getContentById } from '@/api/admin/contents'
import TextViewerVue from '@/components/TextViewer.vue'

export default {
  components: { TextViewerVue, InfoCardVue, Dropdown },
  props: ['email', 'labNo'],
  data() {
    return {
      terms: {
        title: '',
        content: ''
      },
      termsAgreement: false,
      emailTo: this.email,
      handoverData: {
        email: '',
        questionType: '',
        title: '',
        content: `1. 기업명:
2. 연락처:
3. 내용:
        `
      },
      searchConditions: [
        { value: '', text: '선택' },
        { value: '기술이전', text: '기술이전' },
        { value: '기술자문', text: '기술자문' },
        { value: 'R&D', text: 'R&D' },
        { value: '기타', text: '기타' }
      ],
      labData: null
    }
  },
  computed: {
    labList() {
      return this.$store.state.labStore.labList
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.getTermsOfUse()
    if (!this.labNo) return (this.emailTo = this.$store.state.basicInfo.email)

    if (this.labList.length !== 0) {
      this.labData = this.labList.find((item) => item.labNo === this.labNo)
    } else {
      this.labData = await getLab(this.labNo)
    }

    this.labData = {
      profile: this.labData.leadResearcherImgUrl,
      title: LanguageConverter({ en: this.labData.labNameEn, kr: this.labData.labName }),
      subtitle: LanguageConverter({ en: this.labData.leadResearcherNameEn, kr: this.labData.leadResearcherName }),
      infoList: this.labData.keyword?.split(', ')
    }
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    onInfoItemClick(info) {
      window.location.href = `/integratedSearch/integratedSearchResult?q=${info}&opt=keyword`
    },

    async getTermsOfUse() {
      const { content, title } = await getContentById(56)
      this.terms = { content, title }
    },

    setNotValidData(msg) {
      return { ok: false, msg }
    },

    removeSpaceChar(str) {
      return str.replaceAll(/[ ]/g, '')
    },
    removeLineBreak(str) {
      return str.replaceAll('\n', '')
    },
    openTerms() {
      this.$refs.termsWrapper.classList.toggle('open')
    },

    validateForm(data) {
      const { email, questionType, title, content } = data

      const titleForCheck = this.removeSpaceChar(title)
      const contentForCheck = pipe(
        this.removeSpaceChar, //
        this.removeLineBreak
      )(content)

      if (!validateEmail(email)) return this.setNotValidData('이메일 형식에 맞게 작성해주십시오.')
      if (!questionType) return this.setNotValidData('문의 목적을 선택해주십시오.')
      if (!titleForCheck) return this.setNotValidData('제목을 입력해주십시오.')
      if (!contentForCheck) return this.setNotValidData('내용을 입력해주십시오.')
      if (!validateKorEngNumberSpecialForContent(titleForCheck)) {
        return this.setNotValidData('입력할 수 없는 특수문자 또는 문자가 포함되어있습니다.')
      }
      if (!validateKorEngNumberSpecialForContent(contentForCheck)) {
        return this.setNotValidData('입력할 수 없는 특수문자 또는 문자가 포함되어있습니다.')
      }

      return { ok: true }
    },

    async sendEmail() {
      const { ok, msg } = this.validateForm(this.handoverData)
      if (!ok) return alert(msg)

      const result = await sendEmailToLab(this.labNo, this.handoverData)

      if (result.code) {
        return alert(result.message)
      }

      if (!this.termsAgreement) {
        return alert('개인정보 이용약관에 동의해주시기 바랍니다')
      }

      alert('신청되었습니다.')
      this.$router.go()
    },
    selectDropdownHandler(opt) {
      this.handoverData.questionType = opt.value
    }
  }
}
</script>

<template>
  <div class="wf__handover-container">
    <h1 class="wf__handover-title">
      {{ LanguageConverter({ en: 'Contact Us', kr: '연구실협력신청' }) }}
    </h1>

    <div class="wf__handover-subtitle" v-if="$store.getters.isEng">Application for Industry-Academic Cooperation</div>

    <div class="divider"></div>

    <form class="wf__handover-form" ref="emailForm">
      <div class="wf__handover-input-wrapper">
        <span v-if="labData" :style="{ 'grid-column': '1 / 3' }">
          <InfoCardVue
            class="wf__lab-summary-profile-content"
            :src="labData.profile"
            :data="labData"
            :notList="true"
            :noImg="true"
            @onInfoItemClick="onInfoItemClick"
            cursor="default"
            maxWidth="150px"
          >
          </InfoCardVue>
        </span>
        <span class="wf__handover-input-label d-none">{{ LanguageConverter({ en: 'To', kr: '받는사람' }) }}</span>
        <input class="wf__handover-input" readonly name="emailTo" type="hidden" v-model="emailTo" />

        <span class="wf__handover-input-label">{{ LanguageConverter({ en: 'From', kr: '보내는 사람' }) }}</span>
        <input class="wf__handover-input" name="email" type="text" v-model="handoverData.email" placeholder="이메일을 입력해주세요" />

        <span class="wf__handover-input-label">{{ LanguageConverter({ en: 'Purpose', kr: '문의 목적' }) }}</span>
        <span>
          <Dropdown class="select-wrapper" :options="searchConditions" name="zipcode" @selected="selectDropdownHandler"></Dropdown>
        </span>
        <!-- <input class="wf__handover-input" name="text" type="text" v-model="handoverData.questionType" /> -->

        <span class="wf__handover-input-label">{{ LanguageConverter({ en: 'Title', kr: '제목' }) }}</span>
        <input class="wf__handover-input" type="text" v-model="handoverData.title" name="title" placeholder="" />
      </div>

      <div>
        <textarea class="wf__handover-input-textarea" v-model="handoverData.content"></textarea>
      </div>

      <div class="wf__handover-input-terms" ref="termsWrapper">
        <div class="wf__handover-input-terms-button-wrapper">
          <input type="checkbox" name="" id="_term-radio" v-model="termsAgreement" />
          <label for="_term-radio" class="wf__handover-input-terms-agreement">
            <span class="material-icons"> check </span>
          </label>

          <button type="button" class="wf__handover-input-terms-button" @click="openTerms">
            {{ terms.title }}
            <span class="material-icons"> navigate_next </span>
          </button>
        </div>
        <div class="wf__handover-input-terms-panel">
          <TextViewerVue :propsData="terms.content"></TextViewerVue>
        </div>
      </div>

      <div class="wf__handover-submit-wrapper">
        <button type="button" class="wf__handover-submit" @click="sendEmail">
          {{ LanguageConverter({ en: 'Send', kr: '연구실협력신청' }) }}
          <span class="material-icons"> play_arrow </span>
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
* {
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wf__handover-container {
  max-height: 80vh;
  overflow-y: scroll;
}
.wf__handover-container::-webkit-scrollbar {
  display: none;
}
.wf__handover-title {
  font-weight: bold;
  font-size: var(--wf-text-36);
  color: var(--wf-black-color);
  text-align: center;
}
.wf__handover-subtitle {
  text-align: center;
  font-size: var(--wf-text-20);
  color: var(--wf-gray-color);
}
.divider {
  height: 2px;
  background-color: var(--wf-gray-color);
  margin: 2em 0;
}

.wf__handover-form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: var(--wf-text-16);
}
.wf__handover-input-wrapper {
  display: grid;
  grid-template-columns: max-content minmax(0px, 1fr);
  row-gap: 1em;
  column-gap: 0.5em;
}

.wf__handover-input-label {
  display: flex;
  align-items: center;
}

.wf__handover-input,
.wf__handover-submit {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
  outline: none;
  border: 1px var(--wf-gray-color) solid;
  border-radius: 0.25em;
}
.wf__handover-input:read-only {
  background-color: var(--wf-misty-color);
  color: var(--wf-gray-color);
}

.wf__handover-input-textarea {
  width: 100%;
  min-height: 15em;
  padding: 1em;
}

.wf__handover-submit {
  float: right;
  color: var(--wf-misty-color);
  background-color: var(--wf-primary-color);
  transition: opacity 0.2s ease;
}
.wf__handover-submit:hover {
  opacity: 0.75;
}

:deep(div.info-content) {
  cursor: pointer;
}

.wf__handover-input-terms {
  padding: 0.25em 0em;
}
.wf__handover-input-terms-button-wrapper {
  display: flex;
  align-items: center;
  padding: 0.5em 0em;
  gap: 1em;
}
.wf__handover-input-terms-agreement {
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid var(--wf-gray-color);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.wf__handover-input-terms-button-wrapper input[type='checkbox'] {
  display: none;
}
.wf__handover-input-terms-button-wrapper input[type='checkbox']:checked + .wf__handover-input-terms-agreement {
  border-color: var(--wf-primary-color);
  background-color: var(--wf-primary-color);
}
.wf__handover-input-terms-button-wrapper input[type='checkbox']:checked + .wf__handover-input-terms-agreement .material-icons {
  color: #fff;
}
.wf__handover-input-terms-agreement .material-icons {
  font-size: 1em !important;
  color: var(--wf-gray-color);
  font-weight: bold;
}

.wf__handover-input-terms-button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  flex: 1;

  color: var(--wf-black-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wf__handover-input-terms-button .material-icons {
  color: var(--wf-gray-color);
  transition: rotate 0.4s;
}
.wf__handover-input-terms.open .wf__handover-input-terms-button .material-icons {
  color: var(--wf-black-color);
  rotate: 90deg;
}
.wf__handover-input-terms-panel {
  height: 0em;
  overflow-y: scroll;
  transition: height 0.4s;
}

.wf__handover-input-terms.open .wf__handover-input-terms-panel {
  height: 10em;
}
</style>
