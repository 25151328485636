import { useAxios } from '../axiosUtil'
import router from '@/router'

const root = '/v1/setting/content'

export const getContent = async () => {
  // axios로 정보 조회
  const result = await useAxios(`${root}`)

  // 조회한 정보가 있을경우
  if (result) return result

  if (!result) {
    // 조회한 정보가 없을경우
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  // 정보 조회시 오류가 발생했을 경우
  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getContentById = async (id) => {
  // axios로 정보 조회
  const result = await useAxios(`${root}/${id}`)

  // 조회한 정보가 있을경우
  if (result) return result

  if (!result) {
    // 조회한 정보가 없을경우
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  // 정보 조회시 오류가 발생했을 경우
  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const saveContent = async (data) => {
  const result = await useAxios(`${root}`, {
    method: 'post',
    data
  })

  return result
}

export const updateContent = async (data) => {
  const result = await useAxios(`${root}/${data.contentId}`, {
    method: 'PUT',
    data
  })

  // 정보 조회시 오류가 발생했을 경우
  if (result.message || result.code === 9999) {
    alert('저장 도중 오류가 발생했습니다.')
    return false
  }

  return true
}

export const deleteContent = async (contentId) => {
  const result = await useAxios(`${root}/${contentId}`, {
    method: 'delete'
  })
  // 정보 조회시 오류가 발생했을 경우
  if (result.message || result.code === 9999) {
    alert('저장 도중 오류가 발생했습니다.')
    return false
  }

  return true
}
