<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  components: {},
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter,

    clickTechTransfer(e) {
      e.preventDefault()
    }
  }
}
</script>

<template>
  <div class="wf__home-cards animate__animated animate__fadeInUp">
    <router-link class="wf__link-card" to="/researchField/lab">
      <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Search By Labs', kr: '연구실별 검색' }) }}</span>
      <span class="wf__link-card-desc">
        {{ LanguageConverter({ en: 'Search Labs you want', kr: '원하는 연구실만 선택해서 검색하기' }) }}
      </span>
    </router-link>

    <router-link class="wf__link-card" to="/researchField/major">
      <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Search By Departments', kr: '기관별 검색' }) }}</span>
      <span class="wf__link-card-desc">
        {{ LanguageConverter({ en: 'Search Labs By Departments', kr: '원하는 학과만 선택해서 검색하기' }) }}
      </span>
    </router-link>

    <router-link class="wf__link-card cursor-pointer" to="/researchField/field">
      <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Search By Fields', kr: '연구분야별 검색' }) }} </span>
      <span class="wf__link-card-desc">
        {{ LanguageConverter({ en: 'Search Labs By Fields', kr: '원하는 연구분야만 선택해서 검색하기' }) }}</span
      >
    </router-link>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__home-cards {
  display: flex;
  gap: 20px;

  animation-delay: 1.6s;
}

.wf__link-card {
  background-color: rgba(0, 0, 0, 0.45);
  border: 1px solid #fff;

  transition: all 0.2s ease-in;
}
.wf__link-card:hover {
  background-color: #0b425f;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .wf__home-cards {
    width: 100%;
    flex-direction: column;
  }
}
</style>
