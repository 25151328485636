<script>
// 스크롤 기능 구현 참고 사이트 => https://webdeasy.de/en/programming-vue-js-fullpage-scroll/#demos
import HomeContent1 from '@/components/Home/HomeContent1.vue'
import HomeContent2 from '@/components/Home/HomeContent2.vue'
import HomeContent3 from '@/components/Home/HomeContent3.vue' // section3
// import HomeContent4 from '@/components/Home/HomeContent4.vue'
import HomeContent5 from '@/components/Home/HomeContent5.vue'
import PopupWrapperVue from '@/components/Home/PopupWrapper.vue'
import HomeSectionNavigatorVue from '@/components/Home/HomeSectionNavigator.vue'

import throttle from '@/util/throttle'

export default {
  components: { PopupWrapperVue, HomeContent1, HomeContent2, HomeContent3, HomeContent5, HomeSectionNavigatorVue },
  data() {
    return {
      scrollSectionList: [],
      inMove: false, // this.inMove : 움직이는 지 아닌지 확인하는 상태값
      activeSection: 0, // 현재 위치한 section
      offsets: [], // 각 section들의 초기 위치값
      totalSection: 0, // section들의 총 갯수
      appHeader: null,
      throttleForResizeEvent: null,
      throttleForScroll: null
    }
  },
  methods: {
    scrollSection(el) {
      if (this.scrollSectionList > 4) return
      this.scrollSectionList.push(el)
    },

    // scroll section별 높이를 계산하여 offsets에 저장
    calculateSectionOffsets() {
      this.offsets = []

      const sections = this.$refs.homeSection.querySelectorAll('.wf__home-section')

      for (const section of sections) {
        const sectionOffset = section.offsetTop
        this.offsets.push(sectionOffset)
      }

      this.totalSection = sections.length
    },

    // 일반 브라우저에서 실행되는 scroll event
    handleMouseWheel: function (e) {
      if (document.querySelector('.wf__modal')) return
      const scrollUp = e.wheelDelta > 30

      if (!scrollUp && this.activeSection === 4) return

      e.preventDefault() // 기본 스크롤 막기

      if (!this.inMove) {
        this.calculateCurrentSection(scrollUp)
        this.scrollAction()
      }
    },

    // 현재 섹션 계산
    calculateCurrentSection(isGoUp) {
      if (!isGoUp && this.activeSection < this.totalSection) {
        this.activeSection++
      } else if (isGoUp && this.activeSection > 0) {
        this.activeSection--
      }
    },

    // 스크롤 실행
    scrollAction(idx) {
      if (idx || idx === 0) this.activeSection = idx
      this.inMove = true
      document.documentElement.scrollTo({ top: this.offsets[this.activeSection], behavior: 'smooth' })

      setTimeout(() => {
        this.inMove = false
      }, 400)
    },

    compareScrollTopWithOffsets(scrollTop, offsets) {
      for (let i = offsets.length - 1, lst = 0; i >= lst; i--) {
        if (offsets[i] - 100 <= scrollTop) return i
      }
    },

    scrollHandler(arg) {
      const index = this.compareScrollTopWithOffsets(document.documentElement.scrollTop, this.offsets)

      if ([0, 2].includes(index)) {
        this.appHeader.classList.remove('transparent')
        this.appHeader.classList.add('black')
      } else if (index === 1) {
        this.appHeader.classList.remove('black')
        this.appHeader.classList.add('transparent')
      } else {
        this.appHeader.classList.remove('black')
        this.appHeader.classList.remove('transparent')
      }

      if (index === 1) {
        this.appHeader.classList.add('white-glob-menu')
      } else {
        this.appHeader.classList.remove('white-glob-menu')
      }
    }
  },
  created() {},
  mounted() {
    this.appHeader = document.querySelector('.wf__header')
    this.appHeader.classList.add('black')
    this.appHeader.classList.add('fixed')

    this.throttleForResizeEvent = throttle(this.calculateSectionOffsets)
    this.throttleForScroll = throttle(this.scrollHandler, 200)

    this.calculateSectionOffsets()

    window.addEventListener('resize', this.throttleForResizeEvent)
    window.addEventListener('scroll', this.throttleForScroll)
    window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }) // Other browsers

    this.handleMouseWheel({ wheelDelta: 100, preventDefault: () => {} }) // 화면 새로고침 시 스크롤 맨 위로 이동

    document.body.classList.add('hide-scroll')
  },
  unmounted() {
    this.appHeader.classList.remove('black')
    this.appHeader.classList.remove('transparent')
    this.appHeader.classList.remove('white-glob-menu')
    this.appHeader.classList.remove('fixed')

    window.removeEventListener('resize', this.throttleForResizeEvent)
    window.removeEventListener('scroll', this.throttleForScroll)
    window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false }) // Other browsers

    document.body.classList.remove('hide-scroll')
  }
}
</script>

<template>
  <section class="wf__home" ref="homeSection">
    <PopupWrapperVue />

    <section class="wf__home-section">
      <HomeContent1></HomeContent1>
    </section>

    <section class="wf__home-section" :style="{ 'min-height': '1000px' }">
      <HomeContent2></HomeContent2>
    </section>

    <section class="wf__home-section">
      <HomeContent3></HomeContent3>
    </section>

    <!-- <section class="wf__home-section">
      <HomeContent4></HomeContent4>
    </section> -->

    <section class="wf__home-section">
      <HomeContent5></HomeContent5>
    </section>

    <HomeSectionNavigatorVue @scrollAction="scrollAction" :section="this.activeSection" />
  </section>
</template>

<style scoped>
.wf__home {
  position: relative;
}
</style>
