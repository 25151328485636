//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  name: '',
  components: {},
  props: ['numbers'],
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <div class="wf__content">
    <div class="wf__intro">
      <div class="wf__intro-title">
        {{ LanguageConverter({ en: 'New technology', kr: '새로운 기술' }) }}
        <div class="wf__intro-title--bold">{{ LanguageConverter({ en: 'Starts With PromisingLabs', kr: '유망에서 시작하다' }) }}</div>
      </div>
    </div>

    <div class="wf__number-wrapper">
      <div class="wf__number-text">
        <div class="wf__rotate">{{ LanguageConverter({ en: 'Fields', kr: '기술분야' }) }}</div>

        <span class="wf__number">{{ numbers[0] }}</span>
        {{ LanguageConverter({ en: 'fields', kr: '개 분야' }) }}
      </div>

      <div class="wf__number-text">
        <div class="wf__rotate">{{ LanguageConverter({ en: 'Cooperations', kr: '기업협력' }) }}</div>

        <span class="wf__number">{{ numbers[1] }}</span>
        {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf__content {
  padding: var(--wf-text-40) 0;
  font-size: var(--wf-text-10);

  border: 2px solid #fff;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: space-between;
}
.wf__intro {
  font-size: var(--wf-text-20);
}
.wf__intro-title--bold {
  font-weight: 700;
}

.wf__rotate {
  rotate: -90deg;
  translate: -9em -1em;
}

.wf__number-wrapper {
  display: flex;
  flex-direction: column;
}
.wf__number {
  font-size: 7rem;
  font-weight: 700;
  color: #28e5d5;

  line-height: 1.2;
}
</style>
