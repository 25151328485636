// axios 참고
// https://inpa.tistory.com/entry/AXIOS-%F0%9F%93%9A-%EC%84%A4%EC%B9%98-%EC%82%AC%EC%9A%A9

import axios from 'axios'

export const getToken = () => {
  const token = localStorage.getItem('token')
  const tokenType = localStorage.getItem('tokenType')
  return {
    headers: {
      Authorization: `${tokenType} ${token}`
    }
  }
}

export const useAxios = async (url, opt = {}, useToken = true) => {
  for (const key in opt.data) {
    const value = opt.data[key]
    if (typeof value === 'string') opt.data[key] = value.replace(/'/g, "\\'")
  }

  const result = await axios(url, useToken ? { ...opt, ...getToken() } : opt).catch((err) => err)

  if (result.response && typeof result.response.data === 'string' && result.response.data.includes('csrf')) {
    const response = await axios.get('/v1/csrf')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken
    return await useAxios(url, opt, useToken)
  }

  if (result.status === 200) return result.data
  return result.response
}
