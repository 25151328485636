export default [
	{
		path: '/admin/board/paper', // 논문 게시판
		name: 'adminBoardPaper',
		component: () => import('../views/admin/board/paper/Paper.vue'),
		children: [
			// 논문 게시판 리스트
			{
				path: ':labNo?/:page?',
				name: 'adminBoardPaperList',
				component: () => import('../views/admin/board/paper/PaperList.vue')
			},
			// 논문 게시판 등록
			{
				path: 'w',
				name: 'adminBoardPaperCreate',
				component: () => import('../views/admin/board/paper/PaperCreate.vue')
			},
			// 논문 게시판 보기, 수정
			{
				path: 'w/:id/:page?/:labNo?',
				name: 'adminBoardPaperUpdate',
				component: () => import('../views/admin/board/paper/PaperUpdate.vue')
			}
		]
	}
]
