<script>
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Autoplay } from 'swiper'

import NewsSliderPagerVue from '@/components/Home/Content2/NewsSliderPager.vue'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { Swiper, SwiperSlide, NewsSliderPagerVue },
  props: ['noticeDataList'],
  data() {
    return {
      dataListLength: 0,
      swiper: null,
      modules: [Autoplay]
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.dataListLength = this.noticeDataList.length
    this.swiper = this.$refs.swiper.$el.swiper
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    goNotice(id) {
      const url = '/community/notice'
      this.$router.push(id ? `${url}/${id}` : url)
    }
  }
}
</script>

<template>
  <div class="wf__notice">
    <div class="wf__notice-title-wrapper">
      <div>
        <div class="wf__notice-subtitle">NOTICE</div>
        <div class="wf__notice-title">{{ LanguageConverter({ en: 'Notice', kr: '공지사항' }) }}</div>
      </div>

      <span class="wf__notice-more-link material-icons-outlined" @click="goNotice()"> expand_circle_down </span>
    </div>

    <div class="wf__notice-item-wrapper">
      <swiper
        :speed="1000"
        :slides-per-view="1"
        :loop="true"
        :autoplay="{
          delay: 1000,
          disableOnInteraction: false
        }"
        :modules="modules"
        ref="swiper"
      >
        <swiper-slide class="cursor-pointer" v-for="(notice, i) in noticeDataList" :key="i" @click="goNotice(notice.noticeId)">
          <div class="slide-item">
            {{ notice.title }}
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="wf__remarkable-pager-wrapper">
      <NewsSliderPagerVue
        v-if="swiper"
        :log="true"
        :slideLength="dataListLength"
        :swiper="swiper"
        :reverseColor="true"
      ></NewsSliderPagerVue>
    </div>
  </div>
</template>

<style scoped>
.wf__notice {
  display: flex;
  flex-direction: column;

  background-color: var(--wf-primary-color);
  color: #fff;
  min-height: 190px;

  padding: 19px 20.5px;
}
.wf__notice-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #fff;

  padding-bottom: 12px;
}
.wf__notice-subtitle {
  font-size: var(--wf-text-14);
  font-weight: 300;
}
.wf__notice-title {
  font-size: var(--wf-text-24);
  font-weight: 600;
  line-height: 1.3;
}
.wf__notice-more-link {
  font-size: var(--wf-text-18) !important;
  cursor: pointer;
  rotate: 270deg;
}
.wf__notice-item-wrapper {
  font-size: var(--wf-text-16);
  flex: 1;
  margin-top: 20px;
}
.slide-item {
  width: 100%;
  text-align: left;
}
.wf__remarkable-pager-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
