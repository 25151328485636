import KyungPookUniversity from './경북대학교.png'
import Digst from './dgist.png'
import KeimyngUniversity from './계명대학교.png'
import DaeguUniversity from './대구대학교.png'
import YoungNam from './영남대학교.png'
import DaeguCatholic from './대구가톨릭대학교.png'
import DaeguHannyUniversity from './한의대학교.png'
import KIU from './경일대학교.png'
import AndongNational from './안동대학교.png'

import SmallKyungPookUniversity from './small/경북대학교.png'
import SmallDigst from './small/dgist.png'
import SmallKeimyngUniversity from './small/계명대학교.png'
import SmallDaeguUniversity from './small/대구대학교.png'
import SmallYoungNam from './small/영남대학교.png'
import SmallDaeguCatholic from './small/대구가톨릭대학교.png'
import SmallDaeguHannyUniversity from './small/한의대학교.png'
import SmallKIU from './small/경일대학교.png'
import SmallAndongNational from './small/안동대학교.png'

export default [
  KyungPookUniversity,
  KIU,
  KeimyngUniversity,
  DaeguCatholic,
  Digst,
  DaeguUniversity,
  DaeguHannyUniversity,
  AndongNational,
  YoungNam
]

const SmallLogos = [
  SmallKyungPookUniversity,
  SmallKIU,
  SmallKeimyngUniversity,
  SmallDaeguCatholic,
  SmallDigst,
  SmallDaeguUniversity,
  SmallDaeguHannyUniversity,
  SmallAndongNational,
  SmallYoungNam
]

export {
  KyungPookUniversity,
  Digst,
  KeimyngUniversity,
  DaeguUniversity,
  YoungNam,
  DaeguCatholic,
  DaeguHannyUniversity,
  KIU,
  AndongNational,
  SmallLogos
}
