<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  props: {
    isOpenMobileMenu: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array
    }
  },
  data() {
    return {
      childMenus: []
    }
  },
  computed: {
    isOpen() {
      return this.isOpenMobileMenu
    },
    loginUserInfo() {
      const user = this.$store.state.userStore.info
      return user?.groupName === 'bot' ? null : user
    }
  },
  created() {},
  mounted() {
    this.childMenus = this.$el.querySelectorAll('.wf__header-item-dropdown')
    this.setHeightProperty(this.childMenus, '--height-itself')
    for (const menu of this.childMenus) {
      this.toggleDropdown(menu, true)
    }
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    goLink(link) {
      if (link === 'showModal') {
        return this.$emit('showModal')
      }
      this.$router.push(link)
    },
    setHeightProperty(targetList, stylePropertyName) {
      for (const target of targetList) {
        const height = target.clientHeight
        target.style.setProperty(stylePropertyName, `${height}px`)
      }
    },
    toggleDropdown(target, isClose) {
      if (isClose === true) {
        target.classList.remove('open')
        target.classList.add('collapsed')
      } else if (isClose === false) {
        target.classList.add('open')
        target.classList.remove('collapsed')
      } else {
        target.classList.toggle('open')
        target.classList.toggle('collapsed')
      }
    },
    logout(e) {
      this.$emit('logout', e)
    },
    menuWrapperClickHandler(e) {
      const target = e.target

      if (target.matches('.wf__header-mobile-menu-item-title')) {
        return this.openChildMenus(target.parentElement)
      }
      this.$emit('toogleMobileMenu')
    },
    openChildMenus(parent) {
      const childMenuWrapper = parent.querySelector('.wf__header-item-dropdown')
      const opendChildMenuWrapper = parent.parentElement.querySelector('.wf__header-item-dropdown.open')

      this.toggleDropdown(childMenuWrapper)
      if (opendChildMenuWrapper) this.toggleDropdown(opendChildMenuWrapper, true)
    }
  }
}
</script>

<template>
  <aside class="wf__header-mobile-menu" :class="isOpen ? 'open' : ''" @click="menuWrapperClickHandler">
    <div class="wf__header-mobile-menu-wrapper">
      <div class="wf__header-mobile-menu-item-list">
        <div>
          <div class="wf__header-mobile-menu-item" v-for="(menu, i) in menuList" :key="i">
            <div class="wf__header-mobile-menu-item-title">
              {{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}
            </div>

            <ul v-if="menu.children.length !== 0" class="wf__header-item-dropdown">
              <li v-for="(child, j) in menu.children" :key="j" @click="goLink(child.path)">
                {{ LanguageConverter({ en: child.nameEn, kr: child.name }) }}
              </li>
            </ul>

            <ul v-else class="wf__header-item-dropdown">
              <li @click="goLink(menu.path)">
                {{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="wf__header-mobile-menu-divider"></div>

      <div class="wf__header-mobile-menu-bottom">
        <div class="uppercase" href="#" @click="$emit('toggleEng')">{{ LanguageConverter({ en: 'kor', kr: 'eng' }) }}</div>

        <div class="wf__header-mobile-menu-login">
          <router-link v-if="!loginUserInfo" class="" to="/member/login"><span class="material-icons-outlined"> login </span></router-link>
          <div v-if="loginUserInfo" class="" @click="logout"><span class="material-icons-outlined"> logout </span></div>
        </div>
      </div>
    </div>
  </aside>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__header-mobile-menu {
  position: fixed;
  top: 0;

  display: flex;

  width: 100vw;
  height: 100vh;

  background-color: transparent;
  backdrop-filter: blur(4px);

  transition: all 0.3s ease-in-out;
  transform: translateX(-100vw);
}

.wf__header-mobile-menu.open {
  transform: translateX(0);
}
.wf__header-mobile-menu-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 2em;

  width: 100vw;
  max-width: 30em;
  color: var(--wf-misty-color);
  padding: 2em;
  padding-top: 100px;
  background-color: rgba(0, 0, 0, 0.8);
}
.wf__header-mobile-menu-item-list {
  overflow-y: scroll;
}
.wf__header-mobile-menu-item-list > div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.wf__header-mobile-menu-item {
  overflow: hidden;
}

.wf__header-mobile-menu-item-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 1em;
  /* border-bottom: 1px solid var(--wf-secondary-color); */
}

.wf__header-mobile-menu-bottom {
  font-size: var(--wf-text-14);
}
.wf__header-item-dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  font-size: 16px;
  font-weight: 300;
  margin-left: 1.5em;

  transition: all 0.4s ease;
}
.wf__header-item-dropdown.collapsed {
  height: 0px;
}
.wf__header-item-dropdown.open {
  height: var(--height-itself);
}
.wf__header-mobile-menu-divider {
  height: 1px;
  background-color: var(--wf-misty-color);
}
.wf__header-mobile-menu-login {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}
.wf__header-mobile-menu-login a {
  color: var(--wf-misty-color);
}
</style>
