<script>
import LanguageConverter from '@/util/LanguageConverter'
export default {
  name: '',
  components: {},
  data() {
    return {
      menuList: [],
      closure: null
    }
  },
  computed: {
    loginUserInfo() {
      const user = this.$store.state.userStore.info
      return user?.groupName === 'bot' ? null : user
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.menuList = this.$store.state.menuStore.menuList
    this.clickTechTransfer = this.removeActiveClosure(this.$refs.sitemap, this.clickTechTransferFn)
  },
  unmounted() {},
  methods: {
    LanguageConverter,

    removeActive(el) {
      el.classList.remove('active')
    },

    removeActiveClosure(el, cb) {
      return (e) => {
        this.removeActive(el)
        cb(e)
      }
    },

    clickTechTransferFn(e) {
      e.preventDefault()
      this.$emit('showModal')
    }
  }
}
</script>

<template>
  <div class="wf__sitemap" ref="sitemap">
    <div class="wf__sitemap-wrapper">
      <div class="wf__area-first">
        <span class="material-icons-outlined cursor-pointer" @click="removeActive($refs.sitemap)"> close </span>
      </div>

      <ul class="wf__sitemap-menus">
        <li class="wf__sitemap-menu-item" v-for="(menu, i) in menuList" :key="i">
          <span>{{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}</span>

          <div class="wf__divider--white"></div>

          <ul v-if="menu.children.length !== 0" class="wf__sitemap-menu-item-child">
            <li v-for="(child, j) in menu.children" :key="j">
              <a v-if="menu.path === 'showModal'" href="#" @click="clickTechTransferFn">
                {{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}
              </a>
              <router-link v-if="menu.path !== 'showModal'" :to="child.path" @click="removeActive($refs.sitemap)">
                {{ LanguageConverter({ en: child.nameEn, kr: child.name }) }}
              </router-link>
            </li>
          </ul>
          <ul v-else class="wf__sitemap-menu-item-child">
            <li>
              <a v-if="menu.path === 'showModal'" href="#" @click="clickTechTransferFn">
                {{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}
              </a>
              <router-link v-if="menu.path !== 'showModal'" :to="menu.path" @click="removeActive($refs.sitemap)">
                {{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>

      <div class="wf__sitemap-cards">
        <router-link class="wf__link-card" to="/integratedSearch" @click="removeActive($refs.sitemap)">
          <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Search', kr: '통합검색' }) }}</span>
          <span class="wf__link-card-desc">
            {{ LanguageConverter({ en: 'View desired search results', kr: '내가 원하는 검색결과 바로보기' }) }}
          </span>
        </router-link>

        <router-link class="wf__link-card" to="/researchField" @click="removeActive($refs.sitemap)">
          <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Lab', kr: '연구분야별 검색' }) }}</span>
          <span class="wf__link-card-desc">
            {{ LanguageConverter({ en: 'Search by lab, department, or research field', kr: '연구실별, 기관별, 연구분야별 검색' }) }}
          </span>
        </router-link>

        <a class="wf__link-card cursor-pointer" to="/" @click="clickTechTransfer">
          <span class="wf__link-card-title">{{ LanguageConverter({ en: 'Tech Cooperation', kr: '연구실협력신청' }) }} </span>
          <span class="wf__link-card-desc"> {{ LanguageConverter({ en: 'Go To Tech Cooperation', kr: '연구실협력신청 바로가기' }) }}</span>
        </a>
      </div>

      <div class="wf__area-second">
        <div class="wf__divider--white"></div>

        <router-link v-if="['admin', 'lab'].includes(loginUserInfo?.groupName)" to="/admin" @click="removeActive($refs.sitemap)">
          {{ LanguageConverter({ en: 'ADMIN', kr: '관리자메뉴' }) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wf__sitemap {
  letter-spacing: 0px;
  position: fixed;
  top: var(--wf-header-height);
  left: 0;

  width: 100vw;
  height: 0;

  background-color: rgba(0, 0, 0, 0.89);

  overflow: hidden;

  z-index: 999;

  transition: height 0.5s ease-in-out;
}

.wf__sitemap,
.wf__sitemap a {
  color: #fff;
  font-size: var(--wf-text-16);
}

.wf__area-first {
  display: flex;
  justify-content: flex-end;
}
.wf__area-second {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1em;

  margin-bottom: 3em;
}

.wf__sitemap.active {
  height: calc(100vh - var(--wf-header-height));
}
.wf__sitemap-wrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  margin: 0 15vw;
  height: calc(100vh - var(--wf-header-height));
}

.wf__sitemap-menus {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 5vw;
  row-gap: 10vh;

  margin-bottom: 3em;
}
.wf__sitemap-menu-item > span {
  display: inline-block;
  font-size: var(--wf-text-24);
  font-weight: 600;

  padding-bottom: 0.5em;

  border-bottom: 3px solid #fff;
}
.wf__sitemap-menu-item-child {
  font-size: var(--wf-text-20);
  font-weight: 300;
  margin-top: 1em;
}
.wf__sitemap-menu-item-child > li > a {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
}
.wf__sitemap-menu-item-child > li > a:hover::after {
  content: '';
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  background-color: #fff;

  border-radius: 50%;
  margin-left: 0.5em;
}
.wf__sitemap-menu-item-child > li > a:active {
  color: var(--wf-primary-color);
}
.wf__sitemap-menu-item-child > li > a:active::after {
  background-color: var(--wf-primary-color);
}

.wf__sitemap-cards {
  display: flex;
  gap: 3em;
  margin-bottom: 2em;
}
.wf__link-card {
  flex: 1;

  transition: all 0.2s ease-in;
}
.wf__link-card:hover {
  opacity: 0.75;
}
</style>
