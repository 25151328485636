//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  data() {
    return {
      navigators: []
    }
  },
  props: ['section'],
  emits: ['scrollAction'],
  // watch: 변경 여부를 감지할 대상과 같은 이름으로 method 생성
  watch: {
    section() {
      const currentNavi = this.navigators[this.section]
      if (!currentNavi) return

      this.$refs.naviWrapper.querySelector('.active').classList.remove('active')
      currentNavi.classList.add('active')

      if (this.section >= 3) {
        this.$refs.naviWrapper.classList.add('black')
      } else {
        this.$refs.naviWrapper.classList.remove('black')
      }
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.navigators = this.$refs.naviWrapper.children
    this.navigators[this.section].classList.add('active')
  },
  unmounted() {},
  methods: {
    LanguageConverter,
    go(e) {
      const target = e.target.matches('div') ? e.target : e.target.parentElement
      this.$emit('scrollAction', Number(target.dataset.idx))
    }
  }
}
</script>

<template>
  <div class="wf__home-progress" ref="naviWrapper">
    <div class="wf__home-progress-circle active" data-idx="0" @click="go">
      <span class="wf__home-progress-circle-text">Home</span>
    </div>
    <div class="wf__home-progress-circle" data-idx="1" @click="go">
      <span class="wf__home-progress-circle-text">{{ LanguageConverter({ en: 'Community', kr: '유망연구실소식' }) }}</span>
    </div>
    <div class="wf__home-progress-circle" data-idx="2" @click="go">
      <span class="wf__home-progress-circle-text">{{ LanguageConverter({ en: 'Accomplishments', kr: '유망연구실성과' }) }}</span>
    </div>
    <div class="wf__home-progress-circle" data-idx="3" @click="go">
      <span class="wf__home-progress-circle-text">{{ LanguageConverter({ en: 'Performances', kr: '유망연구실실적' }) }}</span>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}

.wf__home-progress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 1em;

  position: fixed;
  bottom: 34vh;
  right: 65px;

  z-index: 99999;
}

.wf__home-progress::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0%;

  transform: translateX(300%);

  width: 3px;
  height: 100%;
  background-color: var(--wf-misty-color);

  z-index: -2;
}

.wf__home-progress-circle {
  position: relative;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  background-color: var(--wf-misty-color);

  cursor: pointer;
  transition: all 0.4s ease;
}
.wf__home-progress-circle-text {
  position: absolute;
  top: 0;
  right: 0;

  color: var(--wf-misty-color);
  transform: translate(5%, -10%);
  display: block;
  width: max-content;

  border-radius: 999px;
  padding-left: 0.5em;
  padding-right: 2em;

  z-index: -1;
  transition: all 0.4s ease;
}

.wf__home-progress-circle.active {
  background-color: var(--wf-primary-color);
}
.wf__home-progress-circle.active .wf__home-progress-circle-text {
  background-color: var(--wf-misty-color);
  color: var(--wf-black-color);
}

.wf__home-progress.black .wf__home-progress-circle,
.wf__home-progress.black::before {
  background-color: var(--wf-black-color);
}

.wf__home-progress.black .wf__home-progress-circle.active {
  background-color: var(--wf-misty-color);
}
.wf__home-progress.black .wf__home-progress-circle .wf__home-progress-circle-text {
  color: var(--wf-black-color);
}

.wf__home-progress.black .wf__home-progress-circle.active .wf__home-progress-circle-text {
  background-color: var(--wf-black-color);
  color: var(--wf-misty-color);
}

@media screen and (max-width: 1721px) {
  .wf__home-progress {
    display: none;
  }
}
</style>
