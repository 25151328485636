<script>
import DefaultImg from '@/assets/images/HomeBoardDefault.jpg'
import DefaultImg2 from '@/assets/images/001.jpg'
import DefaultImg3 from '@/assets/images/002.jpg'
import DefaultImg4 from '@/assets/images/003.jpg'
import DefaultImg5 from '@/assets/images/004.jpg'

import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Autoplay } from 'swiper'

export default {
  components: { Swiper, SwiperSlide },
  props: ['slideList'],
  data() {
    return {
      defaultImgArray: [DefaultImg, DefaultImg2, DefaultImg3, DefaultImg4, DefaultImg5],
      slidesPerView: 2.8,
      modules: [Autoplay]
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$el.swiper
    }
  },
  setup() {},
  created() {},
  mounted() {
    const observer = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.contentRect.width <= 992) {
          this.slidesPerView = 1
        } else {
          this.slidesPerView = 2.8
        }
      }
    })

    observer.observe(this.swiper.el)
  },
  unmounted() {},
  methods: {
    setDefaultImg(index) {
      return this.defaultImgArray[index % this.defaultImgArray.length]
    },
    goNews(id) {
      this.$router.push(`/community/patent/${id}`)
    }
  }
}
</script>

<template>
  <swiper
    :speed="1200"
    :slides-per-view="slidesPerView"
    :spaceBetween="28"
    :loop="true"
    :autoplay="{
      delay: 1500,
      disableOnInteraction: false
    }"
    :modules="modules"
    ref="swiper"
  >
    <swiper-slide class="cursor-pointer" v-for="(slide, i) in slideList" :key="i" @click="goNews(slide.patentId)">
      <div class="carousel__item" :style="{ 'background-image': `url(${setDefaultImg(i)})` }">
        <div v-if="!slide.thumbnail" class="carousel__item-overlay"></div>
        <h4 class="carousel__item-title">
          {{ slide.title }}
          <ul class="carousel__item-title--desc">
            <li v-for="(desc, j) in slide.descList" :key="j">{{ desc }}</li>
          </ul>
        </h4>
      </div>
    </swiper-slide>
  </swiper>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.carousel__item {
  position: relative;
  height: 360px;
  background-position: center;
  background-size: cover;
  color: #fff;
}

.carousel__item-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.55;
}
.carousel__item-title {
  position: absolute;
  bottom: 16px;
  left: 24px;
  font-weight: 600;
  font-size: 24px;

  width: 90%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel__item-title--desc {
  padding-top: 1em;
  display: flex;
  font-size: var(--wf-text-14);
  font-weight: 400;
  color: var(--wf-gray-500);
}
.carousel__item-title--desc li {
  white-space: nowrap;
  overflow: hidden;
}
.carousel__item-title--desc li:not(:last-child)::after {
  content: '/';
  padding: 0.5em;
}
</style>
