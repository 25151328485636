//TODO: 등록된 연구실 수, 등록된 학교 수
<script>
import LanguageConverter from '@/util/LanguageConverter'
import { replaceByDefaultProfile } from '@/util/defaultImg'
import { createObjectURL } from '@/api/fileAPI'

export default {
  props: ['labList'],
  data() {
    return {
      imgUrl: '',
      numberFormatter: new Intl.NumberFormat(), // 숫자 3자리 콤마
      timeout: null, // mounted 시 작동할 timeout,
      randomLabInterval: null,
      lab: null
    }
  },
  methods: {
    LanguageConverter,
    // 숫자 count up 함수
    countUp(target, maxCount) {
      let now = maxCount

      // 0.05초에 한 번 씩 동작
      const handle = setInterval(() => {
        // 원하는 DOM Element의 textContent에 계산한 수 출력 (Math.ceil => 올림)
        target.textContent = this.numberFormatter.format(Math.ceil(maxCount - now))

        // 현재 계산 값이 1보다 작을때 interval 종료
        if (now < 1) return clearInterval(handle)

        const step = now / 10
        now -= step
      }, 50)
    },

    // 랜덤 연구실 정보
    getRandomLab() {
      const randomNum = Math.floor(Math.random() * this.labList.length)
      this.lab = this.labList[randomNum] ?? null

      const imageFileName = this.lab.leadResearcherImgUrl?.split('/').at(-1)

      createObjectURL(imageFileName, 'lab', this.lab.labNo).then((item) => {
        this.imgUrl = item
      })
    },

    // 연구실 이동
    goLab() {
      this.$router.push(`/lab/${this.lab.labNo}`)
    },

    replaceByDefaultProfile
  },
  mounted() {
    // 연구실 정보가 조회되지 않을 경우 0.2초에 한 번씩 연구실 정보 조회 실행
    this.randomLabInterval = setInterval(() => {
      if (this.lab) return clearInterval(this.randomLabInterval)
      this.getRandomLab()
    }, 200)
  },
  unmounted() {
    // interval 종료
    clearInterval(this.randomLabInterval)
  }
}
</script>

<template>
  <section class="wf__animate-section">
    <div class="wf__animate-head animate__animated animate__fadeIn">
      <h2 class="wf__animate-head-subtitle">
        {{ LanguageConverter({ en: 'Labs And Companies', kr: '연구실과 기업' }) }}
      </h2>
      <h1 class="wf__animate-head-title">
        {{ LanguageConverter({ en: 'Find Each Other', kr: '서로를 발견하다' }) }}
      </h1>
    </div>

    <div class="wf__animate-body" v-if="lab">
      <div class="wf__animate-body-content first animate__animated animate__bounceIn">
        <img class="wf__animate-body-img" :src="imgUrl" alt="" @error="replaceByDefaultProfile" />
        <span class="wf__animate-body-labName">{{ LanguageConverter({ en: lab.labNameEn, kr: lab.labName }) }}</span>
        <span class="wf__animate-body-leadResearcherName">{{
          LanguageConverter({ en: lab.leadResearcherNameEn, kr: lab.leadResearcherName })
        }}</span>
        <div class="wf__animate-body-divider"></div>
        <span class="wf__animate-body-label">{{ LanguageConverter({ en: lab.introductionEn, kr: lab.introduction }) }}</span>
      </div>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__animate-section {
  display: flex;

  position: relative;

  width: 75%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;

  color: var(--wf-misty-color);
}

.wf__animate-head {
  margin-right: 40px;
  animation-duration: 0.4s;
  animation-delay: 0.8s;
}
.wf__animate-head-subtitle {
  font-size: 70px;
  font-weight: 700;
  color: var(--wf-primary-color);
}
.wf__animate-head-title {
  font-size: 80px;

  font-weight: 900;
  color: transparent;
  -webkit-text-stroke: 2px var(--wf-misty-color);
}

.wf__animate-body {
  min-width: 340px;
  max-width: 340px;

  min-height: 525px;
  max-height: 525px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 4rem;
}
.wf__animate-body-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  animation-duration: 0.8s;
}
.wf__animate-body-content.first {
  animation-delay: 0.4s;
}
.wf__animate-body-content.second {
  animation-delay: 0.6s;
}
.wf__animate-body-label {
  font-size: var(--wf-text-18);
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 6; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;

  text-align: left;
}

.wf__animate-body-labName {
  font-size: var(--wf-text-20);
  font-weight: 400;
  color: var(--wf-primary-color);
  margin-top: 2rem;
}
.wf__animate-body-leadResearcherName {
  font-size: var(--wf-text-48);
  font-weight: 700;
  line-height: 1em;

  color: #fff;
}
.wf__animate-body-divider {
  width: 2.2em;
  height: 3px;
  margin: 2rem 0;
  background-color: #fff;
}
.wf__animate-body-img {
  min-width: 140px;
  min-height: 180px;

  max-width: 140px;
  max-height: 180px;

  object-fit: cover;
  object-position: center;
}
.wf__animate-body-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--wf-primary-color);
  line-height: 0.6;
}
.wf__animate-body-number .number-view {
  font-size: 10rem;
}

.wf__animate-tail {
  flex: 3;

  position: relative;
}
</style>
