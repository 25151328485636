//INFO: 화면설명
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  props: {
    maxNumberList: {
      type: Array
    }
  },
  data() {
    return {
      numberFormatter: new Intl.NumberFormat(), // 숫자 3자리 콤마
      timeout: null // mounted 시 작동할 timeout
    }
  },
  setup() {},
  created() {},

  methods: {
    LanguageConverter,
    // 숫자 count up 함수
    countUp(target, maxCount) {
      let now = maxCount

      // 0.05초에 한 번 씩 동작
      const handle = setInterval(() => {
        // 원하는 DOM Element의 textContent에 계산한 수 출력 (Math.ceil => 올림)
        target.textContent = this.numberFormatter.format(Math.ceil(maxCount - now))

        // 현재 계산 값이 1보다 작을때 interval 종료
        if (now < 1) return clearInterval(handle)

        const step = now / 10
        now -= step
      }, 50)
    }
  },
  mounted() {
    // 0.4초 뒤 countUp 함수 실행
    this.timeout = setTimeout(() => {
      this.countUp(this.$refs.countPjt, this.maxNumberList[0])
      this.countUp(this.$refs.countPaper, this.maxNumberList[1])
      this.countUp(this.$refs.countPatent, this.maxNumberList[2])
    }, 400)
  },
  unmounted() {
    // timeout 종료
    clearTimeout(this.timeout)
  }
}
</script>

<template>
  <section class="wf__animate-section">
    <div class="wf__animate-head animate__animated animate__fadeInDown">
      <div class="wf__animate-head-title">
        <span class="bold">{{ LanguageConverter({ en: 'New Knowledge Stretching', kr: '유망에서부터 뻗어가는' }) }}</span>
        <br />
        {{ LanguageConverter({ en: 'From PromisingLabs', kr: '새로운 지식' }) }}
      </div>
    </div>

    <div class="wf__animate-body animate__animated animate__zoomIn">
      <div class="wf__animate-body-desc">
        {{ LanguageConverter({ en: 'Meet Wide and Various', kr: '폭넓게, 다양하게 얻을 수 있는' }) }}

        <span class="wf__font-bold">
          {{ LanguageConverter({ en: 'PromisingLabs Knowledges', kr: '유망연구실의 지식자료' }) }}
        </span>
      </div>
      <div class="wf__animate-body-content-list">
        <div class="wf__animate-body-content first animate__animated animate__zoomIn">
          <span class="wf__animate-body-label project">
            {{ LanguageConverter({ en: 'Project', kr: '프로젝트' }) }}
          </span>
          <span class="wf__animate-body-number"> <span ref="countPjt"></span> </span>
          <span class="wf__animate-body-tail">
            {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
          </span>
        </div>
        <div class="wf__animate-body-content second animate__animated animate__zoomIn">
          <span class="wf__animate-body-label paper">
            {{ LanguageConverter({ en: 'Paper', kr: '논문' }) }}
          </span>
          <span class="wf__animate-body-number"> <span ref="countPaper"></span> </span>
          <span class="wf__animate-body-tail">
            {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
          </span>
        </div>
        <div class="wf__animate-body-content third animate__animated animate__zoomIn">
          <span class="wf__animate-body-label patent">
            {{ LanguageConverter({ en: 'Patent', kr: '지재권' }) }}
          </span>
          <span class="wf__animate-body-number"> <span ref="countPatent"></span> </span>
          <span class="wf__animate-body-tail">
            {{ LanguageConverter({ en: 'cases', kr: '건' }) }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__animate-section {
  position: absolute;
  top: 30%;
  left: 28vw;
  text-align: left;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75vw;
  color: var(--wf-misty-color);
  padding-right: 56px;
}

.wf__animate-head {
  font-size: 80px;
  font-weight: 400;
  line-height: 1.1em;
  align-self: flex-end;
  animation-delay: 0.4s;
}

.wf__animate-body {
  margin-top: 7%;
  animation-delay: 0.8s;
}
.wf__animate-body-desc {
  font-size: var(--wf-text-24);
}
.wf__animate-body-content-list {
  display: flex;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin-top: 1em;
}
.wf__animate-body-content {
  margin-left: 6rem;
  position: relative;

  animation-duration: 0.6s;
}
.wf__animate-body-content.first {
  animation-delay: 1.4s;
}
.wf__animate-body-content.second {
  animation-delay: 1.7s;
}
.wf__animate-body-content.third {
  animation-delay: 2s;
}

.wf__animate-body-label {
  position: absolute;
  top: 2em;
  left: -3em;

  width: max-content;

  font-size: var(--wf-text-20);
  font-weight: 400;
  transform: rotate(-90deg);
}
.wf__animate-body-label.paper {
  top: 1.2em;
  left: -2em;
}
.wf__animate-body-label.patent {
  top: 1.5em;
  left: -2.4em;
}
.wf__animate-body-tail {
  font-size: 24px;
  font-weight: 700;
}
.wf__animate-body-number {
  font-size: 92px;
  color: #28e5d5;
  font-weight: 700;
  line-height: 1.2em;
}

.wf__animate-head-title .bold {
  font-weight: 700;
}
.wf__animate-head-title {
  color: var(--wf-misty-color);
  font-weight: 200;
  font-size: 70px;
}
/*
.wf__animate-head {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  left: 28%;
}

.wf__animate-head-title {
  position: absolute;
  bottom: -380%;
  right: 35%;

  color: transparent;
  -webkit-text-stroke: 1px var(--wf-misty-color);

  font-size: 5rem;
  font-weight: bold;

  transform: rotate(90deg);

  animation-name: slideUp;
  animation-duration: 0.6s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.wf__animate-body {
  position: relative;
  flex: 5;
  color: var(--wf-misty-color);
}

.wf__animate-body-content.first {
  animation-duration: 0.6s;
  animation-delay: 0.6s;
}
.wf__animate-body-content.second {
  animation-duration: 0.6s;
  animation-delay: 1s;
}
.wf__animate-body-content.third {
  animation-duration: 0.6s;
  animation-delay: 1.4s;
}
.wf__animate-body-label {
  font-size: 2rem;
}
.wf__animate-body-number {
  font-size: 127px;
  color: #28e5d5;
  font-weight: 700;
}

@keyframes slideUp {
  from {
    bottom: -380%;
  }

  to {
    bottom: -90%;
  }
} */
</style>
