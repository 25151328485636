<template>
  <div ref="view"></div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer'
import FileAPI, { createObjectURL } from '@/api/fileAPI'

export default {
  components: {},
  props: ['propsData', 'boardData', 'width', 'height'],
  data() {
    return {
      contents: '',
      imageAPI: new FileAPI('images'),
      htmlParser: new DOMParser()
    }
  },
  watch: {
    propsData() {
      this.viewer.setMarkdown(this.propsData)
      this.replaceImgSrc()
    }
  },
  setup() {},
  created() {
    // 컴포넌트 생성시 props data가 있으면 viewer에 propsData의 Content를 보여준다.
    if (this.propsData) {
      this.contents = typeof this.propsData === 'string' ? this.propsData : this.propsData.content
    }
  },
  async mounted() {
    if (this.width) this.$refs.view.style.width = this.width
    if (this.height) this.$refs.view.style.height = this.height

    this.viewer = new Viewer({
      el: this.$refs.view,
      initialValue: this.contents // 초기에 propsData의 Content를 보여준다.
    })

    await this.replaceImgSrc()
  },
  methods: {
    async replaceImgSrc() {
      // 에디터 view Element select
      const editorWrapper = this.$refs.view.querySelector('.toastui-editor-contents')

      // img 태그 선택
      const imageList = editorWrapper.querySelectorAll('img:not(.ProseMirror-separator)')

      for (const image of imageList) {
        // download api를 이용하여 미리보기 url 생성
        const src = await createObjectURL(image.alt, this.boardData.boardName, this.boardData.boardId)
        image.src = src
      }

      this.imageMap = this.imageAPI._blobMap
    }
  },
  unmounted() {}
}
</script>

<style scoped>
:deep(.toastui-editor-contents) {
  font-size: var(--wf-text-18);
}
</style>
