// module store
// store.state.userStore로 접근

let labListSortByDepartment = []
const sortByDepartment = (state) => {
  if (labListSortByDepartment.length !== 0) return labListSortByDepartment
  if (!(state.labList instanceof Array)) return []

  const list = state.labList.reduce((acc, cur) => {
    const curAffiliatedInstitutionName = cur.affiliatedInstitutionName.trim()

    const idx = acc.findIndex(({ affiliatedInstitutionName }) => affiliatedInstitutionName === curAffiliatedInstitutionName)

    if (idx === -1) {
      acc.push({
        affiliatedInstitutionName: curAffiliatedInstitutionName,
        departments: [[cur.departmentName, 1]],
        counts: 1
      })
    } else {
      acc[idx].counts++
      const departments = acc[idx].departments
      const curDepartmentName = cur.departmentName.trim()

      const iidx = departments.findIndex(([departmentName]) => departmentName === curDepartmentName)

      if (iidx === -1) {
        acc[idx].departments.push([curDepartmentName, 1])
      } else {
        acc[idx].departments[iidx][1]++
      }
    }

    return acc
  }, [])

  labListSortByDepartment = list

  return list
}

export default {
  namespaced: true,
  state: {
    labList: [],
    labListSortByDepartment: [],
    labCategory: [],
    remarkableLabList: []
  },
  getters: {
    labList: (state) => state.labList,
    labListSortByDepartment: sortByDepartment,
    labCategory: (state) => state.labCategory,
    remarkableLabList: (state) => state.remarkableLabList
  },
  mutations: {
    SET_LAB_LIST(state, labList) {
      state.labList = labList
    },
    SET_LAB_CATEGORY(state, labCategory) {
      state.labCategory = labCategory
    },
    SET_REMARKABLE_LAB(state, remarkableLabList) {
      state.remarkableLabList = remarkableLabList
    }
  },
  actions: {
    LoadLabList({ commit, state }, labList) {
      commit('SET_LAB_LIST', labList)
    },
    SetLabCategory({ commit, state }, categoryList) {
      commit('SET_LAB_CATEGORY', categoryList)
    },
    SetRemarkableLabList({ commit, state }, remarkableLabList) {
      commit('SET_REMARKABLE_LAB', remarkableLabList)
    }
  }
}
