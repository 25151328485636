/* eslint-disable no-unused-vars */
import { everyPipe, pipe, memorize } from './functional'
import { Reg } from './regex'

const deleteCharByReg = (reg) => {
  return (str) => (typeof str === 'string' ? str.replaceAll(reg, '') : str)
}
const testStringByReg = (reg) => {
  return (str) => (typeof str === 'string' ? new RegExp(reg).test(str) : false)
}
const lengthBetween = (min = '', max = '') => {
  return (str) => (new RegExp(`.{${min},${max}}`).test(str) ? str : false)
}

const convertBool = (bool) => !bool

const deleteEng = deleteCharByReg(Reg.ENG)
const deleteNumber = deleteCharByReg(Reg.NUMBER)
const deleteSpecial = deleteCharByReg(Reg.SPECIAL_CHAR)
const deleteSpecialForContent = deleteCharByReg(Reg.SPECIAL_CHAR_FOR_CONTENT)
const deleteKor = deleteCharByReg(Reg.KOR)

const testEng = testStringByReg(Reg.ENG)
const testNumber = testStringByReg(Reg.NUMBER)
const testSpecial = testStringByReg(Reg.SPECIAL_CHAR)
const testEmail = testStringByReg(Reg.EMAIL)

const minLength1 = lengthBetween(1) // 최소 1자리
const maxLength10 = lengthBetween(1, 10) // 최대 10자리
const lengthBetween4And10 = lengthBetween(4, 10) // 4 ~ 10자리
const lengthBetween8And16 = lengthBetween(8, 16) // 8 ~ 16자리

const onlyNumberEng = pipe(
  deleteEng, // 영어
  deleteNumber, // 숫자
  convertBool
)

const requireEngNumberSpecial = everyPipe(
  testEng, // 영문자 포함
  testNumber, // 숫자 포함
  testSpecial // 특수문자 포함
)

const onlyEngNumberSpecial = pipe(
  deleteEng, // 영문자
  deleteNumber, // 숫자
  deleteSpecial, // 특수문자
  convertBool
)

const onlyEngKor = pipe(
  deleteEng, //
  deleteKor,
  convertBool
)

const onlyEngKorNumber = pipe(
  deleteEng, //
  deleteKor,
  deleteNumber,
  convertBool
)

const onlyEngKorNumberSpecial = pipe(
  deleteEng, //
  deleteKor,
  deleteNumber,
  deleteSpecial,
  convertBool
)

const onlyEngKorNumberSpecialForContent = pipe(
  deleteEng, //
  deleteKor,
  deleteNumber,
  deleteSpecialForContent,
  convertBool
)

export const validateRegistId = memorize(
  everyPipe(
    lengthBetween4And10, //
    onlyNumberEng
  )
)

// 비밀번호는 8~16 자리의 영문자, 숫자, (!, @, #, $, %, ^, &, *)를 포함해야합니다.
export const validateRegistPassword = memorize(
  everyPipe(
    lengthBetween8And16, //
    requireEngNumberSpecial,
    onlyEngNumberSpecial
  )
)

// 이름은 한글, 영어만 입력가능합니다.
// 소속대학교는 한글, 영어만 입력가능합니다.
export const validateEngKor = memorize(
  everyPipe(
    minLength1, //
    onlyEngKor
  )
)

// 연구실 이름은 한글, 영어, 숫자만 입력가능합니다.
export const validateRegistLabName = memorize(
  everyPipe(
    minLength1, //
    onlyEngKorNumber
  )
)

// 이메일 형식
export const validateEmail = memorize(
  everyPipe(
    minLength1, //
    testEmail
  )
)

export const validateLoginId = memorize(
  everyPipe(
    maxLength10, //
    onlyNumberEng
  )
)

export const validateLoginPassword = memorize(
  everyPipe(
    minLength1, //
    onlyEngNumberSpecial
  )
)

export const validateKorEngNumberSpecial = memorize(
  everyPipe(
    minLength1, //
    onlyEngKorNumberSpecial
  )
)

export const validateKorEngNumberSpecialForContent = memorize(
  everyPipe(
    minLength1, //
    onlyEngKorNumberSpecialForContent
  )
)
