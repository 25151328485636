import sanitizeHTML from 'sanitize-html'
const options = {
  allowedTags: false,
  allowedAttributes: {
    '*': ['style', 'class']
  },
  parser: { decodeEntities: false }
}

const sanitizer = (msg) => sanitizeHTML(msg, options)
export default sanitizer
