// module store
// store.state.searchStateStore 접근
export default {
  namespaced: true,
  state: {
    labNo: 0,
    board: '',
    page: 0
  },
  getters: {},
  mutations: {
    SET_LAB_NO(state, labNo) {
      state.labNo = labNo
    },
    SET_BOARD(state, board) {
      state.board = board
    },
    SET_PAGE(state, page) {
      state.page = page
    }
  },
  actions: {
    setLabNo({ commit, state }, labNo) {
      commit('SET_LAB_NO', labNo)
    },
    setBoard({ commit, state }, board) {
      commit('SET_BOARD', board)
    },
    setPage({ commit, state }, page) {
      commit('SET_PAGE', page)
    },
    initailize({ commit, state }) {
      commit('SET_LAB_NO', 0)
      commit('SET_BOARD', '')
      commit('SET_PAGE', 0)
    }
  }
}
