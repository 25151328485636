// INFO: api 개발 완료 이후 작업

import { useAxios } from '../axiosUtil'

const baseUrl = '/v1/search'

const queryBuilder = (obj) => {
  let query = ''
  for (const key in obj) {
    query += query === '' ? '?' : '&'
    query += `${key}=${obj[key]}`
  }
  return query
}

const getSearchData = async (endpoint, opt = {}) => {
  const defaultOpt = {
    offset: 0,
    limit: 10
  }

  const searchOpt = { ...defaultOpt, ...opt }
  const searchQuery = queryBuilder(searchOpt)

  const result = await useAxios(`${baseUrl}/${endpoint}${searchQuery}`)
  // console.log(result)
  return result
}

export const searchProject = async (opt = {}) => {
  return await getSearchData('project', opt)
}
export const searchPaper = async (opt = {}) => {
  return await getSearchData('paper', opt)
}
export const searchPatent = async (opt = {}) => {
  return await getSearchData('patent', opt)
}
export const searchHandover = async (opt = {}) => {
  return await getSearchData('tech-hand-over', opt)
}
export const topBoards = async (opt = {}) => {
  return await getSearchData('', opt)
}
