// module store
// store.state.menuStore로 접근

export default {
  namespaced: true,
  state: {
    menuList: [
      {
        path: '/integratedSearch',
        name: '통합검색',
        nameEn: 'Search',
        children: []
      },

      {
        path: '/researchField',
        name: '연구분야검색',
        nameEn: 'Lab',
        children: [
          {
            path: '/researchField/lab',
            name: '연구실',
            nameEn: 'Labs'
          },
          {
            path: '/researchField/major',
            name: '기관별',
            nameEn: 'Departments'
          },
          {
            path: '/researchField/field',
            name: '연구 분야별',
            nameEn: 'ResearchFields'
          }
        ]
      },
      {
        path: '/remarkable',
        name: '이달의 연구실',
        nameEn: 'RemarkableLabs',
        children: [
          {
            path: '/remarkable',
            name: '이달의 연구실',
            nameEn: 'RemarkableLabs'
          },
          {
            path: '/remarkable/majorFieldTrend',
            name: '전공분야동향',
            nameEn: 'Major field trend'
          }
        ]
      },
      {
        path: 'showModal',
        name: '연구실협력신청',
        nameEn: 'Cooperation',
        children: []
      },
      {
        path: '/community',
        name: '커뮤니티',
        nameEn: 'Community',
        children: [
          {
            path: '/community/notice',
            name: '공지사항',
            nameEn: 'Notice'
          },
          {
            path: '/intro/service',
            name: '서비스 안내',
            nameEn: 'Service'
          },
          {
            path: '/intro/labs',
            name: '참여기관',
            nameEn: 'institute'
          },
          {
            path: '/community/qna',
            name: 'Q&A',
            nameEn: 'Q&A'
          }
        ]
      }
    ]
  },
  getters: {},
  mutations: {},
  actions: {}
}
