export default [
	{
		path: '/admin/board/project', // 프로젝트 게시판
		name: 'adminBoardProject',
		component: () => import('../views/admin/board/project/Project.vue'),
		children: [
			// 프로젝트 게시판 리스트
			{
				path: ':labNo?/:page?',
				name: 'adminBoardProjectList',
				component: () => import('../views/admin/board/project/ProjectList.vue')
			},
			// 프로젝트 게시판 등록
			{
				path: 'w',
				name: 'adminBoardProjectCreate',
				component: () => import('../views/admin/board/project/ProjectCreate.vue')
			},
			// 프로젝트 게시판 보기, 수정
			{
				path: 'w/:id/:page?/:labNo?',
				name: 'adminBoardProjectUpdate',
				component: () => import('../views/admin/board/project/ProjectUpdate.vue')
			}
		]
	}
]
