<template>
  <ul v-if="userRole" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion fixed-navbar-admin" id="accordionSidebar">
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/admin">
      <div class="sidebar-brand-icon rotate-n-15"></div>
      <div class="sidebar-brand-text mx-3">ADMINISTRATOR<sup></sup></div>

      <hr class="sidebar-divider my-0" />
    </a>

    <div class="logout-wrapper">
      <a class="logout" href="#" @click="logout">
        {{ LanguageConverter({ en: 'Log-Out', kr: '로그아웃' }) }}
      </a>
    </div>

    <div v-if="userRole !== 'lab'">
      <div class="sidebar-heading">홈페이지 설정</div>
      <li class="nav-item active">
        <a class="nav-link" href="/admin/basic"><span>기본환경설정</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/contents"><span>내용관리</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/popups"><span>팝업레이어관리</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/banners"><span>배너관리</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/users"><span>회원관리</span></a>
      </li>

      <hr class="sidebar-divider" />
    </div>

    <div v-if="userRole !== 'lab'">
      <div class="sidebar-heading">홈페이지 관리</div>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/gallery"><span>갤러리</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/qna"><span>Q&A게시판</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/notice"><span>공지사항 게시판</span></a>
      </li>
      <hr class="sidebar-divider" />
    </div>

    <div>
      <div class="sidebar-heading">연구실 DATA관리</div>
      <li class="nav-item" v-if="userRole !== 'lab'">
        <a class="nav-link" href="/admin/data"><span>DATA관리</span></a>
      </li>
      <!-- <li class="nav-item" v-if="userRole !== 'lab'">
        <a class="nav-link" href="/admin/labs/labsapplylist"><span>연구실 회원신청 현황</span></a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="/admin/labs">
          <span> 연구실{{ `${userRole !== 'lab' ? '리스트' : '정보'}` }} </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/project"><span>프로젝트 게시판</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/paper"><span>논문 게시판</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/patent"><span>지재권 게시판</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin/board/result"><span>기업협력 성과게시판</span></a>
      </li>

      <hr class="sidebar-divider d-none d-md-block" />
    </div>
  </ul>
</template>

<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  data() {
    return {
      userRole: null
    }
  },

  setup() {},
  created() {},
  mounted() {
    const interval = setInterval(() => {
      // console.log(this.userRole)

      if (this.userRole) clearInterval(interval)

      this.userRole = this.getUserRole()
    }, 100)
  },
  watch: {},
  unmounted() {},
  methods: {
    LanguageConverter,
    getUserRole() {
      return this.$store.state.userStore.info?.groupName
    },
    logout(e) {
      e.preventDefault()
      if (!confirm('로그아웃 하시겠습니까?')) return

      this.$store.dispatch('userStore/LogoutUser')
      window.location.href = '/'
    }
  }
}
</script>

<style scoped>
.fixed-navbar-admin {
  float: left;
}
.nav-link {
  padding: 5px 15px !important;
}
.navbar-nav li {
  padding: 0px 0px 0px 10px;
}

.logout-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 0 1rem;
}
.logout {
  color: var(--wf-misty-color);
  font-weight: bold;
  font-size: 0.75rem;
}
.logout:hover {
  opacity: 0.75;
}
</style>
