import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import { registerLicense } from '@syncfusion/ej2-base'

import 'material-icons/iconfont/material-icons.css'

// sync fusion css
import '../src/assets/css/syncfusion/ej2-base/material.css'
import '../src/assets/css/syncfusion/ej2-buttons/material.css'
import '../src/assets/css/syncfusion/ej2-calendars/material.css'
import '../src/assets/css/syncfusion/ej2-dropdowns/material.css'
import '../src/assets/css/syncfusion/ej2-inputs/material.css'
import '../src/assets/css/syncfusion/ej2-navigations/material.css'
import '../src/assets/css/syncfusion/ej2-popups/material.css'
import '../src/assets/css/syncfusion/ej2-splitbuttons/material.css'
import '../src/assets/css/syncfusion/ej2-vue-grids/material.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/js/bootstrap.js'
// import 'aos/dist/aos.css'
import '../src/assets/css/common_admin.css'
import '../src/assets/css/sb-admin-2.min.css'
// import '../src/assets/css/default.css'
import '../src/assets/css/modern-business.css'
import commonJs from '@/util/common.js'
import daumAddressAPI from '@/util/addressAPI.js'

import 'animate.css'
import '../src/assets/css/common.css'

// import '../public/vendor/fontawesome-free/css/all.min.css'

// SyncFusion License Key 등록
// FIXME: 키 env 파일로 옮기기
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFacF5JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNhW39acnJQR2RYV0U=')
// registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFacllJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1gWH1cdXxURWlfVkM=')
registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhWH9edHxQRGBdU0I=')

const app = createApp(App)

// FIXME: ^20.1.61 버전은 vue3를 지원하지 않는것으로 추정됨. 확인필요
// app.use(UploaderPlugin)
app.use(router)
app.use(store)
app.use(BootstrapVue3)
// app.use(AOS)
// app.use(MotionPlugin)
app.use(commonJs)
app.use(daumAddressAPI)
// app.use(BootstrapVue)
// app.use(IconsPlugin)
// app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
