import { useAxios } from './axiosUtil'
import queryStringBuilder from '../util/queryStringBuilder'

const baseUrl = {
  lab: '/v1/lab',
  category: '/v1/lab-scientific-category',
  question: '/v1/lab-question/status',
  remarkables: '/v1/lab-remarkables'
}

const get = async (baseUrlType, param) => {
  let url = `${baseUrl[baseUrlType]}`

  if (param.id || param.id === 0) {
    url += `/${param.id}`
    if (param.email) {
      url += '/question-mail'
      const str = queryStringBuilder(param.data)
      url += str
    }
  }

  if (!param.id && param.id !== 0) {
    const str = queryStringBuilder(param)
    url += str
  }

  const result = await useAxios(url)

  return result
}

const post = async (baseUrlType, param) => {
  let url = `${baseUrl[baseUrlType]}`

  if (param.id || param.id === 0) {
    url += `/${param.id}`
    if (param.sendEmail) url += '/question-mail'
  }

  const opt = {
    method: 'POST',
    data: {
      privacyAgreement: 1,
      ...param.data
    }
  }

  return await useAxios(url, opt)
}

const put = async (baseUrlType, param) => {
  let url = `${baseUrl[baseUrlType]}`

  if (param.id || param.id === 0) {
    // console.log(param.id)
    url += `/${param.id}`
  }

  const opt = {
    method: 'PUT',
    data: {
      ...param.data
    }
  }

  return await useAxios(url, opt)
}

export const mailStatusModel = {
  0: '<span class="wf__badge badge-secondary">요청중</span>',
  1: '<span class="wf__badge badge-warning">진행중</span>',
  2: '<span class="wf__badge badge-success">완료</span>'
}
const mapMailList = (item) => {
  return { ...item, status: mailStatusModel[item.status] }
}

export const getManyLab = async (opt = {}) => {
  return get('lab', opt)
}
export const getRemarkables = async (opt = {}) => {
  return get('remarkables', opt)
}
export const getLab = async (id = null) => {
  return get('lab', { id })
}
export const sendEmailToLab = async (id, data) => {
  return post('lab', { id, data, sendEmail: true })
}
export const getLabMails = async (id, data) => {
  const result = await get('lab', { id, data, email: true })
  return result.map(mapMailList)
}
export const putLabMailStatus = async (id, data) => {
  return await put('question', { id, data })
}
export const getLabCategory = async (id = null) => {
  return get('category', { id })
}
