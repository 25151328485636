import { topBoards } from '@/api/platform/search'
import { getLab, getRemarkables } from '@/api/labs'
import LanguageConverter from '@/util/LanguageConverter'

async function getTopLabNoList(limit) {
  const boards = await topBoards({ limit })

  if (!(boards instanceof Array)) return []
  const topLabNoList = boards.reduce((acc, cur) => {
    if (acc.length === 10) return acc

    const isIncludededLabNo = acc.findIndex((labNo) => labNo === cur.labNo)
    if (isIncludededLabNo === -1) acc.push(cur.labNo)
    return acc
  }, [])

  return topLabNoList
}

async function getTopLabList(labNoList) {
  const labList = []

  for (const labNo of labNoList) {
    const lab = await getLab(labNo)

    if (lab.code === 'DB_DATA_NOT_FOUND') continue

    if (labList.length === 12) break

    labList.push(lab)
  }

  return labList
}

// 중복 없는 랜덤 숫자
function getRandomNumbers(length, { startNum = 0, endNum }) {
  const arr = new Set()
  while (arr.size < length) {
    const num = Math.floor(Math.random() * (endNum - startNum + 1)) + startNum
    arr.add(num)
  }
  return [...arr]
}

async function getRandomLabList(labNoList, count) {
  const labs = await getLab()

  if (!(labs.items instanceof Array)) return []

  const filtered = labs.items.filter((item) => !labNoList.includes(item.labNo))

  if (labs.items.length <= 10) {
    return filtered
  }

  const randomNumbers = getRandomNumbers(count, { endNum: filtered.length > 1 ? filtered.length - 1 : 1 })

  return randomNumbers.reduce((acc, cur) => {
    acc.push(filtered[cur])
    return acc
  }, [])
}

export async function loadRemarkableLabs() {
  // const labNoList = await getTopLabNoList(50)
  // const labList = await getTopLabList(labNoList)

  // const count = 10 - labNoList.length
  // const randomLabList = count > 0 ? await getRandomLabList(labNoList, count) : []

  // const result= [...labList, ...randomLabList]

  const result = await getRemarkables({ limit: 10 })

  return result
}
