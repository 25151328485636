//TODO: 기술분야 펼쳐 보기
<script>
import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: {},
  props: {
    sixTechLargeList: {
      type: Array
    }
  },
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    LanguageConverter
  }
}
</script>

<template>
  <section class="wf__animate-section">
    <div class="wf__animate-head-content animate__animated animate__flipInX">
      <h1 class="wf__animate-head-content-title">
        <span class="bold">{{ LanguageConverter({ en: 'Find Techs We Want', kr: '우리가 원하는 기술' }) }}</span>
        <br />
        {{ LanguageConverter({ en: 'In PromisingLabs', kr: '유망연구실에서' }) }}
      </h1>
    </div>
    <div class="wf__animate-content animate__animated animate__zoomIn">
      <div class="wf__animate-foot-desc">
        {{ LanguageConverter({ en: 'Meet Wide and Various', kr: '폭넓은 분야의 정보를 제공하는' }) }}

        <span class="wf__font-bold">
          {{ LanguageConverter({ en: 'PromisingLabs Teches', kr: '유망연구실의 기술분야' }) }}
        </span>
      </div>
      <div class="wf__animate-foot-content">
        <h1 class="wf__animate-foot-content-title animate__animated">
          {{ LanguageConverter({ en: 'Fields', kr: '기술분야' }) }}
        </h1>
        <ul class="wf__animate-foot-content-list">
          <li class="animate__animated animate__rotateIn" v-for="(tech, i) in sixTechLargeList" :key="i">{{ tech }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__animate-section {
  position: relative;
  top: 30%;
  left: 12vw;
}

.wf__animate-head-content {
  animation-delay: 0.4s;
}
.wf__animate-head-content-title {
  color: var(--wf-misty-color);
  font-weight: 200;
  font-size: 70px;
  margin-bottom: 64px;
}
.wf__animate-head-content-title .bold {
  font-weight: 700;
}

.wf__animate-foot-desc {
  color: var(--wf-misty-color);
  font-size: var(--wf-text-24);
  margin-bottom: 1em;
}

.wf__animate-content {
  animation-delay: 1s;
}
.wf__animate-foot-content {
  position: relative;
  top: 10%;
  padding-bottom: 21px;

  color: var(--wf-misty-color);

  border: 2px solid var(--wf-misty-color);
  border-left: none;
  border-right: none;
}
.wf__animate-foot-content-title {
  color: var(--wf-primary-color);
  font-size: 70px;
  font-weight: bold;
  line-height: 1.5em;

  animation-delay: 1.2s;
}
.wf__animate-foot-content-list {
  display: flex;
  gap: 1.5em;
  font-size: 26px;
  font-weight: 700;
}
.wf__animate-foot-content-list li {
  animation-delay: 1.4s;
}
</style>
