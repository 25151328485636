<script setup>
import { createObjectURL } from '@/api/fileAPI'
import DefaultImg from '@/assets/images/profile.jpg'
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue'
const props = defineProps({
  src: String,
  data: Object,
  vertical: Boolean,
  cursor: {
    default: 'pointer',
    type: String
  },
  maxWidth: {
    default: '160px',
    type: String
  },
  height: {
    default: '185px',
    type: String
  },
  notList: {
    type: Boolean,
    default: false
  },
  noImg: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['onClick', 'onInfoItemClick'])

const imageSrc = ref('')
const figure = ref(null)
const figureWidth = ref(0)
const ro = new ResizeObserver(roCallback)
const imgWidth = ref('')

function roCallback(entries) {
  for (const entry of entries) {
    setWidth(entry)
  }
}

const figureClickHandler = () => {
  emit('onClick', props.data)
}

const replaceByDefault = (e) => {
  e.target.src = DefaultImg
}

const replaceImgSrc = () => {
  const src = props.src

  if (!src) return (imageSrc.value = '')
  const splitedSrc = src?.split('/')
  if (splitedSrc[0] !== 'https:') return

  const imageName = decodeURIComponent(splitedSrc.at(-1))
  const childFolder = decodeURIComponent(splitedSrc.at(-3))
  const parentFolder = decodeURIComponent(splitedSrc.at(-4))

  createObjectURL(imageName, parentFolder, childFolder).then((src) => (imageSrc.value = src))
}

const setWidth = (entry) => {
  const width = entry.contentRect.width
  figureWidth.value = props.vertical ? `${width}px` : `calc(${width - 160}px - 0.5em)`

  if (width >= 355 || props.vertical) {
    imgWidth.value = props.maxWidth
  } else {
    imgWidth.value = '40%'
  }
}

const clickInfoItem = (info) => {
  emit('onInfoItemClick', info)
}

onMounted(() => {
  replaceImgSrc()
  if (figure.value) {
    ro.observe(figure.value)
  }
})

watch(
  () => props.src,
  () => {
    replaceImgSrc()
  }
)
</script>

<template>
  <figure v-if="data" :class="vertical ? 'column' : ''" @click="figureClickHandler" :style="{ cursor: cursor }" ref="figure">
    <img v-if="!noImg" :src="imageSrc" :style="{ 'max-width': imgWidth, 'min-width': imgWidth, height }" @error="replaceByDefault" />

    <figcaption>
      <h5 class="title" :style="{ width: figureWidth }">{{ data.title }}</h5>
      <h6 class="wf__subtitle" :style="{ width: figureWidth }">{{ data.subtitle }}</h6>

      <slot></slot>

      <div class="divider"></div>

      <ul v-if="!notList" class="info-wrapper">
        <li class="info-content" v-for="(info, i) in data.infoList" :key="i" :style="{ width: figureWidth }">
          <span class="info-content-span head">{{ info?.head }}</span>
          <span class="info-content-span desc">{{ info?.desc }}</span>
        </li>
      </ul>

      <div v-if="notList" class="info-wrapper">
        <div class="info-content" v-for="(info, i) in data.infoList" :key="i" @click="clickInfoItem(info)">
          <span class="info-content-span" :style="{ width: figureWidth }">{{ info }}</span>
        </div>
      </div>
    </figcaption>
  </figure>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.divider {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  width: 100%;
  height: 1px;
  background-color: var(--wf-gray-color);
}

figure {
  font-size: var(--wf-text-16);
  display: flex;

  margin: 1em;
  gap: 1em;

  text-align: left;
}

figure img {
  object-fit: cover;
  object-position: center;
}

figure.column {
  flex-direction: column;
  align-items: center;
}

figure figcaption {
  width: 100%;
}
figure figcaption h5 {
  font-size: var(--wf-text-20);
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
figure figcaption h6 {
  color: var(--wf-gray-color);
}

.wf__subtitle {
  font-size: var(--wf-text-14);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
li.info-content {
  display: flex;
  align-items: flex-start;

  gap: 0.5em;
}

li .info-content-span {
  min-height: 1.5em;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* ...처리가 일어날 줄 */
  -webkit-box-orient: vertical;
}

li .info-content-span:not(:last-child) {
  padding-bottom: 5px;
}

li .info-content-span.head {
  display: flex;
  justify-content: space-between;
  min-width: 4.8em;
}
li .info-content-span.head::after {
  content: '|';
  display: inline-block;
  margin-left: 0.25em;
}

.info-wrapper {
  font-size: var(--wf-text-14) !important;
}
div.info-content {
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  background-color: var(--wf-gray-600);
  text-align: center;
  border-radius: 7px;
}

figure.column figcaption {
  width: 100%;
}
</style>
