import Home from '@/views/Home.vue'

const basicUrl = [
  {
    path: '/', // home 화면
    name: 'Home',
    component: Home
  },
  {
    path: '/section3', // 홈 섹션 3
    name: 'Section3',
    component: () => import('../components/Home/MobileHomeSection3.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    children: [
      {
        path: '',
        name: 'about404',
        redirect: '/404'
      },
      {
        path: ':id',
        component: () => import('../components/About/About.vue')
      }
    ]
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => import('../views/sitemap/Sitemap.vue')
  },
  {
    path: '/remarkable',
    component: () => import('../views/labs/Remarkable.vue'),
    children: [
      {
        path: '/remarkable/majorFieldTrend',
        name: 'majorFieldTrend',
        beforeEnter(to, from, next) {
          window.open('https://www.innopolis.or.kr/board?menuId=MENU00999&siteId=null')
        }
      }
    ]
  },
  // {
  //   path: '/handover',
  //   name: 'handoverReg',
  //   component: () => import('../views/tech/Handover.vue'),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../views/tech/HandoverForm.vue')
  //     },
  //     {
  //       path: ':id',
  //       component: () => import('../views/tech/HandoverForm.vue')
  //     }
  //   ]
  // },

  {
    path: '/lab',
    redirect: '/lab/0'
  },
  {
    path: '/lab/:id',
    name: 'labD',
    component: () => import('../views/labs/LabDetail.vue')
  },
  {
    path: '/intro',
    name: 'introduce',
    component: () => import('../views/introduce/Introduce.vue'),
    children: [
      {
        path: '',
        name: 'introduceRedirect',
        redirect: '/intro/service'
      },
      {
        path: 'service',
        meta: { title: '서비스 소개' },
        component: () => import('../components/introduce/Service.vue')
      },
      {
        path: 'platform',
        meta: { title: '플랫폼 소개' },
        component: () => import('../components/introduce/Platform.vue')
      },
      {
        path: 'labs',
        meta: { title: '연구기관 소개' },
        component: () => import('../components/introduce/Labs.vue')
      }
    ]
  },
  {
    path: '/404',
    name: 'Notfoundpage',
    component: () => import('../views/NotFoundPage.vue')
  },

  {
    // INFO: vue2에 *, vue3엔 pathmatch로 변경
    // FIXME: 관리자 화면에서 지정되지 않은 url입력시 홈화면이 보임
    path: '/:pathMatch(.*)*',
    name: 'notfoundpage',
    redirect: '/404'
  }
]

export default basicUrl
